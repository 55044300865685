import moment from 'moment';
import 'moment-duration-format';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Header, Label } from 'semantic-ui-react';

import style from './style.css';

function customFormatting(expiredText) {
    return function durationToString() {
        // One day
        if (this.duration.asSeconds() >= 86400) {
            return 'd [days] h [h]';
        }

        // One hour
        if (this.duration.asSeconds() >= 3600) {
            return 'h [h] m [m]';
        }

        if (this.duration.asSeconds() <= 0) {
            return `[${expiredText}]`;
        }

        return 'm [m] s [s]';
    };
}

class TimeCountdown extends Component {
    constructor(props) {
        super(props);

        const from = new moment();
        const to = new moment(this.props.countdownTo);

        this.state = {
            timeLeft: moment.duration(to.diff(from)),
        };
    }

    UNSAFE_componentWillMount() {
        this.timer = setInterval(() => {
            const timeLeft = this.state.timeLeft.clone();

            timeLeft.subtract(1, 's');

            this.setState({ timeLeft });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderLabel = () => {
        const { expiredText, ...labelProps } = { ...this.props };
        delete labelProps.countdownTo;
        delete labelProps.header;
        delete labelProps.wrapper;
        return (
            <Label
                className={style.Label}
                content={this.state.timeLeft.format(customFormatting(expiredText))}
                {...labelProps}
            />
        );
    };

    render() {
        if (this.props.header || this.props.wrapper) {
            return (
                <div>
                    {this.props.header && <Header sub content="Tid kvar" />}
                    {this.renderLabel()}
                </div>
            );
        }

        return this.renderLabel();
    }
}

TimeCountdown.propTypes = {
    countdownTo: PropTypes.string,
    header: PropTypes.bool,
    wrapper: PropTypes.bool,
    expiredText: PropTypes.string,
};
TimeCountdown.defaultProps = {
    countdownTo: null,
    header: false,
    wrapper: false,
    expiredText: '0 s',
};

export default TimeCountdown;
