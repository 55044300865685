import React from 'react';
import PropTypes from 'prop-types';
import { Header, Menu } from 'semantic-ui-react';
import TimeCountdown from '../../../../common/TimeCountdown';

function ApplicationDataMenu(props) {
    const { product } = props;

    return (
        <Menu.Menu position="right">
            {props.columns
                // Only show relevant columns for product category
                .map(c => (
                    <Menu.Item key={c.key}>
                        <Header as="h2">
                            <Header.Subheader>{c.header}</Header.Subheader>
                            {c.value(product)}
                        </Header>
                    </Menu.Item>
                ))}
            <Menu.Item fitted="horizontally">
                <TimeCountdown wrapper icon="clock" size="large" countdownTo={props.responses_expired_at} />
            </Menu.Item>
        </Menu.Menu>
    );
}

ApplicationDataMenu.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    responses_expired_at: PropTypes.string,
    product: PropTypes.shape({}).isRequired,
};
ApplicationDataMenu.defaultProps = {};

export default ApplicationDataMenu;
