import React, { useEffect, Fragment, useState } from 'react';
import { Form, Input, Table, Header, Button } from 'semantic-ui-react';
import { useForm } from '@lendoab/use-form';
import Toast from 'APP/common/Toast';
import './style.css';

import { PropertyQuestionFormMachine } from './PropertyQuestionFormMachine';
import { numeric } from '@lendoab/web-validations';
import AddressAutoCompleteInput from 'APP/components/AddressAutoCompleteInput/AddressAutoCompleteInput';
import { constructAddress } from 'APP/helpers/ConstructAddress';

function getPropertyQuestionFields(data) {
    return {
        [`marketValue`]: {
            value: data[`marketValue`],
            meta: { fieldType: 'input' },
        },
        [`loanAmount`]: {
            value: data[`loanAmount`],
            meta: { fieldType: 'input' },
        },
        ['propertyAddress']: {
            value: data['propertyAddress'],
        },
        ['propertyMunicipality']: {
            value: data['propertyMunicipality'],
        },
        ['propertyPostalCode']: {
            value: data['propertyPostalCode'],
        },
    };
}

export function UpdatePropertyAnswersForm(props) {
    const { property, reFetchApplication } = props;
    const [state, dispatch] = PropertyQuestionFormMachine();
    const { getFieldValue, commitValue, getFieldMessage, getInputProps, getFieldState, handleSubmit } = useForm({
        fields: {
            ...getPropertyQuestionFields(state),
        },
        onSubmit: data => {
            // check market value before request
            if (!numeric(getFieldValue('marketValue'))) {
                Toast('error', 'incorrect market value');
                return;
            }

            // check loan amount before request
            if (!numeric(getFieldValue('loanAmount'))) {
                Toast('error', 'incorrect loan amount');
                return;
            }
            dispatch({ type: 'SUBMIT', data: data });
        },
    });

    const [addressValidationError, setAddressValidationError] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [address, setAddress] = useState('');
    useEffect(() => {
        if (property.address)
            setAddress(property?.address + ' ,' + property.postal_code + ' ,' + property.municipality);
    }, [property]);

    function addressValidation(data) {
        if (data === null && address.length > 0) {
            setAddressValidationError('Ange endast bostadens adress och gatunummer. Exempel: Drottninggatan 10');
        } else {
            setAddressValidationError('');
        }
    }

    function onAddressSelected({ data }) {
        commitValue('propertyAddress', data.address + data.street_number);
        commitValue('propertyMunicipality', data.city);
        commitValue('propertyPostalCode', data.postal_code);
        setSelectedAddress(data);
        setAddress(constructAddress(data));
        addressValidation(data);
        if (!data?.street_number) {
            setAddressValidationError('Ange endast bostadens adress och gatunummer. Exempel: Drottninggatan 10');
        }
    }

    function onChangeAddress(propertyFieldValue) {
        setAddressValidationError('');
        setSelectedAddress(null);
        setAddress(propertyFieldValue.target.value);
    }

    useEffect(() => {
        if (state.status === 'fetching') {
            const payload = {
                applicationId: property.applicationID,
                propertyQuestionId: property.id,
                marketValue: property.market_value,
                loanAmount: property.loan_amount,
                propertyName: property.property_name,
            };

            commitValue('marketValue', property.market_value);
            commitValue('loanAmount', property.loan_amount);

            dispatch({ type: 'UPDATE_PROPERTY_QUESTION_DATA', data: payload });
        }

        if (state.error) {
            Toast('error', state.errorMessage);
            dispatch({ type: 'RESET_ERROR_AND_SUCCESS' });
        }

        if (state.success) {
            Toast('success', 'Updated property question.');
            dispatch({ type: 'RESET_ERROR_AND_SUCCESS' });
            reFetchApplication();
        }
    }, [state]);

    return (
        <Form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Header as="h4" floated="left" style={{ marginBottom: '0px' }}>
                    <Header.Content>
                        Property Information
                        <Header.Subheader>Handle Property Information</Header.Subheader>
                    </Header.Content>
                </Header>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        name="submitContactInformation"
                        size="small"
                        content="Save"
                        primary
                        type="submit"
                        style={{ marginRight: '10px' }}
                    />
                </div>
            </div>
            <Table basic="very" compact style={{ margin: '6px 0px' }}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Header as="h4">
                                <Header.Content>
                                    <Header.Subheader>Market Value</Header.Subheader>
                                </Header.Content>
                            </Header>

                            <Fragment>
                                <Input
                                    {...getInputProps('marketValue')}
                                    error={getFieldMessage('marketValue')}
                                    success={getFieldState('marketValue')}
                                    fluid
                                    name="marketValue"
                                    style={{ minHeight: '38px', fontSize: '14px', marginBottom: '6px' }}
                                />
                            </Fragment>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Header as="h4">
                                <Header.Content>
                                    <Header.Subheader>Loan Amount</Header.Subheader>
                                </Header.Content>
                            </Header>

                            <Fragment>
                                <Input
                                    {...getInputProps('loanAmount')}
                                    error={getFieldMessage('loanAmount')}
                                    success={getFieldState('loanAmount')}
                                    fluid
                                    name="loanAmount"
                                    style={{ minHeight: '38px', fontSize: '14px', marginBottom: '6px' }}
                                />
                            </Fragment>
                        </Table.Cell>
                    </Table.Row>
                    {property.address && (
                        <Table.Row>
                            <Table.Cell>
                                <Header as="h4">
                                    <Header.Content>
                                        <Header.Subheader>Property Address</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <AddressAutoCompleteInput
                                    value={address}
                                    error={addressValidationError}
                                    success={selectedAddress !== null}
                                    onBlur={() => addressValidation(selectedAddress)}
                                    id="propertyAddress"
                                    placeholder="Sök adress"
                                    name={'propertyAddress'}
                                    onAddressSelected={onAddressSelected}
                                    onChange={onChangeAddress}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
        </Form>
    );
}
