import ApplicationTypes from '../enums/Application/Types';
import { Codes } from '../enums/Applicant/Restriction';
import moment from 'moment';
import config from '../config/StateMachine/config';
import { getDropdownOptions } from './CommonHelper';
import { ucWindowBusiness } from '../consts';
import ObjectHelper from './ObjectHelper';

const applicationTypeStrings = {
    [ApplicationTypes.PRIVATE]: 'Private',
    [ApplicationTypes.BUSINESS]: 'Business',
    [ApplicationTypes.MORTGAGE]: 'Mortgage',
};

const getMainApplicant = application => {
    if (!application?.applicants?.length) {
        return null;
    }

    return application.applicants.find(({ type }) => type === 'main');
};

const getCoApplicant = application => {
    if (!application?.applicants?.length) {
        return null;
    }

    return application.applicants.find(({ type }) => type === 'co');
};

const formatSSN = ssn => {
    const birthdate = ssn.slice(0, 8);
    const controlnumber = ssn.slice(8, 12);
    return `${birthdate}-${controlnumber}`;
};

const formatCompanyOrgNumber = orgNum => {
    const first = orgNum.slice(0, 6);
    const last = orgNum.slice(6, 10);
    return `${first}-${last}`;
};

export default {
    shortId(id) {
        return id.substr(0, 8);
    },

    renderShortId(application) {
        return application.id.substr(0, 8);
    },

    getMainApplicant,
    getCoApplicant,

    formatSSN,

    formatCompanyOrgNumber,

    options: getDropdownOptions(applicationTypeStrings),

    hasCoApplicant: application => application.applicants.length > 1,

    renderPeriod(period) {
        if (period % 12 === 0) {
            return `${Math.ceil(period / 12)} years`;
        }
        return `${period} months`;
    },

    renderAmount(value, suffix = 'kr') {
        if (value === undefined || value === null) {
            return '-';
        }
        const p = `${value}`.split('.', 2);
        p[0] = p[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, ' ');
        return p.join('.') + (suffix ? ` ${suffix}` : '');
    },

    renderLoanPurpose(value) {
        if (value === undefined || value === null) {
            return '-';
        }

        // Replace underscores with spaces and capitalize the first letter of the string
        let result = value.replace(/_/g, ' ');
        result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();

        return result;
    },

    getLastTaxableIncome(applicant) {
        const taxableIncome = applicant?.credit_data?.incomes?.[0]?.taxable_income;
        if (taxableIncome) {
            return taxableIncome;
        }
    },
    getLastTotalIncome(applicant) {
        const totalIncome = applicant?.credit_data?.incomes?.[0]?.total_income;
        if (totalIncome) {
            return totalIncome;
        }
    },
    getLastIncomeYear(applicant) {
        const incomeYear = applicant?.credit_data?.incomes?.[0]?.year;
        if (incomeYear) {
            return incomeYear;
        }
    },

    renderPercentage(amount, multiplyBy100 = true) {
        if (amount === undefined || amount === null) {
            return '-';
        }
        return multiplyBy100 ? `${(amount * 100).toFixed(2)} %` : `${amount} %`;
    },

    renderStatus(application) {
        switch (application.status) {
            default:
                return application.status.substr(0, 1).toUpperCase() + application.status.substr(1);
        }
    },

    getIdOfApplicantOrCompany: application => {
        if (application.company && application.company.registration_number) {
            return formatCompanyOrgNumber(application.company.registration_number);
        }

        return formatSSN(getMainApplicant(application).ssn);
    },

    getRevisionByRevisionAndByOfferId: (revisions, offerId) => {
        return revisions.find(revision => {
            return revision.offers.find(offer => offer.id === offerId);
        });
    },

    latestRevision: revisions =>
        revisions.reduce((oldR, newR) => (newR.revision > oldR.revision ? newR : oldR), revisions[0]),

    getUCEndDate(application) {
        // Returns UC end date, Needs the active revision as param and works for both business and private
        return application && application.type === ApplicationTypes.BUSINESS
            ? this.getUCEndDateBusiness(application)
            : this.getUCEndDatePrivate(this.getMainApplicant(application));
    },

    hasApplicantUnderOngoingInvestigation(applicants) {
        return Boolean(applicants?.find(item => item.personal_data?.restriction?.code === Codes.ONGOING_INVESTIGATION));
    },

    getUCEndDateBusiness(application) {
        const expiresAt = ObjectHelper.get(application, 'company.credit_data.expires_at');
        if (expiresAt) {
            return moment(new Date(expiresAt));
        }

        // Fallback to manual calculation of expiration date, if expires at is missing.
        const fetchedAt = ObjectHelper.get(application, 'company.credit_data.fetched_at');
        if (fetchedAt) {
            return moment(new Date(fetchedAt)).add(ucWindowBusiness, 'days');
        }

        return undefined;
    },

    getUCEndDatePrivate(applicant) {
        const expiresAt = ObjectHelper.get(applicant, 'credit_data.expires_at');
        if (!expiresAt) {
            return undefined;
        }
        return moment(new Date(expiresAt));
    },

    hasActiveUCWindow(user) {
        const creditDataExpiresAt = ObjectHelper.get(user, 'credit_data.expires_at');
        const m = moment(new Date(creditDataExpiresAt));
        const diff = moment.duration(m.diff(new Date()));
        return diff > 0;
    },

    getDominantStatus(revisions) {
        if (!revisions.length) {
            return '';
        }
        const statuses = revisions.map(r => r.status);
        const priorities = statuses.map(s => config.DOMINANCE_PRIORITY.indexOf(s)).filter(p => p > -1);
        if (priorities.length === 0) {
            // return the latest status
            const latestRevision = this.latestRevision(revisions);
            return latestRevision.status;
        }
        return config.DOMINANCE_PRIORITY[Math.min(...priorities)];
    },

    phoneNorm: value => {
        const rEx = new RegExp('^(0046)|(\\+46)');
        return value && value.replace(rEx, '0');
    },

    /**
     * Normalize comma seperated numbers like this: "1,2 324" to 1.2324
     *
     * Or, "1,022.55" to 1022.55
     * @param {String} value Number with comma to normalize
     * @returns number|NaN
     */
    normalizeCommaSeparatedNumber: value => {
        if (value && typeof value === 'string') {
            const valueWithoutSpace = value.replace(/ /g, '');
            if (value.match(/\./gi) !== null) {
                return Number(valueWithoutSpace.replace(/,/g, ''));
            }
            return Number(valueWithoutSpace.replace(/,/g, '.'));
        }

        return value;
    },

    /**
     * Get latest income report from multiple UC credit checks.
     *
     * @param {array} incomes List of credit report from UC
     * @returns array|null
     */
    getLatestIncomeReport(incomes) {
        if (incomes.length) {
            const [latestIncome] = incomes.sort((a, b) => b.year - a.year);
            return latestIncome;
        }

        return null;
    },

    getTotalLoanAmount(data) {
        if (data?.existing_loan_amount && data.mortgage_purpose === ApplicationTypes.MORTGAGE_EXTEND_LOAN) {
            return data.loan_amount + data.existing_loan_amount;
        } else {
            return data.loan_amount;
        }
    },
};
