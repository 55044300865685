import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';
import store from '../stores/ReduxStore';

const baseUrl = `${config.api.prefix}/mortgage-queues`;
const callbacksUrl = `${config.api.prefix}/callbacks`;
const transport = AjaxHelper;

const queryFromObject = function(obj) {
    let i = 0;
    let query = '';
    Object.keys(obj).forEach(key => {
        const val = obj[key];
        if (val !== null && typeof val !== 'undefined') {
            if (i++ === 0) {
                query += `?${key}=${encodeURIComponent(val)}`;
            } else {
                query += `&${key}=${encodeURIComponent(val)}`;
            }
        }
    });

    return query;
};

export default {
    queues: () => transport.get(baseUrl, 'data'),
    createQueue: queue => transport.post(baseUrl, { data: queue }, 'data'),
    updateQueue: queue => transport.patch(`${baseUrl}/${queue.id}`, { data: queue }, 'data'),
    deleteQueue: id => transport.delete(`${baseUrl}/${id}`, 'data'),
    selections: () => transport.get(`${baseUrl}/selections`, 'data'),
    selectionStructure: () => transport.get(`${baseUrl}/selections/structure`, 'data'),
    createSelection: selection => {
        const url = `${baseUrl}/selections`;
        return transport.post(url, { data: selection });
    },
    deleteSelection: id => transport.delete(`${baseUrl}/selections/${id}`),
    updateSelection: (id, selection) => transport.patch(`${baseUrl}/selections/${id}`, { data: selection }),
    patch: (user_id, user) => transport.patch(`${baseUrl}/users/${user_id}`, { data: user }),
    statistics: queueID => transport.get(`${baseUrl}/${queueID}/statistics`, 'data'),
    users: () => transport.get(`${baseUrl}/users`, 'data'),
    get: id => transport.get(`${baseUrl}/${id}`, 'data'),
    next: () => {
        const state = store.getState();
        const userId = state.auth.authData.user.id;
        return transport.post(`${baseUrl}/applications/next`, { data: { user_id: userId } }, 'data');
    },
    createCallback: callback => transport.post(callbacksUrl, { data: callback }, 'data'),
    updateCallback: (callbackId, data) => transport.patch(`${callbacksUrl}/${callbackId}`, { data }),
    getApplicationCallback: applicationId => transport.get(`${callbacksUrl}?application_id=${applicationId}`),
    events: (from, to, user_id, limit, offset) =>
        transport.get(
            `${baseUrl}/events` +
                queryFromObject({ from: from, to: to, user_id: user_id, limit: limit, offset: offset }),
            'data'
        ),
};
