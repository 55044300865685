import PropTypes from 'prop-types';
import React from 'react';
import { Header, Popup, Segment } from 'semantic-ui-react';
import ComplementsList from 'APP/components/Application/common/ComplementsList';
import FeesAndRates from './FeesAndRates';

import styles from './MoreDetailsLink.css';

function MoreDetails(props) {
    return (
        <Popup on="click" flowing trigger={<span className="hoverable">More info</span>}>
            <Segment.Group horizontal className="basic">
                <Segment className={styles.Segment}>
                    <FeesAndRates response={props.response} />
                </Segment>
                <Segment className={styles.Segment}>
                    <Header as="h4" content="Requested complements" />
                    <ComplementsList complements={props.response.complements} />
                </Segment>
            </Segment.Group>
        </Popup>
    );
}

MoreDetails.propTypes = {
    response: PropTypes.shape({
        complements: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
};
MoreDetails.defaultProps = {};

export default MoreDetails;
