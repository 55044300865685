import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import CompanyInformation from './CompanyInformation';
import ApplicantInformationFormContainer from './ApplicantInformationFormContainer';
import EditableBeneficialOwners from './EditableBeneficialOwners';
import BeneficialOwners from './BeneficialOwners';
import BeneficialOwnerHelper from '../../../../../helpers/BeneficialOwnerHelper';

function ApplicationWrapper(props) {
    const { admin, boxed, application, canEditBeneficialOwners, reFetchApplication } = props;
    const applicant = props.application.applicants[0];
    const { company } = application;

    return (
        <Grid columns="equal">
            <Grid.Column>
                <ApplicantInformationFormContainer
                    application={application}
                    applicant={applicant}
                    company={company}
                    beneficialOwnerLabel={BeneficialOwnerHelper.generateLabelText(
                        applicant,
                        props.application.beneficial_owners,
                        company
                    )}
                    assumedBeneficialOwner={props.application.should_notify_beneficial_owner}
                    boxed={boxed}
                    admin={admin}
                    reFetchApplication={reFetchApplication}
                />
            </Grid.Column>
            <Grid.Column>
                <CompanyInformation application={props.application} boxed={props.boxed} />
            </Grid.Column>
            {company.type !== 'EN' && (
                <Grid.Column width={16}>
                    {canEditBeneficialOwners ? (
                        <EditableBeneficialOwners
                            reFetchApplication={reFetchApplication}
                            application={application}
                            boxed={props.boxed}
                        />
                    ) : (
                        <BeneficialOwners application={application} boxed={props.boxed} />
                    )}
                </Grid.Column>
            )}
        </Grid>
    );
}

ApplicationWrapper.propTypes = {
    application: PropTypes.shape({
        applicants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        beneficial_owners: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    boxed: PropTypes.bool,
    admin: PropTypes.bool,
};
ApplicationWrapper.defaultProps = {
    boxed: true,
    admin: false,
};

export default ApplicationWrapper;
