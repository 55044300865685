import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

function SNIPopup({ company }) {
    if (!company.credit_data) {
        return null;
    }

    if (company.credit_data.sni_code === null) {
        return <span>N/A</span>;
    }

    return (
        <Popup
            on="hover"
            trigger={<span className="hoverable">{company.credit_data.sni_code}</span>}
            content={company.credit_data.sni_description}
        />
    );
}

SNIPopup.propTypes = {
    company: PropTypes.object.isRequired,
};
SNIPopup.defaultProps = {};

export default SNIPopup;
