import { useReducer, useEffect } from 'react';
import { createEffect } from 'APP/helpers/Effect';
import PropertyQuestionActions from 'APP/actions/PropertyQuestionActions';

const initialPropertyQuestionStateData = {
    status: 'fetching',
    effects: [],
    editing: false,
    propertyQuestionId: '',
    propertyName: '',
    applicationId: '',
    marketValue: '',
    loanAmount: '',
    newMarketValue: '',
    newLoanAmount: '',
    municipality: '',
    postal_code: '',
    address: '',
};

function reducer(state, event) {
    switch (state.status) {
        case 'fetching':
            if (event.type === 'UPDATE_PROPERTY_QUESTION_DATA') {
                return {
                    ...state,
                    status: 'idle',
                    marketValue: event.data.marketValue,
                    loanAmount: event.data.loanAmount,
                    propertyQuestionId: event.data.propertyQuestionId,
                    propertyName: event.data.propertyName,
                    applicationId: event.data.applicationId,
                    effects: [...state.effects],
                };
            }
            return state;
        case 'idle':
            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    newMarketValue: event.data.marketValue,
                    newLoanAmount: event.data.loanAmount,
                    municipality: event.data.propertyMunicipality,
                    postal_code: event.data.propertyPostalCode,
                    address: event.data.propertyAddress,
                    status: 'pending',
                    editing: false,
                    effects: [...state.effects, createEffect('submit')],
                };
            }

            if (event.type === 'TOGGLE_EDIT') {
                return {
                    ...state,
                    editing: true,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'DISCARD') {
                return {
                    ...state,
                    editing: false,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'RESET_ERROR_AND_SUCCESS') {
                return {
                    ...state,
                    error: false,
                    success: false,
                    errorMessage: '',
                    effects: [...state.effects],
                };
            }
            return state;
        case 'pending':
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    status: 'idle',
                    editing: false,
                    success: true,
                    effects: [...state.effects],
                };
            }
            if (event.type === 'REJECT') {
                return {
                    ...state,
                    status: 'idle',
                    error: true,
                    errorMessage: event.errorMessage,
                    editing: false,
                    effects: [...state.effects],
                };
            }
            return state;
    }
}

export function PropertyQuestionFormMachine() {
    const [{ effects, ...state }, dispatch] = useReducer(reducer, initialPropertyQuestionStateData);

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }
            effect.markAsStarted();

            if (effect.type === 'submit') {
                // We need to diff the new values against the existing values
                // and only send the values that actually changed.
                const requestObject = {
                    id: state.propertyQuestionId,
                    property_label: state.propertyName,
                    market_value: state.marketValue,
                    loan_amount: state.loanAmount,
                    address: state.address,
                    municipality: state.municipality,
                    postal_code: state.postal_code,
                };

                if (state.newMarketValue !== state.marketValue) {
                    requestObject.market_value = parseInt(state.newMarketValue);
                }

                if (state.newLoanAmount !== state.loanAmount) {
                    requestObject.loan_amount = parseInt(state.newLoanAmount);
                }
                const questions = {
                    questions: [requestObject],
                };

                // update property information request
                PropertyQuestionActions.update(state.applicationId, questions)
                    .then(() => dispatch({ type: 'RESOLVE' }))
                    .catch(err => dispatch({ type: 'REJECT', errorMessage: err }));
            }
        }
    }, [state, effects]);

    return [state, dispatch];
}
