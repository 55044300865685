import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Field from '@lendoab/lendo-redux-form-helpers';
import ComplementsHelper from '../../../../helpers/ComplementsHelper';
import { formValues } from 'redux-form';

const ComplementsBusiness = props => {
    const getOnChange = key =>
        ['one_guarantor', 'two_guarantors'].includes(key) ? props.toggleGuarantorComplement(key) : null;

    return (
        <Table basic="very" collapsing celled selectable>
            <Table.Body>
                {Object.entries(ComplementsHelper.getComplements(ComplementsHelper.types.BUSINESS)).map(
                    ([key, value]) => (
                        <Table.Row key={key}>
                            <Table.Cell>{value}</Table.Cell>
                            <Table.Cell>
                                <Field
                                    component="checkbox"
                                    toggle
                                    name={`complements.${key}`}
                                    onChange={getOnChange(key)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )
                )}
            </Table.Body>
        </Table>
    );
};

ComplementsBusiness.propTypes = {
    mainApplicant: PropTypes.object.isRequired,
    toggleGuarantorComplement: PropTypes.func.isRequired,
};

ComplementsBusiness.defaultProps = {};

export default formValues('complements')(ComplementsBusiness);
