import React, { useRef } from 'react';
import { Box } from '@lendoab/aphrodite';
import PropTypes from 'prop-types';
// @ts-ignore
import style from './style.css';

export default function AddressSelectMenuItem(props) {
    const { value, label, ...rest } = props;
    const selectMenuItemRef = useRef(null);

    return (
        <Box
            {...rest}
            fluid
            display="flex"
            cursor="pointer"
            justifyContent="flex-start"
            alignItems="center"
            paddingLeft="medium"
            paddingRight="medium"
            component="li"
            tabIndex={-1}
            role="option"
            data-value={value}
            ref={selectMenuItemRef}
            className={style.selectMenuItem}
        >
            {label}
        </Box>
    );
}

AddressSelectMenuItem.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
};
