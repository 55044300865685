// eslint-disable-next-line import/no-cycle
import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';

const baseUrl = `${config.api.prefix}/auth`;
const transport = AjaxHelper;

const validateRequestMapper = data => {
    if (data.data && data.data.auth) {
        return { ...data.data.auth, csrfToken: data.headers['x-csrf-token'] };
    }
};

const authenticateRequestMapper = data => {
    if (data.data && data.data.auth) {
        return {
            ...data.data.auth,
            csrfToken: data.headers['x-csrf-token'],
        };
    }
};

export default {
    validateToken() {
        return transport.get('/api/auth/validate').then(validateRequestMapper);
    },

    validateUser(credentials) {
        const data = {
            data: {
                username: credentials.email,
                password: credentials.password,
            },
        };
        return transport.post(`${baseUrl}/authenticate`, data).then(authenticateRequestMapper);
    },

    logoutUser() {
        return transport.delete(`${baseUrl}/session`);
    },

    impersonateUser(userID, reason) {
        return transport.post(`${baseUrl}/impersonate-user`, {
            data: {
                user_id: userID,
                reason,
            },
        });
    },
};
