import React from 'react';
import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';

function GeneralInformation(props) {
    if (!props.text) {
        return null;
    }

    return (
        <Table.Cell>
            <Header as="h4" sub content={props.header} />
            <span>{props.text}</span>
        </Table.Cell>
    );
}

GeneralInformation.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.string,
};

GeneralInformation.defaultProps = {
    text: null,
};

export default GeneralInformation;
