import React, { Component } from 'react';
import { Segment, List, Header, Icon } from 'semantic-ui-react';
import RequestStatusHandler from '../../common/RequestStatusHandler/RequestStatusHandler';
import style from './RequestStatusResultMessage.css';

class RequestStatusResultMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    UNSAFE_componentWillMount() {
        RequestStatusHandler.addChangeListener(this.getRequests);
        RequestStatusHandler.on('message_show', this.showMessage);
    }

    componentWillUnmount() {
        RequestStatusHandler.addChangeListener(this.getRequests);
        RequestStatusHandler.removeListener('message_show', this.showMessage);
    }

    getRequests = () => {
        this.setState({
            request_results: RequestStatusHandler.getRequestResults(),
            requests_overall_status: RequestStatusHandler.getStatus(),
            request_chain_name: RequestStatusHandler.getRequestChainName(),
        });
    };

    showMessage = () => {
        this.setState({
            visible: true,
        });
        if (this.state.requests_overall_status !== 'failed') {
            setTimeout(() => {
                this.setState({
                    visible: false,
                });
            }, 8000);
        }
    };

    closeMessage = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        if (!this.state.visible) {
            return null;
        }

        const request_nodes = this.state.request_results.map((request, index) => (
            <List.Item key={index}>
                {request.name}
                <List.Content floated="right">
                    {request.status === 'done' && <Icon name="check" color="green" circular />}
                    {request.status === 'failed' && <Icon name="remove" color="red" circular />}
                </List.Content>
            </List.Item>
        ));

        return (
            <div className={style.statusMessageContainer}>
                <Segment inverted padded raised className={style.statusMessage}>
                    <div className={style.closeMessageIcon} onClick={this.closeMessage}>
                        <Icon name="close" />
                    </div>
                    <Header as="h4" style={{ marginTop: '0' }}>
                        {this.state.requests_overall_status === 'done' && (
                            <Icon inverted name="check" color="green" circular />
                        )}
                        {this.state.requests_overall_status === 'failed' && (
                            <Icon inverted name="remove" color="red" circular />
                        )}
                        <Header.Content>
                            {this.state.requests_overall_status === 'done' && 'Lyckades'}
                            {this.state.requests_overall_status === 'failed' && 'Anrop misslyckades'}
                            {this.state.request_chain_name && (
                                <Header.Subheader style={{ color: '#fff' }}>
                                    {this.state.request_chain_name}
                                </Header.Subheader>
                            )}
                        </Header.Content>
                    </Header>
                    {this.state.requests_overall_status === 'failed' && <List>{request_nodes}</List>}
                </Segment>
            </div>
        );
    }
}

export default RequestStatusResultMessage;
