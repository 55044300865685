import queryString from 'query-string';
import AjaxHelper from '../../helpers/AjaxHelper';
import config from '../../config';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const treatApplicationsUrl = `${baseUrl}/treat-applications/integration-tasks`;
const wonApplicationsUrl = `${baseUrl}/won-applications/integration-tasks`;

export default {
    getTasksTreatApplications: (params = {}) => {
        const query = queryString.stringify(params);

        return transport.get(`${treatApplicationsUrl}?${query}`, 'data');
    },
    getTasksWonApplications: (params = {}) => {
        const query = queryString.stringify(params);

        return transport.get(`${wonApplicationsUrl}?${query}`, 'data');
    },
};
