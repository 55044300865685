import React from 'react';
import PropTypes from 'prop-types';

import { Header, Step, List } from 'semantic-ui-react';
import ObjectHelper from 'APP/helpers/ObjectHelper';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import { RevisionChanges as RevisionOptions } from '../../../../enums/Application/PrivateLoan/RevisionChanges';
import RevisionValues from '../../../../enums/Application/PrivateLoan/RevisionValues';

const RevisionChanges = ({ prevRevision, application }) => {
    // CHECKS FOR DIFFERENCES BETWEEN CURRENT REVISION AND PREVIOUS REVISION
    const productChecks = ['amortize_length', 'amount_to_collect', 'total_loan_amount'];
    const applicantChecks = [
        'employment.type',
        'employment.company.name',
        'employment.monthly_income',
        'number_of_children',
        'accommodation.monthly_cost',
        'accommodation.type',
        'civil_state',
    ];
    const numApplicant = ['number_of_applicants'];

    const newValues = {};
    const prevValues = {};
    const applicantInCurrentRevision = ApplicationHelper.getMainApplicant(application);
    const applicantInPrevRevision = ApplicationHelper.getMainApplicant(prevRevision);
    const previousApplicants = prevRevision.applicants.length;
    const newApplicants = application.applicants.length;

    // CHECKS FOR DIFFERENCES BETWEEN REVISIONS IN PRODUCT INFORMATION ARRAY
    productChecks.forEach(check => {
        if (prevRevision.products[0][check] !== application.products[0][check]) {
            if (check === 'amortize_length') {
                newValues[check] = ApplicationHelper.renderPeriod(application.products[0][check]);
                prevValues[check] = ApplicationHelper.renderPeriod(prevRevision.products[0][check]);
            } else {
                newValues[check] = application.products[0][check];
                prevValues[check] = prevRevision.products[0][check];
            }
        }
    });

    // CHECKS FOR DIFFERENCES BETWEEN REVISIONS IN APPLICANT INFORMATION ARRAY, NOTE TYPE IS BOTH ACCOMMODATION TYPE AND EMPLOYMENT TYPE
    applicantChecks.forEach(check => {
        const previousValue = ObjectHelper.get(applicantInPrevRevision, check);
        const currentValue = ObjectHelper.get(applicantInCurrentRevision, check);

        if (previousValue !== currentValue) {
            prevValues[check] = RevisionValues[previousValue] || previousValue;
            newValues[check] = RevisionValues[currentValue] || currentValue;
        }
    });

    // CHECKS FOR DIFFERENCES BETWEEN REVISIONS IN NUMBER OF APPLICANTS
    if (previousApplicants !== newApplicants) {
        newValues[numApplicant] = newApplicants;
        prevValues[numApplicant] = previousApplicants;
    }

    return (
        <Step.Group style={{ width: '100%' }} widths={2}>
            <Step>
                <Step.Content>
                    <Step.Title>
                        <Header as="h2">Previous Revision</Header>
                    </Step.Title>
                    <Step.Description>
                        <List bulleted>
                            {Object.entries(prevValues).map(([keyName, keyValue]) => (
                                <List.Item key={keyName}>
                                    {RevisionOptions[keyName]}: {[keyValue]}
                                </List.Item>
                            ))}
                        </List>
                    </Step.Description>
                </Step.Content>
            </Step>

            <Step active>
                <Step.Content>
                    <Step.Title>
                        <Header as="h2">Active Revision</Header>
                    </Step.Title>
                    <Step.Description>
                        <List bulleted>
                            {Object.entries(newValues).map(([keyName, keyValue]) => (
                                <List.Item key={keyName}>
                                    {RevisionOptions[keyName]}: {[keyValue]}
                                </List.Item>
                            ))}
                        </List>
                    </Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
    );
};

RevisionChanges.propTypes = {
    prevRevision: PropTypes.object.isRequired,
    application: PropTypes.object.isRequired,
};

export default RevisionChanges;
