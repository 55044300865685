import CompanyHelper from './CompanyHelper';

const ownerTypes = {
    SOLE: 'sole',
    SHARED: 'shared',
};

const isOwner = (applicant, beneficialOwners) => beneficialOwners.find(s => s.id_nr === applicant.ssn);

const isSoleOwner = (applicant, beneficialOwners) =>
    beneficialOwners.length === 1 && isOwner(applicant, beneficialOwners);

export default {
    types: ownerTypes,

    isSoleOwner,

    generateLabelText: (applicant, beneficialOwners, company) => {
        if (CompanyHelper.isEN(company)) {
            return null;
        }

        if (isSoleOwner(applicant, beneficialOwners)) {
            return 'Sole beneficial owner';
        }

        return isOwner(applicant, beneficialOwners) ? 'Beneficial owner' : null;
    },
};
