import config from '../../config';
import AjaxHelper from '../../helpers/AjaxHelper';

const baseUrl = `${config.partnerApi.prefix}/files`;

export default {
    getFiles(filesIds) {
        let params = '';
        filesIds.forEach(fileId => {
            params += params == '' ? '?' : '&';
            params += `id=${fileId}`;
        });

        return AjaxHelper.get(`${baseUrl}${params}`, 'data');
    },
};
