import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';

const consentsBaseUrl = `${config.api.prefix}/consents`;
const applicationConsentsBaseUrl = `${config.api.prefix}/applications`;

export default {
    /**
     * get marketing e-mail and phone consents for a specfic email and phone number.
     * @param {String} email
     * @param {String} phone
     */
    getMarketingEmailAndPhoneConsents: (email, phone, userId) => {
        const formattedPhoneNumber = encodeURIComponent(phone);
        const url = `${consentsBaseUrl}/marketing?phone_number=${formattedPhoneNumber}&email=${email}&user_id=${userId}`;
        return AjaxHelper.get(url, 'data');
    },

    /**
     * get transactional email and phone consents for specific applicant id.
     * @param {String} applicantId
     */
    getTransactionalEmailAndPhoneConsents: applicantId => {
        const url = `${consentsBaseUrl}/transactional?applicant_id=${applicantId}`;
        return AjaxHelper.get(url, 'data');
    },

    /**
     * set e-mail consents and phone consents.
     * @param {Object} payload
     */
    setApplicantEmailAndPhoneConsents: payload => {
        const url = consentsBaseUrl;
        return AjaxHelper.post(url, { data: payload });
    },

    /**
     * get machine learning consents for specific application id.
     * @param {String} applicationId
     */
    getMachineLearningConsents: (applicationId, consentName) => {
        const url = `${applicationConsentsBaseUrl}/${applicationId}/consents/${consentName}`;
        return AjaxHelper.get(url, 'data');
    },

    /**
     * set machine learning consent for specific application id and consent name
     * @param {Object} payload
     */
    setMachineLearningConsents: (applicationId, consentName, payload) => {
        const url = `${applicationConsentsBaseUrl}/${applicationId}/consents/${consentName}`;
        return AjaxHelper.post(url, { data: payload });
    },
};
