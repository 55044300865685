import BankIdConstants from 'APP/consts/BankIdConstants';

export const getErrorMessage = bankIdStatus => {
    switch (bankIdStatus) {
        case BankIdConstants.PROGRESS.USER_CANCEL:
            return 'The applicant canceled the verification process.';
        case BankIdConstants.PROGRESS.NO_USER:
            return 'No user was found for the SSN. Please try again in 1,5min.';
        case BankIdConstants.PROGRESS.NO_CLIENT:
            return 'No client was found for the SSN.';
        case BankIdConstants.PROGRESS.UNKNOWN_ERROR:
        default:
            return 'An unknown error occurred.';
    }
};
