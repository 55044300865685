import { Sidebar } from 'semantic-ui-react';
import React from 'react';
import { connect } from 'react-redux';
import TopBarComponent from '../components/TopBar/TopBarComponent';
import NavigationContainer from '../components/Sidebar/Navigation/NavigationContainer';
import UserProfileContainer from '../components/Sidebar/UserProfile/UserProfileContainer';
import ToplistSidebar from '../components/Score/LeaderBoard/SidebarToplist';
import RequestStatusModal from '../components/RequestStatus/RequestStatusModal';
import RequestStatusResultMessage from '../components/RequestStatus/RequestStatusResultMessage';
import './Helpers.css';
import { UserTypes } from 'APP/enums/User/Type';
import PrivilegesHelper from 'APP/helpers/PrivilegesHelper';

class SidebarLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.authData ? props.authData.user : {},
            sidebarVisible: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.authData !== this.props.authData) {
            this.setState(() => ({
                user: nextProps.authData.user,
            }));
        }
    }

    toggleSidebar = () => {
        // Currently we dont have anything to show in the sidebar.
        //  But in LENSE-5104 we will add a new table.
        //  Keeping the code here and disabling the sidebar instead of deleting it.
        //
        // this.setState({
        //     sidebarVisible: !this.state.sidebarVisible,
        // });
    };

    render() {
        if (!this.state.user) {
            return null;
        }
        return (
            <Sidebar.Pushable>
                {!PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <ToplistSidebar sidebarVisible={this.state.sidebarVisible} toggleSidebar={this.toggleSidebar} />
                )}
                <Sidebar.Pusher style={{ marginLeft: '0' }}>
                    <div className="sidebar">
                        <UserProfileContainer user={this.state.user} />
                        <NavigationContainer />
                    </div>
                    <div id="scroll-container">
                        <RequestStatusModal />
                        <RequestStatusResultMessage />
                        <TopBarComponent user={this.state.user} toggleSidebar={this.toggleSidebar} />
                        <div>{this.props.children}</div>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}

function mapStateToProps(state) {
    const { authData } = state.auth;
    return {
        authData,
    };
}

export default connect(mapStateToProps)(SidebarLayout);
