import PropTypes from 'prop-types';
import React from 'react';
import { Header, Label, Segment, Table } from 'semantic-ui-react';
import BeneficialOwnerHelper from '../../../../../helpers/BeneficialOwnerHelper';
import NullableBooleanField from './NullableBooleanField';
import AssumedPopup from './AssumedPopup';

function BeneficialOwners(props) {
    const { beneficial_owners, applicants } = props.application;

    if (beneficial_owners.length === 0) {
        return null;
    }
    const applicant = applicants[0];

    if (BeneficialOwnerHelper.isSoleOwner(applicant, beneficial_owners)) {
        return null;
    }

    // Sort by name
    const beneficialOwners = beneficial_owners.sort((a, b) =>
        a.full_name ? a.full_name.localeCompare(b.full_name) : 0
    );

    return (
        <Segment.Group className={props.boxed ? '' : 'basic'}>
            <Segment basic={!props.boxed}>
                <Header as="h4" content="Beneficial owners" />
            </Segment>
            <Segment basic={!props.boxed}>
                <Table
                    basic="very"
                    compact
                    headerRow={
                        <Table.Row>
                            <Table.Cell>
                                <Header as="h4" sub content="Nationality" />
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Country of residence" />
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Name" />
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="SSN/Org. number" />
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="Email" />
                            </Table.Cell>
                            <Table.Cell>
                                <Header as="h4" sub content="PEP" />
                            </Table.Cell>
                        </Table.Row>
                    }
                    renderBodyRow={beneficial_owner => (
                        <Table.Row key={beneficial_owner.id_nr}>
                            <Table.Cell>{beneficial_owner.nationality}</Table.Cell>
                            <Table.Cell>{beneficial_owner.countryOfResidence}</Table.Cell>
                            <Table.Cell>
                                {props.application.should_notify_beneficial_owner && <AssumedPopup />}
                                {beneficial_owner.full_name}
                            </Table.Cell>
                            <Table.Cell>{beneficial_owner.id_nr}</Table.Cell>
                            <Table.Cell>{beneficial_owner.email}</Table.Cell>
                            <Table.Cell>
                                <NullableBooleanField value={beneficial_owner.is_politically_exposed} />
                            </Table.Cell>
                            {beneficial_owner.id_nr === applicant.ssn && (
                                <Table.Cell>
                                    <Label content="Applicant" />
                                </Table.Cell>
                            )}
                        </Table.Row>
                    )}
                    tableData={beneficialOwners}
                />
            </Segment>
        </Segment.Group>
    );
}

/* eslint-disable camelcase */
BeneficialOwners.propTypes = {
    application: PropTypes.shape({
        beneficial_owners: PropTypes.arrayOf(
            PropTypes.shape({
                full_name: PropTypes.string,
                registration_number: PropTypes.string,
                personal_number: PropTypes.string,
                email: PropTypes.string,
            })
        ),
        applicants: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                ssn: PropTypes.string,
                email: PropTypes.string,
            })
        ).isRequired,
        should_notify_beneficial_owner: PropTypes.bool,
    }).isRequired,
    boxed: PropTypes.bool,
};
BeneficialOwners.defaultProps = {
    boxed: true,
};
/* eslint-enable camelcase */

export default BeneficialOwners;
