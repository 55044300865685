import AjaxHelper from '../../helpers/AjaxHelper';
import config from '../../config';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const rolesUrl = `${baseUrl}/roles`;

export default {
    listRoles() {
        return transport.get(`${rolesUrl}`, 'data');
    },
};
