import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';

const baseUrl = `${config.api.prefix}/applications/business`;

export default {
    postBeneficialOwners(beneficial_owners) {
        return AjaxHelper.post(`${baseUrl}/beneficial-owner`, { beneficial_owners }, 'data');
    },
    create(data) {
        return AjaxHelper.post(`${baseUrl}`, { data });
    },
};
