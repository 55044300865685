import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { lowerCase, upperFirst } from 'lodash';
import { Table, Button, Accordion, Icon, Header, Popup } from 'semantic-ui-react';
import { runTask as runTaskAction } from 'APP/actions/IntegrationsActions';
import { HandleException } from 'APP/helpers/ErrorHelper';
import Toast from 'APP/common/Toast';
import { DATE_TIME_FORMAT } from 'APP/enums/Moment';
import clipbordHelper from 'APP/helpers/ClipboardHelper';
import style from './TaskEntry.css';
import { ERROR, TRANSIENT_ERROR } from 'APP/enums/Integrations/Errors';
import { IntegrationStatus } from 'APP/enums/Integrations/Status';
import RunButton from './RunButton';

/**
 * @param {string} taskStatus
 */
function isError(taskStatus) {
    return taskStatus === ERROR || taskStatus === TRANSIENT_ERROR;
}

/**
 * @param {Object} props
 * @param {Object} props.task
 */
function TaskEntry({ task, updateModal, expanded: expandedProp }) {
    const [expanded, setExpanded] = useState(expandedProp);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        setActiveAccordion(null);
    }, [expanded]);

    if (!task) {
        return null;
    }

    function toggleExpanded() {
        setExpanded(!expanded);
    }

    function accordionClickHandler(index) {
        if (index === activeAccordion) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(index);
        }
    }

    function handleRunClick() {
        if (task.status === IntegrationStatus.status.FINISHED) {
            // Show confirm modal.
            runTask({ force: true });
        } else {
            // No need to send force flag.
            runTask();
        }
    }

    async function runTask(options = {}) {
        if (inProgress) {
            return;
        }
        setInProgress(true);

        try {
            await runTaskAction(task.id, options);
            Toast('success', `Ran ${task.id}`);
            updateModal();
        } catch (error) {
            HandleException(error, 'Failed to run task');
            setInProgress(false);
        }
    }

    function productRow() {
        const productNames = new Set(Object.values(task.products));
        return Array.from(productNames).join(', ');
    }

    function formatId() {
        return task.id.split('-')[0];
    }

    function copyId() {
        clipbordHelper.copy(task.id);
    }

    return (
        <Fragment>
            <Table.Row negative={isError(task.status)} active={expanded}>
                <Table.Cell className={style.idCell}>
                    <Popup trigger={<span>{formatId()}</span>} content={task.id} basic flowing />
                    <Popup
                        trigger={<Button icon="copy" basic size="small" onClick={copyId} />}
                        content="Copied!"
                        on="click"
                    />
                </Table.Cell>
                <Table.Cell>
                    {task.last_executed_at ? moment(task.last_executed_at).format(DATE_TIME_FORMAT) : '(never)'}
                </Table.Cell>
                <Table.Cell>{productRow()}</Table.Cell>
                <Table.Cell>{upperFirst(lowerCase(task.action))}</Table.Cell>
                <Table.Cell>{task.run_count}</Table.Cell>
                <Table.Cell>{task.application_revision}</Table.Cell>
                <Table.Cell>{upperFirst(lowerCase(task.status))}</Table.Cell>
                <Table.Cell textAlign="center">
                    <RunButton
                        loading={inProgress}
                        disabled={inProgress}
                        status={task.status}
                        handleRunClick={handleRunClick}
                    />
                </Table.Cell>
                <Table.Cell textAlign="center">
                    <Button icon={expanded ? 'chevron down' : 'chevron right'} basic onClick={toggleExpanded} />
                </Table.Cell>
            </Table.Row>
            <Table.Row style={{ display: !expanded && 'none' }}>
                <Table.Cell colSpan="12">
                    <Accordion styled fluid>
                        {Array.isArray(task.events) &&
                            task.events.map((event, i) => (
                                <Fragment key={i}>
                                    <Accordion.Title
                                        active={activeAccordion === i}
                                        onClick={() => accordionClickHandler(i)}
                                    >
                                        <Icon name="dropdown" />
                                        {event.message} at {moment(event.created_at).format(DATE_TIME_FORMAT)}
                                    </Accordion.Title>
                                    <Accordion.Content active={activeAccordion === i}>
                                        {Array.isArray(event.error_trail) && (
                                            <Fragment>
                                                <Header as="h4">Error trail</Header>
                                                {event.error_trail.map((error, index) => (
                                                    <pre key={index} className={style.errorTrail}>
                                                        {error}
                                                    </pre>
                                                ))}
                                            </Fragment>
                                        )}
                                    </Accordion.Content>
                                </Fragment>
                            ))}
                        {!Array.isArray(task.events) && <h3>This task has no events.</h3>}
                    </Accordion>
                </Table.Cell>
            </Table.Row>
        </Fragment>
    );
}

TaskEntry.propTypes = {
    task: PropTypes.shape({}),
    expanded: PropTypes.bool,
};

TaskEntry.defaultProps = {
    task: {},
    expanded: false,
};

export default TaskEntry;
