import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { Accordion, Icon, Menu, Label } from 'semantic-ui-react';
import { logoutUser } from '../../../actions/auth-actions-redux';
import PrivilegesHelper from '../../../helpers/PrivilegesHelper';
import Privileges from '../../../enums/Privileges/Privileges';
import Toast from '../../../common/Toast';
import QueueActions from '../../../actions/QueueActions';
import MortgageQueueActions from 'APP/actions/MortgageQueueActions';
import { QUEUE_CORE } from 'APP/featureFlags';
import QueueActionsNew from 'APP/actions/QueueActions.new';

import style from './style.css';
import { UserTypes } from '../../../enums/User/Type';
import { HandleException } from 'APP/helpers/ErrorHelper';
import ApplicationsAction from 'APP/actions/ApplicationsAction';
import { ENABLE_NOTIFICATIONS_NAVBAR_ITEM } from 'APP/featureFlags';
import NotificationHelpers from 'APP/helpers/NotificationHelpers';

export function NavigationComponent(props) {
    const { dispatch, history, location } = props;

    const currentPath = location.pathname;

    const [activeIndex, setActiveIndex] = useState(null);
    const [unreadNotifications, setUnreadNotifications] = useState(0);

    useEffect(() => {
        if (ENABLE_NOTIFICATIONS_NAVBAR_ITEM && PrivilegesHelper.hasAccess(Privileges.MORTGAGE_NOTIFICATIONS_BASIC)) {
            async function fetchUnreadNotificationCount() {
                const unreadNotifications = await ApplicationsAction.getUnreadNotificationsCount();
                let numberOfUnreadNotificationsCount = NotificationHelpers.sumAllUnreadCounts(unreadNotifications);

                setUnreadNotifications(numberOfUnreadNotificationsCount);
            }

            fetchUnreadNotificationCount().catch(error =>
                Toast('error', `Failed to fetch unread notifications count. Error: ${error}`)
            );
        }
    }, []);

    function handleSignout() {
        dispatch(logoutUser());
    }

    function handleClick(e, titleProps) {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    }

    // This is used by when clicking Application as a customer sales person
    function nextApplication() {
        if (QUEUE_CORE) {
            QueueActionsNew.next().then(
                res => {
                    if (!res.application_id) {
                        return Toast('error', 'Application missed an id');
                    }
                    history.push({
                        pathname: `/application/${res.application_type}/${res.application_id}`,
                        state: { callback: res.callback },
                    });
                },
                e => {
                    HandleException(e, 'Could not go to application');
                }
            );
        } else {
            QueueActions.next().then(
                res => {
                    if (!res.application_id) {
                        return Toast('error', 'Application missed an id');
                    }
                    const type = res.category;
                    history.push({
                        pathname: `/application/${type}/${res.application_id}`,
                        state: { callback: res.callback },
                    });
                },
                e => {
                    HandleException(e, 'Could not go to application');
                }
            );
        }
    }

    function nextMortgageApplication() {
        if (QUEUE_CORE) {
            MortgageQueueActions.next().then(
                res => {
                    if (!res.application_id) {
                        return Toast('error', 'Mortgage application missed an id');
                    }
                    history.push({
                        pathname: `/application/mortgage/treat/${res.application_id}`,
                        state: { callback: res.callback },
                    });
                },
                e => {
                    HandleException(e, 'Could not go to mortgage application');
                }
            );
        } else {
            MortgageQueueActions.next().then(
                res => {
                    if (!res.application_id) {
                        return Toast('error', 'Mortgage application missed an id');
                    }
                    history.push({
                        pathname: `/application/mortgage/treat/${res.application_id}`,
                        state: { callback: res.callback },
                    });
                },
                e => {
                    HandleException(e, 'Could not go to application');
                }
            );
        }
    }

    return (
        <div className="styledScroll" style={{ height: '90%', overflowY: 'scroll' }}>
            <Menu vertical fluid inverted className={style.sidebarMenu}>
                {PrivilegesHelper.isUserType(UserTypes.ADMIN) && (
                    <Menu.Item as={Link} to="/" name="dashboard" active={currentPath === '/'}>
                        <Icon name="dashboard" />
                        Dashboard
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_OWNERSHIPS_LIST) && (
                    <Menu.Item as={Link} to="/ownerships" name="ownerships" active={currentPath === '/ownerships'}>
                        <Icon name="users" />
                        My customers
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_NOTIFICATIONS_BASIC) &&
                    ENABLE_NOTIFICATIONS_NAVBAR_ITEM && (
                        <Menu.Item
                            as={Link}
                            to="/notifications"
                            name="notifications"
                            active={currentPath === '/notifications'}
                        >
                            <Icon name="bell outline" />
                            {unreadNotifications > 0 && (
                                <Label circular color="red" size="mini">
                                    {unreadNotifications > 99 ? '99+' : unreadNotifications}
                                </Label>
                            )}
                            Notifications
                        </Menu.Item>
                    )}
                {PrivilegesHelper.fullAccess(Privileges.ADMIN_USER_LIST, 'admin_user') && (
                    <Menu.Item as={Link} to="/users/accounts" name="users" active={currentPath.startsWith('/users/')}>
                        <Icon name="users" />
                        Users
                    </Menu.Item>
                )}
                {PrivilegesHelper.fullAccess(
                    [Privileges.APPLICATION_MANAGE, Privileges.APPLICATION_LIST],
                    'admin_user',
                    false
                ) && (
                    <Accordion>
                        <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                            <Menu.Item>
                                <Icon name="dropdown" />
                                Applications
                            </Menu.Item>
                        </Accordion.Title>
                        <Accordion.Content className={style.sidebarAccordionContent} active={activeIndex === 0}>
                            {PrivilegesHelper.hasAccess(Privileges.APPLICATION_MANAGE) && (
                                <Menu.Item
                                    as={Link}
                                    to="/application"
                                    active={currentPath === '/application'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="add" />
                                    Create new
                                </Menu.Item>
                            )}

                            {PrivilegesHelper.hasAccess(Privileges.APPLICATION_MANAGE) && (
                                <Menu.Item
                                    as={Link}
                                    to="/application/business"
                                    active={currentPath === '/application/business'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="add" />
                                    Create new business
                                </Menu.Item>
                            )}

                            {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_APPLICATION_MANAGE) && (
                                <Menu.Item
                                    as={Link}
                                    to="/application/mortgage/create"
                                    active={currentPath === '/application/mortgage/create'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="add" />
                                    Create new mortgage
                                </Menu.Item>
                            )}

                            {PrivilegesHelper.hasAccess(Privileges.APPLICATION_LIST) && (
                                <Menu.Item
                                    as={Link}
                                    to="/applications"
                                    name="applications"
                                    active={currentPath === '/applications'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="list" />
                                    List
                                </Menu.Item>
                            )}
                        </Accordion.Content>
                    </Accordion>
                )}
                {/* APPLICATIONS FOR THE CUSTOMER SALES/SUPPORT PEOPLES */}
                {PrivilegesHelper.hasAccess(Privileges.QUEUE_NEXT) && (
                    <Menu.Item
                        onClick={nextApplication}
                        name="applications-queue"
                        active={currentPath === '/applications-queue'}
                    >
                        <Icon name="browser" color="green" />
                        Application
                    </Menu.Item>
                )}
                {/* APPLICATIONS FOR THE CUSTOMER SALES/SUPPORT PEOPLES */}
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_QUEUE_NEXT) && (
                    <Menu.Item
                        onClick={nextMortgageApplication}
                        name="mortgage-applications-queue"
                        active={currentPath === '/mortgage-applications-queue'}
                    >
                        <Icon name="browser" color="green" />
                        Mortgage Application
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.STATISTICS_LIST) && (
                    <Menu.Item as={Link} to="/statistics" name="statistics" active={currentPath === '/statistics'}>
                        <Icon name="pie chart" />
                        Statistics
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.REPORTS_LIST) && (
                    <Menu.Item as={Link} to="/reports" name="reports" active={currentPath === '/reports'}>
                        <Icon name="file" />
                        Reports
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.QUEUE_MANAGE) && (
                    <Menu.Item as={Link} to="/callqueues" name="callqueues" active={currentPath === '/callqueues'}>
                        <Icon name="phone" />
                        Call Queues
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.MORTGAGE_QUEUE_MANAGE) && (
                    <Menu.Item
                        as={Link}
                        to="/mortgage-callqueues"
                        name="mortgage-callqueues"
                        active={currentPath === '/mortgage-callqueues'}
                    >
                        <Icon name="phone" />
                        Call Queues Mortgages
                    </Menu.Item>
                )}
                {QUEUE_CORE && PrivilegesHelper.hasAccess(Privileges.QUEUE_MANAGE) && (
                    <Menu.Item as={Link} to="/queues" name="queues" active={currentPath === '/queues'}>
                        <Icon name="phone" />
                        Queues
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.PARTNER_LIST) && PrivilegesHelper.isUserType(UserTypes.ADMIN) && (
                    <Menu.Item as={Link} to="/partners" name="partners" active={currentPath === '/partners'}>
                        <Icon name="travel" />
                        Partners
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.PARTNER_LIST) && PrivilegesHelper.isUserType(UserTypes.ADMIN) && (
                    <Menu.Item
                        as={Link}
                        to="/integrations/tasks"
                        name="integrations"
                        active={
                            currentPath === '/integrations/overview' ||
                            currentPath === '/integrations/tasks' ||
                            currentPath === '/integrations/integrations'
                        }
                    >
                        <Icon name="computer" />
                        Integrations
                    </Menu.Item>
                )}
                {PrivilegesHelper.isUserType(UserTypes.PARTNER) &&
                    PrivilegesHelper.hasAccess(Privileges.PARTNER_ADMIN) && (
                        <Menu.Item
                            as={Link}
                            to="/partneradmins"
                            name="partneradmins"
                            active={currentPath === '/partneradmins'}
                        >
                            Partneradmins
                        </Menu.Item>
                    )}
                {PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <Menu.Item as={NavLink} to="/treatapplications" name="treatapplications">
                        Treat Applications
                    </Menu.Item>
                )}
                {PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <Menu.Item as={NavLink} to="/accepted-offers" name="accepted-offers">
                        Accepted offers
                    </Menu.Item>
                )}
                {PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <Menu.Item as={NavLink} to="/bill-of-debt" name="bill-of-debt">
                        Bill of Debt Sent Out
                    </Menu.Item>
                )}
                {PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <Menu.Item as={NavLink} to="/paid-out" name="paid-out">
                        Paid Out
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.SCORE_MANAGE) && (
                    <Menu.Item as={Link} to="/score" name="score" active={currentPath === '/score'}>
                        <Icon name="trophy" />
                        Score
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess([
                    Privileges.SCHEDULER_DEAD_LIST,
                    Privileges.SCHEDULER_LIST_SCHEDULED_EVENTS,
                ]) && (
                    <Accordion>
                        <Accordion.Title active={activeIndex === 1} index={1} onClick={handleClick}>
                            <Menu.Item>
                                <Icon name="dropdown" />
                                Message Scheduler
                            </Menu.Item>
                        </Accordion.Title>
                        <Accordion.Content className={style.sidebarAccordionContent} active={activeIndex === 1}>
                            {PrivilegesHelper.hasAccess([Privileges.SCHEDULER_DEAD_LIST]) && (
                                <Menu.Item
                                    as={Link}
                                    to="/scheduler/dead"
                                    name="dead_messages"
                                    active={currentPath === '/scheduler/dead'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="announcement" />
                                    Dead Letters
                                </Menu.Item>
                            )}

                            {PrivilegesHelper.hasAccess([Privileges.SCHEDULER_LIST_SCHEDULED_EVENTS]) && (
                                <Menu.Item
                                    as={Link}
                                    to="/scheduler/jobs"
                                    name="scheduled_events"
                                    active={currentPath === '/scheduler/jobs'}
                                    className={style.sidebarMenuItem}
                                >
                                    <Icon name="list" />
                                    Scheduled Events
                                </Menu.Item>
                            )}
                        </Accordion.Content>
                    </Accordion>
                )}
                {PrivilegesHelper.hasAccess([Privileges.CONSENT_BLACKLIST]) && (
                    <Menu.Item
                        as={Link}
                        to="/consent/blacklist"
                        name="consent_blacklist"
                        active={currentPath === '/consent/blacklist'}
                    >
                        <Icon name="list layout" />
                        Blacklist
                    </Menu.Item>
                )}
                {PrivilegesHelper.hasAccess(Privileges.MESSAGE_TEMPLATE_MANAGE) && (
                    <Menu.Item as={Link} to="/templates" active={currentPath === '/templates'}>
                        <Icon name="send" />
                        Templates: Email & SMS
                    </Menu.Item>
                )}
                {PrivilegesHelper.fullAccess(Privileges.ADMIN_USER_LIST, 'admin_user') && (
                    <Menu.Item as={Link} to="/upload-csv" active={currentPath === '/upload-csv'}>
                        <Icon name="cloud upload" />
                        Import paid out state
                    </Menu.Item>
                )}
                {PrivilegesHelper.fullAccess(Privileges.ADMIN_USER_LIST, UserTypes.ADMIN) && (
                    <Menu.Item as={Link} to="/tracking/sourcegroups" active={currentPath === '/tracking/sourcegroups'}>
                        <Icon name="crosshairs" />
                        Tracking
                    </Menu.Item>
                )}
                <Menu.Item as={Link} to="/change-password" active={currentPath === '/change-password'}>
                    <Icon name="edit" />
                    Change password
                </Menu.Item>
                <Menu.Item onClick={handleSignout}>
                    <Icon name="log out" />
                    Sign out
                </Menu.Item>
            </Menu>
        </div>
    );
}

function mapStateToProps(state) {
    const { authData } = state.auth;
    return { authData };
}

export default withRouter(connect(mapStateToProps)(NavigationComponent));
