import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';
import store from '../stores/ReduxStore';
import { queryFromObject } from 'APP/helpers/QueryFromObject';

const baseUrl = `${config.api.prefix}/queues`;
const transport = AjaxHelper;

export default {
    queues: () => transport.get(baseUrl, 'data'),
    createQueue: queue => transport.post(baseUrl, { data: queue }, 'data'),
    updateQueue: queue => transport.patch(`${baseUrl}/${queue.id}`, { data: queue }, 'data'),
    deleteQueue: id => transport.delete(`${baseUrl}/${id}`, 'data'),
    selections: () => transport.get(`${baseUrl}/selections`, 'data'),
    selectionStructure: () => transport.get(`${baseUrl}/selections/structure`, 'data'),
    createSelection: selection => {
        const url = `${baseUrl}/selections`;
        return transport.post(url, { data: selection });
    },
    deleteSelection: id => transport.delete(`${baseUrl}/selections/${id}`),
    updateSelection: (id, selection) => transport.put(`${baseUrl}/selections/${id}`, { data: selection }),
    patch: (user_id, user) => transport.patch(`${baseUrl}/users/${user_id}`, { data: user }),
    statistics: queueID => transport.get(`${baseUrl}/${queueID}/statistics`, 'data'),
    users: () => transport.get(`${baseUrl}/users`, 'data'),
    get: id => transport.get(`${baseUrl}/${id}`, 'data'),
    next: () => {
        const state = store.getState();
        const userId = state.auth.authData.user.id;
        return transport.post(`${baseUrl}/applications/next`, { data: { user_id: userId } }, 'data');
    },
    createCallback: callback => transport.post(baseUrl, { data: callback }, 'data'),
    events: (from, to, user_id, team_id, limit, offset) => {
        const userId = user_id.length ? user_id : undefined;
        const teamId = team_id.length ? team_id : undefined;
        const fromDate = from !== 'Invalid date' ? from : undefined;
        const toDate = to !== 'Invalid date' ? to : undefined;

        return transport.get(
            `${baseUrl}/events` +
                queryFromObject({
                    from: fromDate,
                    to: toDate,
                    user_id: userId,
                    team_id: teamId,
                    limit: limit,
                    offset: offset,
                }),
            'data'
        );
    },
};
