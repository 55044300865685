import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import ComplementsHelper from 'APP/helpers/ComplementsHelper';

function ComplementsList(props) {
    if (props.complements.length === 0) {
        return <span>No complements requested</span>;
    }

    return (
        <List>
            {props.complements
                .sort(a => (a.applicant_type === 'main' ? -1 : 1)) // Sort so main applicant is at top
                .map(complement => (
                    <List.Item key={`${complement.type}_${complement.applicant_type}`}>
                        {ComplementsHelper.renderComplement(complement)}
                    </List.Item>
                ))}
        </List>
    );
}

ComplementsList.propTypes = {
    complements: PropTypes.arrayOf(PropTypes.shape({})),
};
ComplementsList.defaultProps = {
    complements: [],
};

export default ComplementsList;
