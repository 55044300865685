import 'core-js';
import 'regenerator-runtime/runtime';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import moment from 'moment';
import Router from './router/Router';
import store from './stores/ReduxStore';
import { InitSentry } from './sentry';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './theme/base.css';
import './theme/overrides.css';
import './theme/custom.css';
import WebsocketProvider from 'APP/components/Websocket';
import { DragAndDropProvider } from '@lendoab/aphrodite-file-upload';

require('@lendoab/aphrodite/dist/styles.css');
require('@lendoab/aphrodite/dist/aphrodite-theme.css');
require('@lendoab/aphrodite-admin/dist/styles.css');
require('@lendoab/aphrodite-chat/dist/styles.css');
require('@lendoab/aphrodite-file-upload/dist/styles.css');

InitSentry();

// Local is set to English, since the UI is in English
// longDateFormat is changed to show the Swedish date format
moment.locale('en', {
    longDateFormat: {
        L: 'YYYY-MM-DD',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        lll: 'D MMM YYYY HH:mm',
        llll: 'ddd D MMM YYYY HH:mm',
    },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <WebsocketProvider>
            <DragAndDropProvider>
                <React.Fragment>
                    <Router />
                    <ToastContainer />
                </React.Fragment>
            </DragAndDropProvider>
        </WebsocketProvider>
    </Provider>
);
