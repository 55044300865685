import ResponseTypes from '../enums/Response/Types';

const LOAN_OFFERS = [ResponseTypes.BUSINESS_LOAN_OFFER, ResponseTypes.PRIVATE_LOAN_OFFER];

export default {
    isLoanOffer: response => LOAN_OFFERS.includes(response.type),

    isDenial: response => response.type === ResponseTypes.DENIAL,

    isPrivateLoanOffer: response => response.type === ResponseTypes.PRIVATE_LOAN_OFFER,

    isBusinessLoanOffer: response => response.type === ResponseTypes.BUSINESS_LOAN_OFFER,

    isActive: response => response.status === 'active',

    isAccepted: (response, acceptance) =>
        acceptance && acceptance.responses && acceptance.responses.some(res => res.response.id === response.id),
};
