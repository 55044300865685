import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { OffersAction } from '../../../../actions/PartnerApiActions';
import style from './index.css';

class DenyButton extends Component {
    state = {
        loading: false,
        open: false,
        reason: '',
    };

    deny = async () => {
        this.setState({ loading: true });
        this.close();

        try {
            const denial = {
                application_id: this.props.applicationId,
                application_type: this.props.applicationType,
                revision: this.props.revision,
                product_id: this.props.productId,
                reason: this.state.reason,
                status: 'active',
            };

            await OffersAction.deny(denial);

            await this.setState({ loading: false });

            if (this.props.callback) {
                this.props.callback(this.props.applicationId);
            }
        } catch (err) {
            this.setState({ loading: false });
        }
    };

    open = () => this.setState({ open: true });
    close = () => this.setState({ open: false });

    render() {
        return (
            <Popup
                onOpen={this.open}
                onClose={this.close}
                open={this.state.open}
                on="click"
                trigger={
                    <Button
                        {...(this.props.rowButton ? { className: style.Button } : { color: 'red' })}
                        icon="remove"
                        content="Deny"
                        loading={this.state.loading}
                    />
                }
                header="Deny offer?"
                content={
                    <div>
                        <p>
                            Denying an offer will permanently remove the application from the Treat Applications view
                            for this product.
                        </p>
                        <Button fluid color="red" content="Yes, deny offer" onClick={this.deny} />
                    </div>
                }
            />
        );
    }
}

DenyButton.propTypes = {
    callback: PropTypes.func.isRequired,
    applicationId: PropTypes.string.isRequired,
    applicationType: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    revision: PropTypes.number.isRequired,
    rowButton: PropTypes.bool,
};
DenyButton.defaultProps = {
    rowButton: false,
};

export default DenyButton;
