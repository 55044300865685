import { getDropdownOptions } from './CommonHelper';
import { CDN_BASE_URL } from 'APP/config/cdn';
import Categories from '../enums/Products/Categories';
import ApplicationTypes from '../enums/Application/Types';
import BUSINESS_LOAN_PURPOSES from '../enums/Application/BusinessLoan/Purposes';
import PRIVATE_LOAN_PURPOSES from '../enums/Application/PrivateLoan/Purpose';
import OfferTypes from '../enums/Response/Types';
import ProductApplicationStates from '../enums/Products/ProductApplicationStates';

const loanCategories = [Categories.BUSINESS_LOAN, Categories.PRIVATE_LOAN, Categories.MORTGAGE_LOAN];
const upsellCategories = [
    Categories.LOAN_INSURANCE,
    Categories.SAFETY_INSURANCE,
    Categories.CREDIT_CARD,
    Categories.ACCIDENT_INSURANCE,
];

const categoryTexts = {
    [Categories.PRIVATE_LOAN]: 'Private Loan',
    [Categories.BUSINESS_LOAN]: 'Business Loan',
    [Categories.MORTGAGE_LOAN]: 'Mortgage Loan',
    [Categories.LOAN_INSURANCE]: 'Loan Insurance',
    [Categories.SAFETY_INSURANCE]: 'Safety Insurance',
    [Categories.ACCIDENT_INSURANCE]: 'Accident Insurance',
    [Categories.CREDIT_CARD]: 'Credit Card',
};

const categoryOptions = getDropdownOptions(categoryTexts);

// Move loan purpose to suitable helper
const privateTexts = {
    [PRIVATE_LOAN_PURPOSES.VEHICLE]: 'Buy vehicle',
    [PRIVATE_LOAN_PURPOSES.HOME_IMPROVEMENT]: 'House and home',
    [PRIVATE_LOAN_PURPOSES.HEALTH_VACATION]: 'Vacation, Wellness',
    [PRIVATE_LOAN_PURPOSES.LOAN_COLLECT]: 'Pay off loans/credits...',
    [PRIVATE_LOAN_PURPOSES.OTHER]: 'Other',
};

const businessTexts = {
    [BUSINESS_LOAN_PURPOSES.CASH_FLOW]: 'Generell likviditet/kassaflöde',
    [BUSINESS_LOAN_PURPOSES.INVESTMENT_SEASON]: 'Investering säsong',
    [BUSINESS_LOAN_PURPOSES.PURCHASE_INVENTORY]: 'Inköp av lager',
    [BUSINESS_LOAN_PURPOSES.PURCHASE_EQUIPMENT]: 'Inköp av maskiner/utrustning',
    [BUSINESS_LOAN_PURPOSES.REFURBISHMENT]: 'Renovering av lokal',
    [BUSINESS_LOAN_PURPOSES.PROPERTY_ACQUISITION]: 'Fastighetsförvärv',
    [BUSINESS_LOAN_PURPOSES.MARKETING]: 'Hemsida/marknadsföring',
    [BUSINESS_LOAN_PURPOSES.PERMIT_APPLICATION]: 'Tillståndsansökan',
    [BUSINESS_LOAN_PURPOSES.UNEXPECTED_EXPENSES]: 'Oväntade utgifter',
    [BUSINESS_LOAN_PURPOSES.EXPANSION]: 'Omformation/expansion',
    [BUSINESS_LOAN_PURPOSES.FINANCE_DEBT]: 'Finansiera skuld',
    [BUSINESS_LOAN_PURPOSES.HIRING]: 'Anställa personal',
    [BUSINESS_LOAN_PURPOSES.ACQUISITION]: 'Förvärv',
    [BUSINESS_LOAN_PURPOSES.OTHER]: 'Övrigt',
};

const applicationProductTypes = {
    [ApplicationTypes.PRIVATE]: Categories.PRIVATE_LOAN,
    [ApplicationTypes.BUSINESS]: Categories.BUSINESS_LOAN,
};

const loanPurposeOptions = {
    [OfferTypes.PRIVATE_LOAN_OFFER]: getDropdownOptions(privateTexts),
    [OfferTypes.BUSINESS_LOAN_OFFER]: getDropdownOptions(businessTexts),
};

const productApplicationStateTexts = {
    [ProductApplicationStates.FILTERED]: 'Filtered',
    [ProductApplicationStates.OFFERED]: 'Offered',
    [ProductApplicationStates.DENIED]: 'Denied',
    [ProductApplicationStates.ACCEPTED]: 'Accepted',
    [ProductApplicationStates.BILL_OF_DEBT_SENT]: 'Bill of debt sent',
    [ProductApplicationStates.PAID_OUT]: 'Paid out',
    [ProductApplicationStates.WITHDRAWN_ACCEPTED]: 'Withdrawn accepted',
};

export default {
    isLoan: product => loanCategories.includes(product.category),

    isBusinessLoan: product => product.category === Categories.BUSINESS_LOAN,

    isPrivateLoan: product => product.category === Categories.PRIVATE_LOAN,

    isMortgageLoan: product => product.category === Categories.MORTGAGE_LOAN,

    getProductRequirements: product => product.settings.find(setting => setting.id === 'product_requirements'),

    getLoanPurposeText(purpose, product) {
        if (product && product.category === Categories.BUSINESS_LOAN) {
            return businessTexts[purpose];
        }

        return privateTexts[purpose];
    },

    getApplicationProductType: applicationType => applicationProductTypes[applicationType] || Categories.PRIVATE_LOAN,

    getLoanPurposeOptions: offerType => loanPurposeOptions[offerType],

    getProductLogoSvg(partnerInternalName, productLogoName) {
        const logoName = productLogoName || 'logo';

        return `${CDN_BASE_URL}/partners/${partnerInternalName}/logos/${logoName}.svg`;
    },

    productApplicationStateOptions: getDropdownOptions(productApplicationStateTexts),
    loanCategoryOptions: categoryOptions.filter(o => loanCategories.includes(o.value)),
    upsellCategoryOptions: categoryOptions.filter(o => upsellCategories.includes(o.value)),

    categories: Categories,

    categoryTexts,
};
