import React from 'react';
import PropTypes from 'prop-types';

import { Validation } from '@lendoab/lendo-redux-form-helpers';
import Field from '@lendoab/lendo-redux-form-helpers';

import { Form, Header } from 'semantic-ui-react';
import { BusinessOptions, Options } from '../../../enums/Application/AmortizeLength';

const loanTypeOptions = [
    { key: '1', text: 'Straight', value: 'straight' },
    { key: '2', text: 'Annuity', value: 'annuity' },
];

const businessLoanTypesOptions = [
    { key: '1', text: 'Checkkredit', value: 'revolver_loan' },
    { key: '2', text: 'Eu-loan', value: 'eu_loan' },
    { key: '3', text: 'Eu-loan 100% guarantor', value: 'eu_loan_100_guarantor' },
    { key: '4', text: 'Flexibel amortering', value: 'flexible_amortization' },
    { key: '5', text: 'Uppstartslån', value: 'startup_loan' },
    { key: '6', text: 'Uppstartslån med ränterabatt', value: 'startup_loan_with_interest_discount' },
    { key: '7', text: 'Ingen av dessa', value: 'none_of_the_above' },
    { key: '8', text: 'Statlig-garanti', value: 'state_guarantee' },
];

const LoanDetails = props => {
    const AmortizeLengthOptions = props.isBusiness ? BusinessOptions : Options;

    const businessLoanAmountValidation = [props.required, props.maxLoanAmount];
    const loanAmountValidation = props.isBusiness ? businessLoanAmountValidation : [Validation.required];

    return (
        <div>
            <Header>Loan details</Header>
            <Form.Group widths="2">
                <Field
                    component="dropdown"
                    validate={[Validation.required]}
                    name="repayment_type"
                    label="Repayment type"
                    options={loanTypeOptions}
                />
                {props.isBusiness && (
                    <Field
                        component="dropdown"
                        placeholder="Select type"
                        name="business_loan_type"
                        label="Business loan type"
                        options={businessLoanTypesOptions}
                    />
                )}
            </Form.Group>
            <Form.Group widths="2">
                <Field
                    component="input"
                    parse="int"
                    label="Loan amount"
                    name="amount"
                    validate={loanAmountValidation}
                />
                <Field
                    validate={[Validation.required]}
                    component="dropdown"
                    name="amortize_length"
                    label="Amortize length"
                    style={{ minWidth: '0px' }}
                    options={AmortizeLengthOptions}
                />
            </Form.Group>
            {!props.isBusiness && (
                <Form.Group widths="2">
                    <Field
                        component="input"
                        parse="int"
                        label="Amount to solve (External)"
                        name="amount_to_solve_external"
                        placeholder="External amount to solve"
                    />
                    <Field
                        component="input"
                        parse="int"
                        label="Amount to solve (Internal)"
                        name="amount_to_solve_internal"
                        placeholder="Internal amount to solve"
                    />
                </Form.Group>
            )}
        </div>
    );
};

LoanDetails.propTypes = {
    isBusiness: PropTypes.bool,
};

LoanDetails.defaultProps = {
    isBusiness: false,
};

export default LoanDetails;
