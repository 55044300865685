import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createUUID } from '@lendoab/lendose-shared';

const WEBSOCKET_STATUS_UP = 'UP';
const WEBSOCKET_STATUS_DOWN = 'DOWN';

const READYSTATE_OPEN = 1;

class ws {
    constructor(url, onOpenCallback, onCloseCallback) {
        this.url = url;
        this.shouldBeConnected = false;
        this.connection = null;
        this.subscriptions = {};
        this.onOpen = onOpenCallback;
        this.onClose = onCloseCallback;
    }

    connect() {
        if (this.connection !== null) {
            return;
        }

        this.connection = new WebSocket(this.url);
        this.connection.addEventListener('open', this._onOpen);
        this.connection.addEventListener('close', this._onClose);
        this.connection.addEventListener('message', this._onMessage);
        this.subscriptions = {};
        this.shouldBeConnected = true;
    }

    disconnect() {
        if (this.connection === null) {
            return;
        }

        this.onClose && this.onClose();
        this.shouldBeConnected = false;

        const ws = this.connection;
        this.connection = null;

        ws.removeEventListener('open', this._onOpen);
        ws.removeEventListener('close', this._onClose);
        ws.removeEventListener('message', this._onMessage);

        // 1000 indicates a normal closure, meaning that the purpose for
        // which the connection was established has been fulfilled.
        // https://tools.ietf.org/html/rfc6455#section-7.4.1
        ws.close(1000);
    }

    _onOpen = () => {
        this.onOpen && this.onOpen();
    };

    _onClose = () => {
        if (this.connection !== null) {
            this.connection.removeEventListener('open', this._onOpen);
            this.connection.removeEventListener('close', this._onClose);
            this.connection.removeEventListener('message', this._onMessage);
            this.connection = null;
        }

        this.onClose && this.onClose();

        // Check if we should try to reconnect.
        if (this.shouldBeConnected) {
            setTimeout(() => {
                this.connect();
            }, 5000);
        }
    };

    _onMessage = ({ data }) => {
        try {
            const payload = JSON.parse(data);
            if (payload.channel) {
                if (this.subscriptions[payload.channel]) {
                    this.subscriptions[payload.channel](payload);
                }
            } else {
                console && console.log('websocket: discarding message without channel');
            }
        } catch (e) {
            console && console.log('websocket: error handling websocket message', e);
        }
    };

    Subscribe = (key, body, callback) => {
        if (!this.connection || this.connection.readyState !== READYSTATE_OPEN) {
            console && console.log('websocket: Subscribe when not connected');
            return;
        }

        const channel = createUUID();
        this.subscriptions[channel] = callback;

        this.connection.send(
            JSON.stringify({
                channel,
                message: 'subscribe',
                body: {
                    key,
                    body,
                },
            })
        );

        return () => {
            delete this.subscriptions[channel];
            this.connection &&
                this.connection.readyState === READYSTATE_OPEN &&
                this.connection.send(
                    JSON.stringify({
                        channel,
                        message: 'unsubscribe',
                    })
                );
        };
    };

    SendMessage = (message, body) => {
        if (!this.connection || this.connection.readyState !== READYSTATE_OPEN) {
            console && console.log('websocket: SendMessage when not connected');
            return;
        }

        this.connection.send(
            JSON.stringify({
                message,
                body,
            })
        );
    };
}

const websocketContext = createContext({});

function WebsocketProvider(props) {
    const { children, isAuthenticated } = props;
    const socket = useRef(undefined);
    const [status, setStatus] = useState(WEBSOCKET_STATUS_DOWN);

    if (!socket.current) {
        const protocol = window.location.protocol.match(/^https/) ? 'wss' : 'ws';
        socket.current = new ws(
            `${protocol}://${document.location.host}/api/ws`,
            () => setStatus(WEBSOCKET_STATUS_UP),
            () => setStatus(WEBSOCKET_STATUS_DOWN)
        );
    }

    useEffect(() => {
        if (isAuthenticated) {
            socket.current.connect();
        } else {
            socket.current.disconnect();
        }
    }, [isAuthenticated]);

    const value = useMemo(
        () => ({
            status,
            subscribe: socket.current.Subscribe,
            sendMessage: socket.current.SendMessage,
        }),
        [status]
    );

    return <websocketContext.Provider value={value}>{children}</websocketContext.Provider>;
}

export default connect(function(state) {
    const { isAuthenticated } = state.auth;
    return {
        isAuthenticated,
    };
})(WebsocketProvider);

export function useWebsocket() {
    const { status, subscribe, sendMessage } = useContext(websocketContext);

    return {
        connected: status === WEBSOCKET_STATUS_UP,
        subscribe,
        sendMessage,
    };
}
