import React, { Fragment } from 'react';
import {
    Columns,
    Column,
    Box,
    Stack,
    Text,
    Select,
    ButtonRow,
    ButtonRowButton,
    StatusCircle,
} from '@lendoab/aphrodite';
import MortgageInputLabel from 'APP/pages/MortgageApplication/MortgageForm/MortgageInputLabel';
import { MarketingConsentFormMachine } from '../MarketingConsentMachine';
import { TransactionalConsentFormMachine } from '../TransactionalConsentMachine';
import { MachineLearningConsentFormMachine } from '../MachineLearningConsentMachine';
import { newValidator, useForm } from '@lendoab/use-form';
import {
    CONSENT_STATES,
    CONSENT_STATE_NAMES,
    marketingEmailDescription,
    transactionalEmailDescription,
    marketingSMSDescription,
    transactionalSMSDescription,
    smartServiceUseDescription,
    smartServiceImproveDescription,
} from '../ConsentConstants';
import { required } from '@lendoab/web-validations';

const options = Object.values(CONSENT_STATES).map(state => {
    return { value: state, label: CONSENT_STATE_NAMES[state] };
});

function hasDifferentValue(newValue, initialValue) {
    return newValue !== initialValue;
}

export default function ApplicantConsentSettings(props) {
    const { applicant, onClose, applicationId } = props;

    const [marketingState, marketingDispatch] = MarketingConsentFormMachine({
        userEmail: applicant.email,
        userPhone: applicant.phone,
        userId: applicant.user_id,
    });

    const [transactionalState, transactionalDispatch] = TransactionalConsentFormMachine({
        userEmail: applicant.email,
        userPhone: applicant.phone,
        applicantId: applicant.id,
    });

    const [smartServiceUseState, smartServiceUseDispatch] = MachineLearningConsentFormMachine({
        applicationId: applicationId,
        consentName: 'smart_services_use',
    });

    const [smartServiceImproveState, smartServiceImproveDispatch] = MachineLearningConsentFormMachine({
        applicationId: applicationId,
        consentName: 'smart_services_improve',
    });

    function handleSubmit(values) {
        if (
            hasDifferentValue(values.marketingEmail, marketingState.emailStatus) ||
            hasDifferentValue(values.marketingPhone, marketingState.phoneStatus)
        ) {
            marketingDispatch({
                type: 'SUBMIT',
                marketingEmailValue: values.marketingEmail,
                marketingPhoneValue: values.marketingPhone,
            });
        }

        if (
            hasDifferentValue(values.transactionalEmail, transactionalState.emailStatus) ||
            hasDifferentValue(values.transactionalPhone, transactionalState.phoneStatus)
        ) {
            transactionalDispatch({
                type: 'SUBMIT',
                transactionalEmailValue: values.transactionalEmail,
                transactionalPhoneValue: values.transactionalPhone,
            });
        }

        if (hasDifferentValue(values.smartServiceUse, smartServiceUseState.consentState)) {
            smartServiceUseDispatch({
                type: 'SUBMIT',
                consentValue: values.smartServiceUse,
            });
        }

        if (hasDifferentValue(values.smartServiceImprove, smartServiceImproveState.consentState)) {
            smartServiceImproveDispatch({
                type: 'SUBMIT',
                consentValue: values.smartServiceImprove,
            });
        }
    }

    return (
        <Box position="relative">
            {marketingState.status === 'idle' &&
            transactionalState.status === 'idle' &&
            smartServiceUseState.status === 'idle' &&
            smartServiceImproveState.status == 'idle' ? (
                <ConsentForm
                    marketingState={marketingState}
                    transactionalState={transactionalState}
                    smartServiceUseState={smartServiceUseState}
                    smartServiceImproveState={smartServiceImproveState}
                    onSubmit={handleSubmit}
                    onClose={onClose}
                />
            ) : (
                <Box display="flex" padding="2xl">
                    <StatusCircle style={{ width: '30px', height: '30px' }} status="loading" />
                </Box>
            )}
        </Box>
    );
}

function getFields(marketingState, transactionalState, smartServiceUseState, smartServiceImproveState) {
    const fields = {
        marketingEmail: {
            value: marketingState.emailStatus,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Email consent is required.')],
        },
        marketingPhone: {
            value: marketingState.phoneStatus,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Phone SMS consent is required.')],
        },
        transactionalEmail: {
            value: transactionalState.emailStatus,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Email consent is required.')],
        },
        transactionalPhone: {
            value: transactionalState.phoneStatus,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Phone SMS consent is required.')],
        },
    };

    if (smartServiceUseState.consentState) {
        fields['smartServiceUse'] = {
            value: smartServiceUseState.consentState,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Smart services use is required.')],
        };
    }

    if (smartServiceImproveState.consentState) {
        fields['smartServiceImprove'] = {
            value: smartServiceImproveState.consentState,
            meta: { type: 'select' },
            validators: [newValidator(required, 'error', 'Smart services improve is required.')],
        };
    }

    return fields;
}

function ConsentForm(props) {
    const { marketingState, transactionalState, smartServiceUseState, smartServiceImproveState, onSubmit } = props;

    const { commitValue, getFieldValue, getFieldState, getFieldMessage, handleSubmit } = useForm({
        fields: {
            ...getFields(marketingState, transactionalState, smartServiceUseState, smartServiceImproveState),
        },
        onSubmit: values => {
            typeof onSubmit === 'function' && onSubmit(values);
        },
    });

    return (
        <Fragment>
            <Stack space="large" padding="2xl">
                <Text size="small" weight="bold">
                    Applicant marketing consents
                </Text>

                <Columns space="medium" collapseBelow="desktop">
                    <Column>
                        <Stack space="base">
                            <MortgageInputLabel
                                label="E-mails"
                                tooltip={marketingEmailDescription}
                                htmlFor="marketingEmail"
                            />

                            <Select
                                name="marketingEmails"
                                value={getFieldValue('marketingEmail')}
                                onChange={value => commitValue('marketingEmail', value)}
                                success={getFieldState('marketingEmail') === 'success'}
                                options={options}
                                placeholder="Choose"
                                error={getFieldMessage('marketingEmail')}
                            />
                        </Stack>
                    </Column>
                    <Column>
                        <Stack space="base">
                            <MortgageInputLabel
                                label="Phone SMS"
                                tooltip={marketingSMSDescription}
                                htmlFor="marketingPhone"
                            />

                            <Select
                                name="marketingPhone"
                                value={getFieldValue('marketingPhone')}
                                onChange={value => commitValue('marketingPhone', value)}
                                success={getFieldState('marketingPhone') === 'success'}
                                options={options}
                                placeholder="Choose"
                                error={getFieldMessage('marketingPhone')}
                            />
                        </Stack>
                    </Column>
                </Columns>
                <Text size="small" weight="bold">
                    Applicant transactional consents
                </Text>
                <Columns space="medium" collapseBelow="desktop">
                    <Column>
                        <Stack space="base">
                            <MortgageInputLabel
                                label="E-mails"
                                tooltip={transactionalEmailDescription}
                                htmlFor="transactionalEmail"
                            />
                            <Select
                                name="transactionalEmail"
                                value={getFieldValue('transactionalEmail')}
                                onChange={value => commitValue('transactionalEmail', value)}
                                success={getFieldState('transactionalEmail') === 'success'}
                                options={options}
                                placeholder="Choose"
                                error={getFieldMessage('transactionalEmail')}
                            />
                        </Stack>
                    </Column>
                    <Column>
                        <Stack space="base">
                            <MortgageInputLabel
                                label="Phone SMS"
                                tooltip={transactionalSMSDescription}
                                htmlFor="transactionalPhone"
                            />
                            <Select
                                name="transactionalPhone"
                                value={getFieldValue('transactionalPhone')}
                                onChange={value => commitValue('transactionalPhone', value)}
                                success={getFieldState('transactionalPhone') === 'success'}
                                options={options}
                                placeholder="Choose"
                                error={getFieldMessage('transactionalPhone')}
                            />
                        </Stack>
                    </Column>
                </Columns>
                {(smartServiceUseState.consentState || smartServiceImproveState.consentState) && (
                    <Text size="small" weight="bold">
                        Application smart services consents
                    </Text>
                )}
                {(smartServiceUseState.consentState || smartServiceImproveState.consentState) && (
                    <Columns space="medium" collapseBelow="desktop">
                        {getFieldValue('smartServiceUse') !== null && (
                            <Column>
                                <Stack space="base">
                                    <MortgageInputLabel
                                        label="Smart Service Usage"
                                        tooltip={smartServiceUseDescription}
                                        htmlFor="smartServiceUse"
                                    />
                                    <Select
                                        name="smartServiceUse"
                                        value={getFieldValue('smartServiceUse')}
                                        onChange={value => commitValue('smartServiceUse', value)}
                                        success={getFieldState('smartServiceUse') === 'success'}
                                        options={options}
                                        placeholder="Choose"
                                        error={getFieldMessage('smartServiceUse')}
                                    />
                                </Stack>
                            </Column>
                        )}
                        {smartServiceImproveState.consentState && (
                            <Column>
                                <Stack space="base">
                                    <MortgageInputLabel
                                        label="Smart Service Improve"
                                        tooltip={smartServiceImproveDescription}
                                        htmlFor="smartServiceImprove"
                                    />
                                    <Select
                                        name="smartServiceImprove"
                                        value={getFieldValue('smartServiceImprove')}
                                        onChange={value => commitValue('smartServiceImprove', value)}
                                        success={getFieldState('smartServiceImprove') === 'success'}
                                        options={options}
                                        placeholder="Choose"
                                        error={getFieldMessage('smartServiceImprove')}
                                    />
                                </Stack>
                            </Column>
                        )}
                    </Columns>
                )}
            </Stack>
            <ButtonRow>
                <Box display="flex" fluid padding="medium">
                    <ButtonRowButton primary onClick={handleSubmit}>
                        Update consent settings
                    </ButtonRowButton>
                </Box>
            </ButtonRow>
        </Fragment>
    );
}
