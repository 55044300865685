import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';
import ApplicantCard from './ApplicantCard/index';

function ApplicantCardsWrapper(props) {
    // We want main first
    const applicants = props.applicants.sort(a => (a.type === 'main' ? -1 : 1));
    return (
        <Segment>
            <Grid columns="2" celled="internally">
                {applicants.map(applicant => (
                    <Grid.Column key={applicant.ssn} className="no-padding">
                        <ApplicantCard applicant={applicant} type={applicant.type} />
                    </Grid.Column>
                ))}
            </Grid>
        </Segment>
    );
}

ApplicantCardsWrapper.propTypes = {
    applicants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ApplicantCardsWrapper.defaultProps = {};

export default ApplicantCardsWrapper;
