import React from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react';

function NothingToShow() {
    return (
        <Segment basic padded>
            <Header as="h2" icon textAlign="center">
                <Icon name="info" circular />
                <Header.Content>No result</Header.Content>
            </Header>
        </Segment>
    );
}

export default NothingToShow;
