import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

function ApplicationDataGrid(props) {
    const { product } = props;

    return (
        <Grid columns="equal" celled="internally">
            {props.columns.map(c => (
                <Grid.Column textAlign="center" key={c.key}>
                    <Header as="h2">
                        <Header.Subheader>{c.header}</Header.Subheader>
                        {c.value(product)}
                    </Header>
                </Grid.Column>
            ))}
        </Grid>
    );
}

ApplicationDataGrid.propTypes = {};
ApplicationDataGrid.defaultProps = {};

export default ApplicationDataGrid;
