import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';

const transport = AjaxHelper;
const baseUrl = `${config.api.prefix}`;
/**
 *
 * @param {String} postalCode
 */
export default {
    async verifyPostalCode(postalCode) {
        return (await transport.get(`${baseUrl}/addresses/postal-code/verify?postal_code=${postalCode}`)).data;
    },
    /**
     *
     * @param {String} input
     * @param {String} sessionId
     */
    async getPlacesPredictions(input, sessionId) {
        return transport.get(`${baseUrl}/addresses/predictions?input=${input}&sessiontoken=${sessionId}`);
    },

    /**
     *
     * @param {String} placeId
     * @param {String} sessionId
     */
    async getPlacesDetails(placeId, sessionId) {
        return transport.get(`${baseUrl}/addresses/details?id=${placeId}&sessiontoken=${sessionId}`);
    },

    /**
     *
     * @param {String} postalCode
     */
    async fetchPostalData(postalCode) {
        return transport.get(`mittbolan/address-service/api/address/postal-data?postal_code=${postalCode}`);
    },
};
