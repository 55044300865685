import { useReducer, useEffect } from 'react';
import { createEffect } from 'APP/helpers/Effect';
import SubscriptionActions from 'APP/actions/SubscriptionActions';
import Toast from 'APP/common/Toast';

function reducer(state, event) {
    switch (state.status) {
        case 'idle': {
            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    status: 'pending_submit',
                    effects: [
                        ...state.effects,
                        createEffect('submit', {
                            consentValue: event.consentValue,
                        }),
                    ],
                };
            }

            return state;
        }

        case 'pending': {
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    status: 'idle',
                    consentState: event.response.state ? event.response.state : null,
                };
            }

            if (event.type === 'REJECT') {
                Toast('error', `Unable to fetch machine learning consents. ${event.error}`);
                return {
                    ...state,
                    status: 'idle',
                };
            }

            return state;
        }

        case 'pending_submit': {
            if (event.type === 'RESOLVE') {
                if (event.update) {
                    Toast('success', `Machine learning consents updated succesfully`);
                }
                return {
                    ...state,
                    status: 'pending',
                    effects: [...state.effects, createEffect('fetch')],
                };
            }

            if (event.type === 'REJECT') {
                Toast('error', `Unable to update machine learning consent. ${event.error}`);
                return {
                    ...state,
                    status: 'idle',
                };
            }
            return state;
        }

        default: {
            return state;
        }
    }
}

export function MachineLearningConsentFormMachine(applicationData) {
    const [{ effects, ...state }, dispatch] = useReducer(reducer, {
        status: 'pending',
        effects: [createEffect('fetch')],
        consentName: null,
        consentState: null,
        applicationId: null,
        ...applicationData,
    });

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }

            effect.markAsStarted();

            if (effect.type === 'fetch') {
                SubscriptionActions.getMachineLearningConsents(state.applicationId, state.consentName)
                    .then(response => dispatch({ type: 'RESOLVE', response: response }))
                    .catch(err => dispatch({ type: 'REJECT', error: err }));
            }

            if (effect.type === 'submit') {
                if (state.consentState) {
                    const payload = {
                        state: effect.consentValue,
                    };

                    SubscriptionActions.setMachineLearningConsents(state.applicationId, state.consentName, payload)
                        .then(() => dispatch({ type: 'RESOLVE', update: true }))
                        .catch(err => dispatch({ type: 'REJECT', error: err }));
                } else {
                    dispatch({ type: 'RESOLVE', update: false });
                }
            }
        }
    }, [state, effects]);

    return [state, dispatch];
}
