let value = null;

/**
 * Copy text to computer clipboard
 */
export async function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        console.err('Could not copy, not supported by browser.');
        return;
    }
    await navigator.clipboard.writeText(text);
}

const onCopy = function(e) {
    if (typeof value === 'string') {
        if (e.clipboardData) {
            e.clipboardData.setData('text/plain', value);
            e.preventDefault();
        }
        if (window.clipboardData && window.clipboardData) {
            window.clipboardData.setData('Text', value);
            e.preventDefault();
        }
        value = null;
    }
    document.removeEventListener('copy', onCopy);
};

export default {
    /**
     * @param {string} text
     */
    copy(text) {
        try {
            value = text;
            document.addEventListener('copy', onCopy);
            document.execCommand('copy');
            setTimeout(() => {
                document.removeEventListener('copy', onCopy);
            }, 100);
        } catch (e) {}
    },
};
