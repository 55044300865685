import React from 'react';
import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';
import DateHelper from 'APP/helpers/DateHelper';
import { RepaymentTypes } from 'APP/enums/Application/PrivateLoan/RepaymentTypes';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';

function BusinessAcceptedOffer(props) {
    return (
        <Table basic="very">
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Amount" />
                        <strong>{DataDisplayHelper.currency(props.offer.amount, 'kr')} </strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Amortize length" />
                        <strong>{ApplicationHelper.renderPeriod(props.offer.amortize_length)}</strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Total cost" />
                        <strong>{DataDisplayHelper.currency(props.offer.total_cost, 'kr')}</strong>
                    </Table.Cell>

                    <Table.Cell>
                        <Header as="h4" sub content="Monthly interest rate" />
                        <strong>{DataDisplayHelper.decimals(props.offer.interest_rate_nominal)} %</strong>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Offer sent at" />
                        <span>{DateHelper.asYmdHis(props.offer.created_at)}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Repayment type" />
                        <span>{RepaymentTypes[props.offer.repayment_type]}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="First monthly cost" />
                        <span>{DataDisplayHelper.currency(props.offer.monthly_cost_first, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Last monthly cost" />
                        <span>{DataDisplayHelper.currency(props.offer.monthly_cost_last, 'kr')}</span>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        <Header as="h4" sub content="Setup fee" />
                        <span>{DataDisplayHelper.currency(props.offer.setup_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Direct debit fee" />
                        <span>{DataDisplayHelper.currency(props.offer.autogiro_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Postal fee" />
                        <span>{DataDisplayHelper.currency(props.offer.administration_fee, 'kr')}</span>
                    </Table.Cell>
                    <Table.Cell>
                        <Header as="h4" sub content="Early redemption fee" />
                        <span>{props.offer.early_redemption_fee ? 'Yes' : 'No'}</span>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

BusinessAcceptedOffer.propTypes = {
    offer: PropTypes.shape({}).isRequired,
};
BusinessAcceptedOffer.defaultProps = {};

export default BusinessAcceptedOffer;
