import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import DateHelper from 'APP/helpers/DateHelper';
import { OffersAction } from 'APP/actions/PartnerApiActions/index';
import Toast from 'APP/common/Toast';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import ResponseStatuses from 'APP/enums/Response/Statuses';
import MoreDetails from '../../../../MoreDetailsLink';
import ResponseLabel from './ResponseLabel';
import WithdrawOfferButton from '../../../../ActionButtons/WithdrawOfferButton';
import ResendOfferButton from './ActionButtons/ResendOfferButton';
import WithdrawnOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/WithdrawnOfferButton';
import DraftOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/DraftOfferButton';
import ResponseHelper from 'APP/helpers/ResponseHelper';
import { addBreadcrumb } from '@sentry/react';
import { HandleException } from 'APP/helpers/ErrorHelper';

class LoanOfferRow extends Component {
    withdrawOffer = async (data = {}) => {
        const { response } = this.props;

        const isBusiness = ResponseHelper.isBusinessLoanOffer(this.props.response);

        if (isBusiness) {
            addBreadcrumb({
                message: `Sending a business withdraw from the LoanOfferRow component`,
                data,
            });
        }

        try {
            await OffersAction.withdrawOffer(response, data);
            this.props.withdrawOfferCallback();
            Toast('success', 'Offer withdrawn!');
        } catch (err) {
            HandleException(err, 'Could not withdraw this offer');
        }
    };

    resendOffer = async (data = {}) => {
        try {
            await OffersAction.resendOffer(this.props.response, this.props.currentRevision, data);
            this.props.resendOfferCallback();
            Toast('success', 'Offer resent!');
        } catch (err) {
            HandleException(err, 'Could not resend the offer');
        }
    };

    renderWithdrawButton() {
        const isBusiness = ResponseHelper.isBusinessLoanOffer(this.props.response);

        if (isBusiness && this.props.response.state === ResponseStatuses.LOAN_PAID) {
            return null;
        }
        if (this.props.response.status === ResponseStatuses.WITHDRAWN) {
            return <WithdrawnOfferButton />;
        } else if (this.props.response.status === ResponseStatuses.DRAFT) {
            return <DraftOfferButton />;
        }

        return (
            <WithdrawOfferButton
                isBusiness={isBusiness}
                onClick={this.withdrawOffer}
                disabled={this.props.disableButtons}
                small
            />
        );
    }

    render() {
        const { response } = this.props;
        const isBusiness = response.type === 'business_loan_offer';
        // Set the widths absolute, since we want the same columns for the other tables in the expanded rows.
        return (
            <Table.Row>
                <Table.Cell width={2} style={{ paddingLeft: '20px' }}>
                    <ResponseLabel response={response} />
                </Table.Cell>
                <Table.Cell width={3}>{DateHelper.asYmdHis(response.created_at)}</Table.Cell>
                <Table.Cell width={2}>{DataDisplayHelper.currency(response.amount, 'kr')}</Table.Cell>
                <Table.Cell width={2}>{ApplicationHelper.renderPeriod(response.amortize_length)}</Table.Cell>
                <Table.Cell width={2}>
                    <MoreDetails response={response} />
                </Table.Cell>
                <Table.Cell width={5}>
                    {this.renderWithdrawButton()}

                    {!this.props.disableButtons &&
                        this.props.latestActiveLoanOffer &&
                        this.props.currentRevision !== this.props.response.revision && (
                            <ResendOfferButton isBusiness={isBusiness} onClick={this.resendOffer} />
                        )}
                </Table.Cell>
            </Table.Row>
        );
    }
}

/* eslint-disable camelcase */
LoanOfferRow.propTypes = {
    currentRevision: PropTypes.number.isRequired,
    response: PropTypes.shape({
        revision: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        amortize_length: PropTypes.number.isRequired,
    }).isRequired,
    resendOfferCallback: PropTypes.func.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
    disableButtons: PropTypes.bool.isRequired,
    latestActiveLoanOffer: PropTypes.bool.isRequired,
};
LoanOfferRow.defaultProps = {};
/* eslint-enable camelcase */

export default LoanOfferRow;
