import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Icon, Modal } from 'semantic-ui-react';
import ClipboardHelper from '../../../helpers/ClipboardHelper';

function OfferModal(props) {
    return (
        <Modal size="small" open={props.open}>
            <Modal.Header>
                <Icon name="checkmark" color="green" /> {props.header}
            </Modal.Header>
            {props.offerId && (
                <Modal.Content>
                    <p onClick={() => ClipboardHelper.copy(props.offerId)}>ID: {props.offerId}</p>
                    <p>Validity: {moment(props.expiresAt).format('LLLL')}</p>
                </Modal.Content>
            )}
            <Modal.Content>
                {props.nextApplication && (
                    <Button onClick={props.onSubmit} primary>
                        Next application
                    </Button>
                )}
                <Button primary onClick={props.onClose}>
                    Close
                </Button>
            </Modal.Content>
        </Modal>
    );
}

OfferModal.propTypes = {
    header: PropTypes.string,
    open: PropTypes.bool.isRequired,
    offerId: PropTypes.string,
    expiresAt: PropTypes.instanceOf(Date),
    nextApplication: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

OfferModal.defaultProps = {
    header: '',
    offerId: '',
    nextApplication: '',
    expiresAt: null,
};

export default OfferModal;
