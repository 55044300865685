import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import ApplicationHelper from '../../../../helpers/ApplicationHelper';
import DataDisplayHelper from '../../../../helpers/DataDisplayHelper';
import ResponseLabel from './ResponseLabel';
import Toast from '../../../../common/Toast';
import { OffersAction } from '../../../../actions/PartnerApiActions';
import ResponseStatuses from 'APP/enums/Response/Statuses';
import moment from 'moment';
import WithdrawOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/WithdrawOfferButton';
import MoreDetails from 'APP/pages/PartnerApplication/View/MoreDetailsLink';
import ResponseHelper from 'APP/helpers/ResponseHelper';
import WithdrawnOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/WithdrawnOfferButton';
import DraftOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/DraftOfferButton';
import { addBreadcrumb } from '@sentry/react';
import { HandleException } from 'APP/helpers/ErrorHelper';

class ResponseRow extends Component {
    withdrawOffer = async (data = {}) => {
        const { response } = this.props;

        const isBusiness = ResponseHelper.isBusinessLoanOffer(this.props.response);
        if (isBusiness) {
            addBreadcrumb({
                message: `Sending a business withdraw from the ResponseRow component`,
                data,
            });
        }

        try {
            await OffersAction.withdrawOffer(response, data);
            Toast('success', 'Offer has been withdrawn.');
            this.props.withdrawOfferCallback();
        } catch (e) {
            HandleException(e, 'Could not withdraw this offer');
        }
    };

    renderWithdrawButton() {
        if (this.props.response.status === ResponseStatuses.WITHDRAWN) {
            return <WithdrawnOfferButton />;
        } else if (this.props.response.status === ResponseStatuses.DRAFT) {
            return <DraftOfferButton />;
        }

        const isBusiness = ResponseHelper.isBusinessLoanOffer(this.props.response);

        return (
            <WithdrawOfferButton
                isBusiness={isBusiness}
                onClick={this.withdrawOffer}
                disabled={this.props.disableButtons}
                small
            />
        );
    }

    render() {
        const { response } = this.props;

        return (
            <Table.Row>
                <Table.Cell style={{ paddingLeft: '20px' }}>
                    <ResponseLabel response={response} />
                </Table.Cell>
                <Table.Cell>{moment(response.created_at).format('lll')}</Table.Cell>
                <Table.Cell>{response.amount && DataDisplayHelper.currency(response.amount, 'kr')}</Table.Cell>
                <Table.Cell>
                    {response.amortize_length && ApplicationHelper.renderPeriod(response.amortize_length)}
                </Table.Cell>
                <Table.Cell>{ResponseHelper.isLoanOffer(response) && <MoreDetails response={response} />}</Table.Cell>
                <Table.Cell textAlign="right">{this.renderWithdrawButton()}</Table.Cell>
            </Table.Row>
        );
    }
}

ResponseRow.propTypes = {
    response: PropTypes.object.isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
    disableActions: PropTypes.bool.isRequired,
};
ResponseRow.defaultProps = {};

export default ResponseRow;
