import moment from 'moment';
import companyTypes from 'APP/enums/Company/Types';
import companyRiskClasses from 'APP/enums/Company/RiskClasses';
import DataDisplayHelper from './DataDisplayHelper';
import { getDropdownOptions } from './CommonHelper';

const companyTypeStrings = {
    [companyTypes.AB]: 'Aktiebolag',
    [companyTypes.HB]: 'Handels-/Kommanditbolag',
    [companyTypes.EN]: 'Enskild firma',
};

const companyRiskClassString = {
    [companyRiskClasses.ONE]: '1',
    [companyRiskClasses.TWO]: '2',
    [companyRiskClasses.THREE]: '3',
    [companyRiskClasses.FOUR]: '4',
    [companyRiskClasses.FIVE]: '5',
};

export default {
    renderCompanyType: company => companyTypeStrings[company.type],

    renderCompanyName(company) {
        return this.hasNoRegisteredName(company) ? 'Ej registrerat' : company.name;
    },

    renderOrganisationNumber: company => {
        if (company.registration_number === null || company.registration_number === undefined) {
            return null;
        }

        const numbersFromBack = 4;

        return `${company.registration_number.slice(
            0,
            company.registration_number.length - numbersFromBack
        )}-${company.registration_number.slice(-numbersFromBack)}`;
    },

    renderCompanyAllaBolagLink: company => {
        if (company.registration_number === null || company.registration_number === undefined) {
            return null;
        }

        return 'https://www.allabolag.se/' + company.registration_number;
    },

    renderRegisteredSince: company =>
        company.credit_data && company.credit_data.registration_date
            ? moment(company.credit_data.registration_date).format('MM/Y')
            : 'N/A',

    renderNetSales: company =>
        company.credit_data && company.credit_data.net_sales !== null
            ? DataDisplayHelper.currency(company.credit_data.net_sales, 'kr')
            : 'N/A',

    renderSelfEstimatedRevenue: company =>
        company && company.self_estimated_revenue !== null
            ? DataDisplayHelper.currency(company.self_estimated_revenue, 'kr')
            : 'N/A',

    hasNoRegisteredName(company) {
        return company.name === 'Verksamhetens namn ej reg hos UC';
    },

    types: companyTypes,

    values: Object.values(companyTypes),

    options: getDropdownOptions(companyTypeStrings),

    riskClassOptions: getDropdownOptions(companyRiskClassString),

    isLLC: ({ type }) => type === companyTypes.AB,

    isEN: company => company.type === companyTypes.EN,
};
