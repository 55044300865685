const maxYears = 15;

const minMonthsBusiness = 6;
const maxMonthsBusiness = 60;

export const Options = Array.from(Array(maxYears).keys()).map(i => ({
    key: i + 1,
    value: (i + 1) * 12,
    text: `${i + 1} year${i + 1 > 1 ? 's' : ''}`,
}));

export const BusinessOptions = Array.from(Array(maxMonthsBusiness).keys())
    .filter(i => i >= minMonthsBusiness - 1)
    .map(i => ({
        key: i + 1,
        value: i + 1,
        text: `${i + 1} month${i + 1 > 1 ? 's' : ''}`,
    }));
