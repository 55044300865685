import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import BusinessWithdrawalReasonsPopup from 'APP/components/BusinessWithdrawalReasonsPopup';

function ResendOfferButton(props) {
    const [showModal, setShowModal] = useState(false);

    function handleButtonClick() {
        if (props.isBusiness) {
            setShowModal(true);
        } else {
            props.onClick();
        }
    }

    return (
        <Fragment>
            <BusinessWithdrawalReasonsPopup
                text={
                    <p>
                        By resending an offer you will send that offer as-is to the new revision. This will also
                        withdraw the old offer from the old revision.
                    </p>
                }
                onClose={() => setShowModal(false)}
                onClick={args => {
                    setShowModal(false);
                    props.onClick(args);
                }}
                showModal={showModal}
            />
            {props.isBusiness ? (
                <Button
                    floated="right"
                    color="yellow"
                    size="small"
                    icon="repeat"
                    content="Resend offer"
                    onClick={handleButtonClick}
                />
            ) : (
                <Popup
                    on="click"
                    trigger={
                        <Button floated="right" color="yellow" size="small" icon="repeat" content="Resend offer" />
                    }
                    header="Resend offer?"
                    content={
                        <div>
                            <p>
                                By resending an offer you will send that offer as-is to the new revision. This will also
                                withdraw the old offer from the old revision.
                            </p>
                            <Button fluid content="Yes, resend offer" onClick={handleButtonClick} />
                        </div>
                    }
                />
            )}
        </Fragment>
    );
}

ResendOfferButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isBusiness: PropTypes.bool.isRequired,
};
ResendOfferButton.defaultProps = {};

export default ResendOfferButton;
