import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const GeneralLoader = props => {
    const { variation, content } = props;

    switch (variation) {
        case 'small':
            return (
                <Loader data-testid="general-loader" size="mini" inline active>
                    {content || ''}
                </Loader>
            );
        default:
            return (
                <Segment basic style={{ minHeight: '100px' }} data-testid="general-loader">
                    <Dimmer active inverted>
                        <Loader inverted>{content || 'Loading'}</Loader>
                    </Dimmer>
                </Segment>
            );
    }
};

export default GeneralLoader;
