import React from 'react';
import { Image, Label, List, Menu } from 'semantic-ui-react';
import style from './style.css';
import SearchBar from './SearchBar';
import PrivilegesHelper from '../../helpers/PrivilegesHelper';
import { UserTypes } from '../../enums/User/Type';
import SearchAsPartner from './SearchBarPartner';

const role_translations = {
    ROLE_ADMIN_SUPERUSER: 'Admin Superuser',
    ROLE_ADMIN_CUSTOMER_SALES: 'Customer Sales Admin',
    ROLE_ADMIN_CUSTOMER_SALES_INSURANCE: 'Customer Insurance Admin',
    ROLE_ADMIN_CUSTOMER_SALES_PLUS: 'Customer Sales Admin Plus',
    ROLE_ADMIN_ADMIN: 'Admin',
};

function TopBarComponent(props) {
    return (
        <Menu secondary pointing className={style.topBar}>
            <Menu.Menu position="right">
                <Menu.Item>
                    {PrivilegesHelper.isUserType(UserTypes.ADMIN) && <SearchBar />}
                    {PrivilegesHelper.isUserType(UserTypes.PARTNER) && <SearchAsPartner />}
                </Menu.Item>
                <Menu.Item onClick={props.toggleSidebar}>
                    <List horizontal>
                        <List.Item>
                            <Label
                                className={style.profileBall}
                                circular
                                content={props.user.first_name.charAt(0) + props.user.last_name.charAt(0)}
                                as={Image}
                                avatar
                            />
                            <List.Content>
                                <b> {`${props.user.first_name} ${props.user.last_name}`}</b>
                                <List.Header>
                                    <p className={style.userRole}>
                                        {role_translations[props.user.role] || props.user.role}
                                    </p>
                                </List.Header>
                            </List.Content>
                        </List.Item>
                    </List>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
}

TopBarComponent.propTypes = {};
TopBarComponent.defaultProps = {};

export default TopBarComponent;
