import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import View from '../View';

function Search(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/:id`} component={View} />
            <Route component={List} />
        </Switch>
    );
}

Search.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};
Search.defaultProps = {};

export default Search;
