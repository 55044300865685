import React, { Component } from 'react';
import CompanyHelper from 'APP/helpers/CompanyHelper';
import PropTypes from 'prop-types';
import ApplicantInformationForm from './ApplicantInformationForm';

class ApplicantInformationFormContainer extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            applicant: props.applicant,
        };
    }

    render() {
        return <ApplicantInformationForm {...this.props} applicant={this.state.applicant} />;
    }
}

ApplicantInformationFormContainer.propTypes = {
    applicant: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    company: PropTypes.shape({
        type: PropTypes.oneOf(CompanyHelper.values).isRequired,
    }).isRequired,
    beneficialOwnerLabel: PropTypes.string,
    boxed: PropTypes.bool,
    admin: PropTypes.bool,
    assumedBeneficialOwner: PropTypes.bool,
};
ApplicantInformationFormContainer.defaultProps = {
    boxed: true,
    admin: false,
    beneficialOwnerLabel: null,
    assumedBeneficialOwner: false,
};

export default ApplicantInformationFormContainer;
