import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import Field, { Validation } from '@lendoab/lendo-redux-form-helpers';
import { Dimmer, Form, Grid, Header, Segment, Button, Icon, Popup } from 'semantic-ui-react';
import Actions from './Actions';
import Complements from './Complements';
import LoanDetails from './LoanDetails';
import Rates from './Rates';
import ResponseHelper from 'APP/helpers/ResponseHelper';

class TreatForm extends Component {
    toggleGuarantorComplement = key => (e, value) => {
        if (!value) {
            return;
        }

        if (key === 'one_guarantor') {
            this.props.change('complements.two_guarantors', false);
        } else {
            this.props.change('complements.one_guarantor', false);
        }
    };

    required = value => (value ? undefined : 'Required field');

    maxLoanAmount = value =>
        value && value > this.props.initialValues.amount
            ? `Allowed maximum offered is applied amount ${this.props.initialValues.amount}`
            : undefined;

    shouldShowReferenceField = productId => {
        const avidaProductId = 'f740a203-3bfd-4189-ac7b-a9659f8182e9';
        const marginalenProductId = '35354a23-b1df-46b4-b0d1-3f87404a998e';
        const resursProductId = 'fe6714e8-1da1-4a83-9f2c-d644f3d5a976';
        return [avidaProductId, marginalenProductId, resursProductId].includes(productId);
    };

    render() {
        const {
            applicants,
            isBusiness,
            submitDisabled,
            setFromPreviousOffer,
            setFromDraft,
            resetFromDraft,
        } = this.props;
        const mainApplicant = isBusiness ? applicants[0] : applicants.find(applicant => applicant.type === 'main');
        const coApplicant = applicants.find(applicant => applicant.type === 'co');

        let previousMessage = "The form is prefilled with the product's settings";
        if (setFromPreviousOffer) {
            previousMessage =
                'The form is prefilled with values from the last sent loan offer, revised with new loan details';
        } else if (setFromDraft && isBusiness) {
            previousMessage = (
                <span>
                    The form is prefilled with values from a draft
                    <Popup
                        hoverable
                        trigger={<Icon name="question circle" />}
                        content={
                            <span>
                                Instead of creating a new offer, the prefilled draft offer will be updated with the
                                filled form data and set to active.
                                <br />
                                <Button size="small" onClick={resetFromDraft}>
                                    Don{"'"}t use draft
                                </Button>
                            </span>
                        }
                    />
                </span>
            );
        } else if (setFromDraft) {
            previousMessage = 'The form is prefilled with values from a draft';
        }

        const referenceField = this.shouldShowReferenceField(this.props.productId) ? (
            <div>
                <Header>
                    <span>
                        Offer Reference
                        <Popup
                            hoverable
                            trigger={<Icon name="question circle" />}
                            content={
                                <span>
                                    Please add your internal reference ID that can be sent with accept signal.
                                    <br />
                                </span>
                            }
                        />
                    </span>
                </Header>
                <Form.Group widths="2">
                    <Field
                        component="input"
                        name="external_id"
                        label="Internal Reference"
                        validate={[Validation.required]}
                    />
                </Form.Group>
            </div>
        ) : (
            ''
        );

        return (
            <Dimmer.Dimmable dimmed={submitDisabled}>
                <Dimmer active={submitDisabled}>
                    <Header inverted as="h1">
                        {this.props.responseGivenToLatestRevision &&
                        ResponseHelper.isLoanOffer(this.props.responseGivenToLatestRevision)
                            ? 'The active offer has to be withdrawn before making a new offer'
                            : 'The denial has to be withdrawn before making a new offer on the current revision'}
                    </Header>
                </Dimmer>
                <Form as={Segment.Group} onSubmit={this.props.handleSubmit(this.props.offerAction)}>
                    <Segment>
                        <p>{previousMessage}</p>
                    </Segment>
                    <Segment clearing>
                        <Grid columns="equal" celled="internally">
                            <Grid.Column>
                                <LoanDetails
                                    isBusiness={isBusiness}
                                    required={this.required}
                                    maxLoanAmount={this.maxLoanAmount}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <Rates isBusiness={isBusiness} />
                            </Grid.Column>
                            <Grid.Column>
                                <Complements
                                    isBusiness={isBusiness}
                                    mainApplicant={mainApplicant}
                                    coApplicant={coApplicant}
                                    toggleGuarantorComplement={this.toggleGuarantorComplement}
                                />
                            </Grid.Column>
                        </Grid>
                        <Grid columns="equal" celled="internally">
                            <Grid.Column>{referenceField}</Grid.Column>
                        </Grid>
                    </Segment>
                    <Segment clearing>
                        <Actions
                            floated="right"
                            giveOffer={this.props.handleSubmit(this.props.offerAction)}
                            hideDeny={this.props.setFromPreviousOffer}
                            denyCallback={this.props.denyCallback}
                            applicationId={this.props.applicationId}
                            applicationType={this.props.applicationType}
                            productId={this.props.productId}
                            revision={this.props.revision}
                        />
                    </Segment>
                </Form>
            </Dimmer.Dimmable>
        );
    }
}

TreatForm.propTypes = {
    applicants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    offerAction: PropTypes.func.isRequired,
    isBusiness: PropTypes.bool,
    applicationId: PropTypes.string,
    applicationType: PropTypes.string,
    revision: PropTypes.number,
    productId: PropTypes.string,
    setFromPreviousOffer: PropTypes.bool,
    denyCallback: PropTypes.func,
    submitDisabled: PropTypes.bool.isRequired,
    responseGivenToLatestRevision: PropTypes.shape({}),
};

TreatForm.defaultProps = {
    isBusiness: false,
    setFromPreviousOffer: false,
    responseGivenToLatestRevision: undefined,
};

TreatForm = reduxForm({
    form: 'TreatForm',
    enableReinitialize: true,
})(TreatForm);

const selector = formValueSelector('TreatForm');

TreatForm = connect(state => {
    const applicationId = selector(state, 'application_id');
    const applicationType = selector(state, 'application_type');
    const revision = selector(state, 'revision');
    const productId = selector(state, 'product_id');
    const setFromPreviousOffer = selector(state, 'set_from_previus_offer');
    const setFromDraft = !!selector(state, 'draft_response_id');

    return {
        applicationId,
        revision,
        applicationType,
        productId,
        setFromPreviousOffer,
        setFromDraft,
    };
})(TreatForm);

export default TreatForm;
