import React, { useEffect, Fragment } from 'react';
import { Table } from 'semantic-ui-react';
import { Form, Button, Input, Checkbox, Header, Label } from 'semantic-ui-react';
import { ContactInfoFormMachine } from './ContactInfoFormMachine';
import { useForm } from '@lendoab/use-form';
import Toast from 'APP/common/Toast';
import ClipboardHelper from 'APP/helpers/ClipboardHelper';
import { email, phone } from '@lendoab/web-validations';

function getContactFields(data) {
    return {
        [`phone`]: {
            value: data[`phone`],
            meta: { fieldType: 'input' },
        },
        [`email`]: {
            value: data[`email`],
            meta: { fieldType: 'input' },
        },
        [`phoneIncorrect`]: {
            value: data[`phoneIncorrect`],
            meta: { fieldType: 'checkbox' },
        },
        ['emailIncorrect']: {
            value: data['emailIncorrect'],
            meta: { fieldType: 'checkbox' },
        },
    };
}

export default function ContactInfoForm(props) {
    const { applicant, applicationType, reFetchApplication, admin, editContactInfo = false } = props;
    const [state, dispatch] = ContactInfoFormMachine();
    const { getFieldValue, commitValue, getFieldMessage, getInputProps, getFieldState, handleSubmit } = useForm({
        fields: {
            ...getContactFields(state),
        },
        onSubmit: data => {
            // check phone validation before request
            if (!phone(getFieldValue('phone').replace('+46', '0'))) {
                Toast('error', 'incorrect phone number');
                return;
            }

            // check email validation before request
            if (!email(getFieldValue('email'))) {
                Toast('error', 'incorrect email');
                return;
            }
            dispatch({ type: 'SUBMIT', data: data });
        },
    });

    useEffect(() => {
        if (state.status === 'fetching') {
            const payload = {
                applicantId: applicant.id,
                applicationType: applicationType,
                email: applicant.email,
                phone: applicant.phone,
            };

            commitValue('email', applicant.email);
            commitValue('phone', applicant.phone);

            if (applicant.phone === '+46700000000') {
                commitValue('phoneIncorrect', true);
            } else {
                commitValue('phoneIncorrect', false);
            }
            if (applicant.email === 'noemail@lendo.se') {
                commitValue('emailIncorrect', true);
            } else {
                commitValue('emailIncorrect', false);
            }

            dispatch({ type: 'UPDATE_CONTACT_DATA', data: payload });
        }

        if (state.error) {
            Toast('error', state.errorMessage);
            dispatch({ type: 'RESET_ERROR_AND_SUCCESS' });
        }

        if (state.success) {
            Toast('success', 'Updated contact information.');
            dispatch({ type: 'RESET_ERROR_AND_SUCCESS' });
            reFetchApplication();
        }
    }, [state]);

    return (
        <Form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Header as="h4" floated="left" style={{ marginBottom: '0px' }}>
                    <Header.Content>
                        Contact Information
                        <Header.Subheader>Handle Contact Information</Header.Subheader>
                    </Header.Content>
                </Header>
                {!editContactInfo && admin && !state.editing && (
                    <Form.Button
                        size="tiny"
                        content="Edit"
                        icon="pencil"
                        name="editContactInformation"
                        onClick={() => dispatch({ type: 'TOGGLE_EDIT' })}
                    />
                )}
                {admin && (state.editing || editContactInfo) && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {!editContactInfo && (
                            <Button size="small" content="Cancel" onClick={() => dispatch({ type: 'DISCARD' })} />
                        )}
                        <Button
                            name="submitContactInformation"
                            size="small"
                            content="Save"
                            primary
                            type="submit"
                            style={{ marginRight: '10px' }}
                        />
                    </div>
                )}
            </div>
            <Table basic="very" compact style={{ margin: '6px 0px' }}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Header as="h4">
                                <Header.Content>
                                    <Header.Subheader>Email</Header.Subheader>
                                </Header.Content>
                            </Header>
                            {state.editing || editContactInfo ? (
                                <Fragment>
                                    <Input
                                        {...getInputProps('email')}
                                        error={getFieldMessage('email')}
                                        success={getFieldState('email')}
                                        fluid
                                        name="email"
                                        style={{ minHeight: '38px', fontSize: '14px', marginBottom: '6px' }}
                                    />
                                    {!email(getFieldValue('email')) && (
                                        <Label basic color="red" pointing style={{ marginBottom: '6px' }}>
                                            Invalid mail
                                        </Label>
                                    )}
                                    <Checkbox
                                        label="Incorrect email"
                                        type="checkbox"
                                        name="emailIncorrect"
                                        checked={getFieldValue('emailIncorrect')}
                                        onChange={() => {
                                            dispatch({
                                                type: 'UPDATE_INCORRECT_EMAIL',
                                                value: !getFieldValue('emailIncorrect'),
                                            }),
                                                commitValue('emailIncorrect', !getFieldValue('emailIncorrect'));
                                        }}
                                        style={{ margin: '6px' }}
                                    />
                                </Fragment>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span id="customerEmailData">
                                        {' '}
                                        {getFieldValue('emailIncorrect') ? 'Incorrect' : getFieldValue('email')}
                                    </span>
                                    <Button
                                        type="button"
                                        icon="copy"
                                        size="mini"
                                        onClick={() => {
                                            ClipboardHelper.copy(getFieldValue('email'));
                                        }}
                                        style={{ padding: '8px', height: '25px' }}
                                    />
                                </div>
                            )}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Header as="h4">
                                <Header.Content>
                                    <Header.Subheader>Phone</Header.Subheader>
                                </Header.Content>
                            </Header>
                            {state.editing || editContactInfo ? (
                                <Fragment>
                                    <Input
                                        {...getInputProps('phone')}
                                        error={getFieldMessage('phone')}
                                        success={getFieldState('phone')}
                                        fluid
                                        name="phone"
                                        style={{ minHeight: '38px', fontSize: '14px', marginBottom: '6px' }}
                                    />
                                    {!phone(getFieldValue('phone').replace('+46', '0')) && (
                                        <Label basic color="red" pointing style={{ marginBottom: '6px' }}>
                                            Invalid phone number
                                        </Label>
                                    )}
                                    <Checkbox
                                        label="Incorrect phone"
                                        type="checkbox"
                                        name="phoneIncorrect"
                                        checked={getFieldValue('phoneIncorrect')}
                                        onChange={() => {
                                            dispatch({
                                                type: 'UPDATE_INCORRECT_PHONE',
                                                value: !getFieldValue('phoneIncorrect'),
                                            });
                                            commitValue('phoneIncorrect', !getFieldValue('phoneIncorrect'));
                                        }}
                                        style={{ margin: '6px', width: '100%' }}
                                    />
                                </Fragment>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span id="customerPhoneData">
                                        {' '}
                                        {getFieldValue('phoneIncorrect') ? 'Incorrect' : getFieldValue('phone')}
                                    </span>
                                    <Button
                                        type="button"
                                        icon="copy"
                                        size="mini"
                                        onClick={() => {
                                            ClipboardHelper.copy(getFieldValue('phone'));
                                        }}
                                        style={{ padding: '8px', height: '25px' }}
                                    />
                                </div>
                            )}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Form>
    );
}
