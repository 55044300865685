import queryString from 'query-string';
import AjaxHelper from '../helpers/AjaxHelper';
import config from '../config';

const baseUrl = `${config.api.prefix}/search`;
const transport = AjaxHelper;

export default {
    search: params => transport.get(`${baseUrl}?q=${encodeURIComponent(params.q)}`, 'data.applications'),

    searchPrevApplications: (params = {}) => {
        const query = queryString.stringify(params);
        return transport.get(`${baseUrl}?q=${query}`, 'data.applications');
    },
};
