exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3Q_F6KUtUxXTqZYtP5RPL6{background:transparent!important;padding-top:1em!important}._3Q_F6KUtUxXTqZYtP5RPL6>.item,._3Q_F6KUtUxXTqZYtP5RPL6>.ui.accordion>.title>.item{color:#fefefe!important;padding:1.3em 1.14285714em!important;font-weight:500!important}._3Q_F6KUtUxXTqZYtP5RPL6>.ui.accordion>.title{padding:0!important;box-shadow:none!important}._3Q_F6KUtUxXTqZYtP5RPL6>.ui.accordion{margin-bottom:0!important}._3Q_F6KUtUxXTqZYtP5RPL6>.ui.accordion>.title:hover{background:hsla(0,0%,100%,.08)!important}._2Jd9hjhxxl9GyjuchO_rz6{background:#181f33;padding:0!important}._3iJXYZIS6H1-la0v9yZie5,._3JLG38bmcH9kDvqGQCj7YL{padding:1.3em 1.14285714em 1.3em 2em!important}._3iJXYZIS6H1-la0v9yZie5{display:flex!important;justify-content:space-between!important;align-items:center!important}.Ih4hFKEM6kFcYARXd8mH_{top:45px!important;left:25px!important;border-radius:4px!important}._3Q_F6KUtUxXTqZYtP5RPL6>.item:hover{color:#fff!important}._3Q_F6KUtUxXTqZYtP5RPL6>*>*>.item.active,._3Q_F6KUtUxXTqZYtP5RPL6>.item.active,._3Q_F6KUtUxXTqZYtP5RPL6>.item:active,._3Q_F6KUtUxXTqZYtP5RPL6>.ui.accordion>.title.active>.content.active+.item.active{border-left:2px solid #3495f9}", ""]);
// Exports
exports.locals = {
	"sidebarMenu": "_3Q_F6KUtUxXTqZYtP5RPL6",
	"sidebarAccordionContent": "_2Jd9hjhxxl9GyjuchO_rz6",
	"createNewDropdown": "_3iJXYZIS6H1-la0v9yZie5",
	"sidebarMenuItem": "_3JLG38bmcH9kDvqGQCj7YL",
	"dropdownMenu": "Ih4hFKEM6kFcYARXd8mH_"
};