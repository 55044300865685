import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import Header from './Header';
import Row from './Row';

function SearchResultsTable(props) {
    return (
        <Table
            basic="very"
            striped
            tableData={props.applications}
            headerRow={<Header />}
            renderBodyRow={row => (
                <Row key={`${row.application.id}_${row.product.id}`} {...row} baseURL={props.baseURL} />
            )}
        />
    );
}

SearchResultsTable.propTypes = {
    applications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        })
    ).isRequired,
    baseURL: PropTypes.string.isRequired,
};
SearchResultsTable.defaultProps = {};

export default SearchResultsTable;
