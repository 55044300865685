import React from 'react';
import PropTypes from 'prop-types';

function NullableBooleanField(props) {
    let returnString;
    if (props.value === null) {
        returnString = 'N/A';
    } else {
        returnString = props.value ? 'Yes' : 'No';
    }

    return <span>{returnString}</span>;
}

NullableBooleanField.propTypes = {
    value: PropTypes.oneOf([true, false]),
};
NullableBooleanField.defaultProps = {
    value: null,
};

export default NullableBooleanField;
