import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';
import ClipboardHelper from 'APP/helpers/ClipboardHelper';
import Toast from 'APP/common/Toast';

function ShortId({ application }) {
    function copyIdToClipboard() {
        ClipboardHelper.copy(application.id);
        Toast('success', `Application id ${application.id} copied to clipboard.`, 1000);
    }

    return (
        <>
            <Popup
                flowing
                on="click"
                trigger={<span className="clickable">{ApplicationHelper.renderShortId(application)} </span>}
            >
                {application.id}
            </Popup>
            <Icon name="copy" onClick={copyIdToClipboard} style={{ cursor: 'pointer' }} />
        </>
    );
}

ShortId.propTypes = {
    application: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
};
ShortId.defaultProps = {};

export default ShortId;
