import queryString from 'query-string';
import AjaxHelper from '../helpers/AjaxHelper';

const BASE_URL = '/api/integration';

/**
 * Fetches detailed tasks for a single application with optional product ids.
 * @param {string} applicationId
 * @param {string[]} productIds
 */
export function getTasksDetailsForApplicationProducts(applicationId, productIds) {
    const paramStrings = productIds.map(id => `product_id=${id}`);
    return AjaxHelper.get(`${BASE_URL}/tasks-by-application-id/${applicationId}?${paramStrings.join('&')}`, 'data');
}

/**
 * Fetches summary tasks for multiple applications.
 * @param {string[]} applicationIds
 */
export function getTasksSummaryForMultipleApplications(applicationIds) {
    const paramStrings = applicationIds.map(id => `application_id=${id}`);

    return AjaxHelper.get(`${BASE_URL}/tasks-summary?${paramStrings.join('&')}`, 'data');
}

/**
 * Sends a post request to run a task
 * @param {string} taskId
 * @param {Object} data
 */
export function runTask(taskId, data) {
    return AjaxHelper.post(`${BASE_URL}/tasks/${taskId}/run`, { data });
}

/**
 * Sends a post request to run multiple tasks
 * @param {Array} data
 */
export function runMultipleTasks(data) {
    return AjaxHelper.post(`${BASE_URL}/tasks/run`, { data: { tasks: data } });
}

/**
 * Sends a post request to create an integration
 * @param {Object} data
 */
export function createIntegrationForPartner(data) {
    return AjaxHelper.post(`${BASE_URL}/`, { data });
}

/**
 * Sends a post request to delete an integration
 * @param {string} integrationId
 */
export function deleteIntegration(integrationId) {
    return AjaxHelper.delete(`${BASE_URL}/${integrationId}`);
}

/**
 * Sends a patch request to update an integration
 * @param {string} integrationId
 * @param {Object} data
 */
export function updateIntegration(integrationId, data) {
    return AjaxHelper.patch(`${BASE_URL}/${integrationId}`, { data });
}

export function getTasks(params) {
    const query = queryString.stringify(params);
    return AjaxHelper.get(`${BASE_URL}/tasks?${query}`, 'data');
}

export function getIntegrations() {
    return AjaxHelper.get(`${BASE_URL}/`, 'data');
}

export function getErrorLogs(taskId, eventId) {
    return AjaxHelper.get(`${BASE_URL}/tasks/${taskId}/events/${eventId}/logs`, 'data');
}
