import { FilesActions } from 'APP/actions/PartnerApiActions';
import { HandleException } from 'APP/helpers/ErrorHelper';
import { useState, useEffect } from 'react';

const fetchApplicationFiles = async offer => {
    if (offer && offer.files && offer.files.length > 0) {
        try {
            const data = await FilesActions.getFiles(offer.files);
            return data;
        } catch (e) {
            HandleException(e);
            return null;
        }
    }
    return [];
};

const useApplicationFiles = offer => {
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
        const fetchFiles = async () => {
            const files = await fetchApplicationFiles(offer);
            if (files) {
                setUploadedFiles(files);
            }
        };

        if (offer) {
            fetchFiles();
        }
    }, [offer]);

    return uploadedFiles;
};

export default useApplicationFiles;
