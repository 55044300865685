import React from 'react';
import { Box, Button, Card, Input, Stack, StatusCircle, Text } from '@lendoab/aphrodite';
import { newValidator, useForm } from '@lendoab/use-form';
import { withRouter, Redirect } from 'react-router-dom';
import { required, email } from '@lendoab/web-validations';
import style from './style.css';

const loginSplash = require('APP/assets/images/loginBG.svg');
const logo = require('APP/assets/images/logo_green.svg');

function LoginPage({ isAuthenticated, doLogin, oktaError, authState, location, isFetching }) {
    const login = credentials => {
        doLogin(credentials);
    };
    // The location the user came from, before being logged out (or /)
    const { from } = location.state || { from: { pathname: '/' } };

    const doOktaLogin = () => window.location.assign('/api/auth/okta/login');

    const { handleSubmit, getInputProps, getFieldMessage } = useForm({
        fields: {
            email: {
                value: '',
                validators: [
                    newValidator(required, 'error', 'Required field'),
                    newValidator(email, 'error', 'Email is not valid'),
                ],
            },
            password: {
                value: '',
                validators: [newValidator(required, 'error', 'Required field')],
            },
        },

        onSubmit: values => {
            login({ email: values.email, password: values.password });
        },
    });
    // If the user is authed redirect to the page they were on
    if (isAuthenticated && authState === 'authed') {
        return <Redirect to={from} />;
    }

    return (
        <Box>
            <Box className={style.wrapper}>
                <img className={style.splash} src={loginSplash} alt="Login" />
            </Box>
            <Box
                className={style.formWrapper}
                display="flex"
                justifyContent="center"
                alignItems="center"
                backgroundColor="white"
            >
                <LoadingState loading={isFetching}>
                    <Card style={{ zIndex: 1 }}>
                        <Stack
                            space="medium"
                            component="form"
                            onSubmit={handleSubmit}
                            padding={['none', 'large']}
                            className={style.container}
                        >
                            <img src={logo} alt="Logo" className={style.logo} />
                            <Stack space="base" fluid>
                                <Text size="xs" weight="bold" component="label" htmlFor="email">
                                    Email
                                </Text>
                                <Input
                                    placeholder="Email"
                                    id="email"
                                    type="text"
                                    name="email"
                                    {...getInputProps('email')}
                                    error={getFieldMessage('email')}
                                />
                            </Stack>
                            <Stack space="base" fluid>
                                <Text size="xs" weight="bold" component="label" htmlFor="password">
                                    Password
                                </Text>
                                <Input
                                    autoComplete="password"
                                    placeholder="Password"
                                    id="password"
                                    type="password"
                                    name="password"
                                    error={getFieldMessage('password')}
                                    {...getInputProps('password')}
                                />
                            </Stack>

                            <Button primary fluid type="submit" disabled={isFetching}>
                                Sign in
                            </Button>

                            <Button primary fluid type="button" disabled={isFetching} onClick={doOktaLogin}>
                                Sign in with Okta
                            </Button>
                            <Text size="xxs" display="flex" justifyContent="center">
                                Version: {process.env.RELEASE || ''}
                            </Text>
                            {oktaError && (
                                <Text size="xs" marginTop="small" align="left" color="red">
                                    We could not authenticate you through Okta. If this is your first time using this
                                    method of authentication then please contact your manager. For all other cases
                                    please contact Tech through the feedback channel in Slack.
                                </Text>
                            )}
                        </Stack>
                    </Card>
                </LoadingState>
            </Box>
        </Box>
    );
}

export default withRouter(LoginPage);

export function LoadingState({ loading, children }) {
    if (loading) {
        return <LargeLoader />;
    }
    return children;
}
export function LargeLoader() {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" margin="6xl">
            <StatusCircle status="loading" color="blue">
                LOADING
            </StatusCircle>
        </Box>
    );
}
