import SearchActions from 'APP/actions/SearchAction';
import InsuranceActions from 'APP/actions/InsuranceActions';
import ApplicationTypes from 'APP/enums/Application/Types';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Search } from 'semantic-ui-react';
import './SearchBar.css';
import { Box } from '@lendoab/aphrodite';
import { Link } from 'react-router-dom';

function generateUrl(application) {
    switch (application.type) {
        case ApplicationTypes.PRIVATE:
        case ApplicationTypes.BUSINESS:
            return `/application/${application.type}/${application.id}`;

        case ApplicationTypes.MORTGAGE:
            return `/application/${application.type}/treat/${application.id}`;

        default:
            return '';
    }
}

function TypeBadge({ content }) {
    return (
        <Box
            display="inline"
            paddingY="tiny"
            paddingX="xs"
            marginTop="tiny"
            borderRadius="base"
            color="white"
            textTransform="capitalize"
            className={content}
        >
            {content}
        </Box>
    );
}

function resultRenderer({ title, description, status, type, url }) {
    return (
        <Link to={url} className="searchLink">
            <Box display="flex" justifyContent="flex-start" flexDirection="column">
                <div className="title">{title}</div>
                <Box className="description" marginTop="tiny">
                    {description}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="space-between" textAlign="right">
                <span className="price">{status}</span>
                {type && <TypeBadge content={type} />}
            </Box>
        </Link>
    );
}

function SearchBar({ history }) {
    const [loading, setLoading] = useState(false);
    const [applications, setApplications] = useState([]);
    const [insurances, setInsurances] = useState([]);
    const [insurancesSeparateProduct, setInsurancesSeparateProduct] = useState([]);
    const [value, setValue] = useState('');

    function resetSearch() {
        setLoading(false);
        setApplications([]);
        setInsurances([]);
        setValue('');
    }

    function getSearchResults() {
        if (applications.length < 1 && insurances.length < 1 && insurancesSeparateProduct?.length < 1) {
            return null;
        }

        const searchResults = {};
        if (applications.length > 0) {
            searchResults.applications = {
                name: 'Applications',
                results: applications.map(application => ({
                    title: application.id,
                    url: generateUrl(application),
                    description: moment(application.created_at).calendar(),
                    status: application.meta_status,
                    type: application.type,
                })),
            };
        }

        if (insurances.length > 0) {
            searchResults.insurances = {
                name: 'Insurances',
                results: insurances.map(insurance => ({
                    title: insurance.product_name,
                    url: `/insurances/${insurance.id}`,
                    description: moment(insurance.application_created_at).calendar(),
                    status: insurance.meta_status,
                })),
            };
        }
        if (insurancesSeparateProduct?.length > 0) {
            searchResults.insurancesSeparateProduct = {
                name: 'Insurances separate product',
                results: insurancesSeparateProduct.map(insurance => ({
                    title: insurance.id,
                    url: `/insurances/v2/${insurance.id}`,
                    description: moment(insurance.created_at).calendar(),
                    status: insurance.status,
                })),
            };
        }

        return searchResults;
    }

    async function handleSearchChange(e, { value }) {
        setLoading(true);
        setValue(value);
        if (value.length < 1) {
            resetSearch();
            return;
        }
        try {
            await Promise.all([
                SearchActions.search({ q: value }).then(applications => setApplications(applications)),
                InsuranceActions.search(value).then(insurances => setInsurances(insurances)),
                InsuranceActions.searchInsuranceSeparateProduct(value).then(insurances => {
                    setInsurancesSeparateProduct(insurances);
                }),
            ]);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    function handleResultSelect(e, { result }) {
        history.push(result.url);
    }

    return (
        <Search
            className="searchBar"
            category
            fluid
            loading={loading}
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            results={getSearchResults()}
            resultRenderer={resultRenderer}
            value={value}
            placeholder="Search..."
        />
    );
}

SearchBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(SearchBar);
