import { createBrowserHistory } from 'history';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Router } from 'react-router-dom';

import { loginUser, logoutUser, validateJWT } from '../actions/auth-actions-redux';
import PrivilegesHelper from '../helpers/PrivilegesHelper';
import { UserTypes } from '../enums/User/Type';

// AUTH
import LoginPage from '../pages/Login';
import RenewLoginModal from '../components/Auth/Login/Reminder';
import TokenOnUserActive from '../common/TokenOnUserActive';

import AdminRoutes from './AdminRoutes';
import PartnerRoutes from './PartnerRoutes';

const history = createBrowserHistory();

function Routes(props) {
    const { isFetching, isAuthenticated, authState, dispatch } = props;
    const [oktaError, setOktaError] = React.useState(false);
    useEffect(() => {
        console.log(
            `%c Admin version: ${process.env.RELEASE}`,
            'background: rgb(99, 183, 70); color: #fff; padding: 2px 5px;'
        );

        dispatch(validateJWT());
        TokenOnUserActive.init(dispatch);
    }, [dispatch]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const error = params.get('okta-error'); // bar

        if (error) {
            setOktaError(true);
        }
    }, []);

    return (
        <Router history={history}>
            <Fragment>
                {!isAuthenticated && (
                    <Route
                        component={() => (
                            <LoginPage
                                isAuthenticated={isAuthenticated}
                                authState={authState}
                                oktaError={oktaError}
                                isFetching={isFetching}
                                doLogin={credentials => dispatch(loginUser(credentials))}
                            />
                        )}
                    />
                )}

                {authState === 'aboutToExpire' && (
                    <RenewLoginModal renewToken={() => dispatch(validateJWT())} logout={() => dispatch(logoutUser())} />
                )}

                {isAuthenticated && PrivilegesHelper.isUserType(UserTypes.ADMIN) && (
                    <AdminRoutes dispatch={dispatch} isAuthenticated={isAuthenticated} authState={authState} />
                )}

                {isAuthenticated && PrivilegesHelper.isUserType(UserTypes.PARTNER) && (
                    <PartnerRoutes dispatch={dispatch} isAuthenticated={isAuthenticated} authState={authState} />
                )}
            </Fragment>
        </Router>
    );
}

function mapStateToProps(state) {
    const { isAuthenticated, isFetching, authState, errorMessage } = state.auth;

    return {
        isAuthenticated,
        isFetching,
        authState,
        errorMessage,
    };
}

export default connect(mapStateToProps)(Routes);
