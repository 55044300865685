import React from 'react';
import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';
import ExplanationPopup from 'APP/common/ExplanationPopup';

function BankInformation(props) {
    const { accountNumber, clearingNumber, bgNumber, pgNumber } = props;

    const show = [accountNumber, bgNumber, pgNumber].some(n => !!n);
    if (!show) {
        return null;
    }

    return (
        <Table.Cell>
            <Header as="h4" sub content="Account data" />

            {bgNumber && <span>{`${bgNumber} Bankgiro`}</span>}
            {pgNumber && <span>{`${pgNumber} Plusgiro`}</span>}
            {accountNumber && (
                <span>
                    ({clearingNumber}) {accountNumber}{' '}
                    <ExplanationPopup>
                        The account data that the applicant provided. The clearing number is shown within parentheses.
                    </ExplanationPopup>
                </span>
            )}
        </Table.Cell>
    );
}

/* eslint-disable camelcase */
BankInformation.propTypes = {
    accountNumber: PropTypes.string,
    clearingNumber: PropTypes.string,
    bgNumber: PropTypes.string,
    pgNumber: PropTypes.string,
};
BankInformation.defaultProps = {
    accountNumber: null,
    clearingNumber: null,
    bgNumber: null,
    pgNumber: null,
};
/* eslint-enable camelcase */

export default BankInformation;
