import { OPEN_INTEGRATION_MODAL, CLOSE_INTEGRATION_MODAL } from 'APP/ReduxActions/IntegrationModalActions';
import { get } from 'lodash';

const initialState = {
    open: false,
    taskId: null,
    applicationId: null,
    productIds: null,
};

export default function IntegrationModal(state = initialState, action) {
    switch (action.type) {
        case OPEN_INTEGRATION_MODAL:
            return {
                ...state,
                open: true,
                taskId: get(action, 'payload.taskId'),
                applicationId: get(action, 'payload.applicationId'),
                productIds: get(action, 'payload.productIds'),
            };
        case CLOSE_INTEGRATION_MODAL:
            return {
                ...state,
                open: false,
                taskId: null,
                applicationId: null,
                productIds: null,
            };
        default:
            return state;
    }
}
