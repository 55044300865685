import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon, Label, Header, Button } from 'semantic-ui-react';
import { Divider } from '@lendoab/aphrodite-admin';
import UserHelper from '../../../../helpers/UserHelper';
import AssumedPopup from 'APP/components/Application/View/Business/ApplicationWrapper/AssumedPopup';
import { Column, Columns, Box, BankIdIcon, Text } from '@lendoab/aphrodite';
import BankIdVerificationModal from 'APP/components/Modals/BankIdModal/BankIdVerificationModal';

export default function ApplicantCardHeader(props) {
    const {
        ssn,
        name,
        type,
        role,
        showingSection,
        admin,
        applicant,
        setShowingSection,
        willingToBeGuarantor,
        beneficialOwnerLabel,
        assumedBeneficialOwner,
    } = props;
    const genderIcon = UserHelper.genderIcon(ssn);
    const [openBankIdModal, setOpenBankIdModal] = useState(false);
    return (
        <div>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Header as="h4" style={{ margin: 'auto' }}>
                    <Icon name={genderIcon} color={genderIcon === 'man' ? 'blue' : 'red'} />
                    <Header.Content>
                        {name || <span>&nbsp;</span>}
                        <Header.Subheader>
                            {type} applicant{role && ` AND ${role}`}
                        </Header.Subheader>
                    </Header.Content>
                </Header>

                <BankIdVerificationModal
                    applicant={applicant}
                    open={openBankIdModal}
                    onClose={() => setOpenBankIdModal(false)}
                    onSuccess={() => setOpenBankIdModal(false)}
                />

                <Box display="flex" alignItems="center">
                    {admin && (
                        <Button borderless size="small" onClick={() => setOpenBankIdModal(true)}>
                            <BankIdIcon color="blue" size="medium" style={{ marginLeft: 0, marginRight: 10 }} />
                            <Text size="xs" weight="bold" color="blue">
                                Verify applicant
                            </Text>
                        </Button>
                    )}

                    {admin && showingSection === 'creditData' && (
                        <Button
                            floated="right"
                            size="small"
                            icon="pencil"
                            title="Edit contact information"
                            content="Edit"
                            style={{ paddingLeft: '11px', paddingRight: '11px', height: '40px' }}
                            onClick={() => setShowingSection('editContactInfo')}
                        />
                    )}
                    {admin && showingSection === 'creditData' ? (
                        <Button
                            floated="right"
                            size="small"
                            title="Consent settings"
                            content="Consent"
                            style={{ paddingLeft: '11px', paddingRight: '11px', height: '40px' }}
                            onClick={() => setShowingSection('consent')}
                        />
                    ) : (
                        admin && (
                            <Button
                                floated="right"
                                size="small"
                                title="back"
                                content="Back"
                                style={{ marginRight: '10px' }}
                                onClick={() => setShowingSection('creditData')}
                            />
                        )
                    )}
                </Box>
            </Box>
            <Divider />
            <Columns>
                <Column
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    marginBottom={showingSection === 'editContactInfo' ? 'xl' : 'xs'}
                >
                    {typeof willingToBeGuarantor === 'boolean' && type !== 'EN' && (
                        <WillingToBeGuarantor willingToBeGuarantor={willingToBeGuarantor} />
                    )}
                    {beneficialOwnerLabel && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <Label style={{ textTransform: 'uppercase', margin: '0px 6px' }}>
                                {beneficialOwnerLabel}
                            </Label>
                            {assumedBeneficialOwner && <AssumedPopup />}
                        </div>
                    )}
                </Column>
            </Columns>
        </div>
    );
}

ApplicantCardHeader.propTypes = {
    name: PropTypes.string.isRequired,
    ssn: PropTypes.string.isRequired,
    type: PropTypes.string,
    role: PropTypes.string,
    beneficialOwnerLabel: PropTypes.string,
    boxed: PropTypes.bool,
    assumedBeneficialOwner: PropTypes.bool,
    applicant: PropTypes.object,
    admin: PropTypes.bool,
    application: PropTypes.object || PropTypes.bool,
};
ApplicantCardHeader.defaultProps = {
    type: null,
    role: null,
    beneficialOwnerLabel: null,
    assumedBeneficialOwner: false,
    boxed: false,
    applicant: null,
    admin: false,
    application: false,
};

function WillingToBeGuarantor(props) {
    const { willingToBeGuarantor } = props;

    if (willingToBeGuarantor) {
        return (
            <Label style={{ marginTop: '10px' }} color="green">
                Applicant is guarantor
            </Label>
        );
    }

    return <Label style={{ marginTop: '10px' }}>Will submit guarantor after accept</Label>;
}
