export function queryFromObject(params) {
    const parts = [];
    Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
            params[key].forEach(value => {
                parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
            });
        } else if (params[key] !== undefined) {
            parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }
    });
    return parts.length ? '?' + parts.join('&') : '';
}
