import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from 'semantic-ui-react';
import ApplicationHelper from 'APP/helpers/ApplicationHelper';

// Every minute
const TICK_INTERVAL = 60 * 1000;

class TimeLeftUCWindow extends Component {
    constructor(props, context) {
        super(props, context);

        const ucEndDate = ApplicationHelper.getUCEndDate(props.application);
        if (!ucEndDate) {
            this.state = {};
            return;
        }
        this.state = {
            ucTimeLeft: moment.duration(ucEndDate.diff(new Date())),
        };
    }

    UNSAFE_componentWillMount() {
        if (!this.state.ucTimeLeft) {
            return;
        }
        this.interval = setInterval(this.tick, TICK_INTERVAL);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    tick = () =>
        this.setState(state => {
            const ucTimeLeft = state.ucTimeLeft.clone();
            ucTimeLeft.subtract(TICK_INTERVAL, 'ms');
            return { ucTimeLeft };
        });

    render() {
        const ucTimeLeft = this.state.ucTimeLeft;

        if (!ucTimeLeft) {
            return <Label>No UC data collected</Label>;
        }

        if (ucTimeLeft < 0) {
            return <Label>The UC window has expired ({ucTimeLeft.humanize()} ago)</Label>;
        }

        return <Label>Time left on UC window: {ucTimeLeft.humanize()}</Label>;
    }
}

TimeLeftUCWindow.propTypes = {
    application: PropTypes.object,
};
TimeLeftUCWindow.defaultProps = {
    application: undefined,
};

export default TimeLeftUCWindow;
