import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import ResponseLabel from './ResponseLabel';
import DateHelper from 'APP/helpers/DateHelper';

function DenialRow(props) {
    const { response } = props;

    // Set the widths absolute, since we want the same columns for the other tables in the expanded rows.
    return (
        <Table.Row>
            <Table.Cell width={2} style={{ paddingLeft: '20px' }}>
                <ResponseLabel response={response} />
            </Table.Cell>
            <Table.Cell width={3}>{DateHelper.asYmdHis(response.created_at)}</Table.Cell>
            <Table.Cell width={2} />
            <Table.Cell width={2} />
            <Table.Cell width={2} />
            <Table.Cell width={5} />
        </Table.Row>
    );
}

/* eslint-disable camelcase */
DenialRow.propTypes = {
    response: PropTypes.shape({
        created_at: PropTypes.string,
    }).isRequired,
};
DenialRow.defaultProps = {};
/* eslint-enable camelcase */

export default DenialRow;
