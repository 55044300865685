import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { OffersAction } from '../../../../actions/PartnerApiActions';
import Toast from '../../../../common/Toast';
import WithdrawOfferButton from 'APP/pages/PartnerApplication/View/ActionButtons/WithdrawOfferButton';
import { addBreadcrumb } from '@sentry/react';
import { HandleException } from 'APP/helpers/ErrorHelper';
import FormModal from '../../../../components/FormModal';
import PaidOutForm from './PaidOutForm';

function WonApplicationActions(props) {
    const [showModal, setShowModal] = useState(false);
    const {
        billOfDebtSent,
        customerAcceptedOffer,
        loanPaidOut,
        activeOffer,
        isBusiness,
        withdrawOfferCallback,
        rerouteToList,
        applicationID,
    } = props;
    const initialValues = { paid_out_amount: activeOffer.amount };

    const togglePaidOutModal = () => {
        setShowModal(!showModal);
    };

    const withdrawOffer = async (data = {}) => {
        if (isBusiness) {
            addBreadcrumb({
                message: `Sending a business withdraw from the WonApplicationActions component`,
                data,
            });
        }

        try {
            await OffersAction.withdrawOffer(activeOffer, data);

            Toast('success', 'Offer has been withdrawn.');
            withdrawOfferCallback();
        } catch (err) {
            HandleException(err, 'Could not withdraw the offer');
        }
    };

    const sendBillOfDebt = async () => {
        try {
            await OffersAction.markContractSent(activeOffer);

            Toast('success', 'Bill of debt sent.');
            rerouteToList(applicationID);
        } catch (err) {
            HandleException(err, 'Could not send bill of debt');
        }
    };

    const markLoanPaidOut = async () => {
        try {
            await OffersAction.markPaidOut(activeOffer);

            Toast('success', "Loan marked as 'paid out'.");
            rerouteToList(applicationID);
        } catch (err) {
            HandleException(err, "Could not mark loan as 'paid out'");
        }
    };

    const markLoanPaidOutWithAmount = async values => {
        if (activeOffer.amount === values.paid_out_amount) {
            markLoanPaidOut();
        } else {
            try {
                await OffersAction.markPaidOutWithAmount(activeOffer, values.paid_out_amount);

                Toast('success', "Loan marked as 'paid out'.");
                rerouteToList(applicationID);
            } catch (err) {
                HandleException(err, "Could not mark loan as 'paid out'");
            }
        }
    };

    const closePaidOutModal = () => {
        setShowModal(false);
    };

    if (loanPaidOut) {
        const amountText =
            activeOffer && activeOffer.amount_paid ? ` with the amount ${activeOffer.amount_paid} kr` : '';
        const header = `Loan has been paid out${amountText}`;
        return <Message success header={header} />;
    }

    return (
        <div>
            <div style={{ float: 'right' }}>
                {customerAcceptedOffer && (
                    <Button primary type="submit" content="Send bill of debt" onClick={sendBillOfDebt} />
                )}
                {billOfDebtSent && (
                    <Button primary type="submit" content="Mark loan paid out" onClick={togglePaidOutModal} />
                )}
                <WithdrawOfferButton isBusiness={isBusiness} onClick={withdrawOffer} withAcceptance />
            </div>
            {
                <FormModal
                    title="Pay out with other amount"
                    open={showModal}
                    close={togglePaidOutModal}
                    form={
                        <PaidOutForm
                            closeAction={closePaidOutModal}
                            paidOutAction={markLoanPaidOutWithAmount}
                            onSubmit={markLoanPaidOutWithAmount}
                            initialValues={initialValues}
                        />
                    }
                />
            }
        </div>
    );
}

WonApplicationActions.propTypes = {
    loanPaidOut: PropTypes.bool.isRequired,
    billOfDebtSent: PropTypes.bool.isRequired,
    customerAcceptedOffer: PropTypes.bool.isRequired,
    activeOffer: PropTypes.shape({}).isRequired,
    withdrawOfferCallback: PropTypes.func.isRequired,
};

export default WonApplicationActions;
