import React, { Component } from 'react';
import { Button, Modal, Loader, Dimmer } from 'semantic-ui-react';
import style from './style.css';

class EditModal extends Component {
    render() {
        let content = null;

        if (this.props.loading) {
            content = (
                <Dimmer active>
                    <Loader size="medium">Loading</Loader>
                </Dimmer>
            );
        } else {
            content = <Modal.Content>{this.props.form}</Modal.Content>;
        }

        return (
            <Modal
                size={this.props.size || 'small'}
                open={this.props.open}
                onClose={this.props.close}
                onOpen={this.props.onOpen}
                closeOnDimmerClick={this.props.closeOnDimmerClick || false}
            >
                <Modal.Header>
                    {this.props.title}
                    <Button
                        floated="right"
                        icon="close"
                        size="small"
                        onClick={this.props.close}
                        className={style.closeButton}
                    />
                </Modal.Header>
                {content}
                {this.props.buttons && (
                    <Modal.Actions>
                        <Button negative onClick={this.props.close}>
                            Avbryt
                        </Button>
                        <Button
                            positive
                            icon="checkmark"
                            labelPosition="right"
                            content="Spara"
                            onClick={this.props.onSave}
                        />
                    </Modal.Actions>
                )}
            </Modal>
        );
    }
}

export default EditModal;
