import React from 'react';
import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';

function ClaimsPepInformation(props) {
    let pepText;

    if (props.pep === true) {
        pepText = 'Yes, claims to be PEP. Control questions asked.';
    } else if (props.pep === false) {
        pepText = 'No, not PEP.';
    } else {
        pepText = 'Data is missing.';
    }

    return (
        <Table.Cell>
            <Header as="h4" sub content="PEP question" />
            <span>{pepText}</span>
        </Table.Cell>
    );
}

ClaimsPepInformation.propTypes = {
    pep: PropTypes.bool,
};
ClaimsPepInformation.defaultProps = {
    pep: null,
};

export default ClaimsPepInformation;
