import AjaxHelper from '../../helpers/AjaxHelper';
import config from '../../config';

const transport = AjaxHelper;
const baseUrl = config.partnerApi.prefix;
const productsUrl = `${baseUrl}/partner/products`;

export default {
    listProducts() {
        return transport.get(`${productsUrl}`, 'data');
    },
};
