import PropTypes from 'prop-types';
import React from 'react';
import { Header } from 'semantic-ui-react';
import ResponseHelper from 'APP/helpers/ResponseHelper';
import ResponseStatuses from 'APP/enums/Response/Statuses';

function ResponseLabel(props) {
    const { response } = props;

    const content = ResponseHelper.isLoanOffer(response) ? 'Loan offer' : 'Denial';

    let color = ResponseHelper.isLoanOffer(response) ? 'green' : 'red';

    if (response.status !== ResponseStatuses.ACTIVE) {
        color = 'grey';
    }

    return (
        <Header color={color} sub>
            {content}
        </Header>
    );
}

ResponseLabel.propTypes = {
    response: PropTypes.shape({
        status: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
    }).isRequired,
};
ResponseLabel.defaultProps = {};

export default ResponseLabel;
