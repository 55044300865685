import PropTypes from 'prop-types';
import React from 'react';
import { Header, Icon, Segment, Table } from 'semantic-ui-react';
import RiskIndicatorLabel from 'APP/common/RiskIndicatorLabel/index';
import SNIPopup from 'APP/components/PartnerApplication/common/SNIPopup/index';
import CompanyHelper from 'APP/helpers/CompanyHelper';
import ExplanationPopup from 'APP/common/ExplanationPopup/index';
import PrivilegesHelper from 'APP/helpers/PrivilegesHelper';
import ProductHelper from 'APP/helpers/ProductHelper';
import DataDisplayHelper from 'APP/helpers/DataDisplayHelper';
import { Link } from 'react-router-dom';

const CompanyInformation = ({ application, boxed }) => {
    const { company } = application;
    const product = application.products[0];
    const loanPurpose = ProductHelper.getLoanPurposeText(product.loan_purpose, product);
    const companyRegistrationNumber = CompanyHelper.renderOrganisationNumber(company);
    const companyAllaBolagLink = CompanyHelper.renderCompanyAllaBolagLink(company);

    return (
        <Segment.Group className={boxed ? '' : 'basic'}>
            <Segment basic={!boxed}>
                <Header as="h4">
                    {(CompanyHelper.isLLC(company) || !PrivilegesHelper.isPartner()) && (
                        <span style={{ float: 'right' }}>
                            <RiskIndicatorLabel labelled company={company} />
                        </span>
                    )}
                    <Icon name="building outline" />
                    <Header.Content>
                        {CompanyHelper.renderCompanyName(company)}
                        <Header.Subheader>
                            {!CompanyHelper.isEN(company) && companyAllaBolagLink != null ? (
                                <a href={companyAllaBolagLink} rel="noreferrer" target="_blank">
                                    View company in Allabolag.se
                                </a>
                            ) : (
                                'Company'
                            )}
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </Segment>
            <Segment basic={!boxed}>
                <Table structured compact basic="very">
                    <Table.Body>
                        <Table.Row verticalAlign="top">
                            <Table.Cell>
                                <Header as="h4" sub content="Business structure" />
                                <span>{CompanyHelper.renderCompanyType(company)}</span>
                            </Table.Cell>
                            {!CompanyHelper.isEN(company) && (
                                <Table.Cell>
                                    <Header as="h4" sub content="Registration number" />
                                    <Link to={{ pathname: `/company/${company.company_id}/view` }}>
                                        {companyRegistrationNumber}
                                    </Link>
                                </Table.Cell>
                            )}
                        </Table.Row>
                        <Table.Row verticalAlign="top">
                            <Table.Cell colSpan="3">
                                <Header as="h4" sub>
                                    Last 12m revenue according to applicant
                                    <ExplanationPopup
                                        style={{
                                            fontSize: '1.2em',
                                            lineHeight: '0.8em',
                                        }}
                                        content="Last 12 months approximated revenue according to applicant"
                                    />
                                </Header>
                                <span>{CompanyHelper.renderSelfEstimatedRevenue(company)}</span>
                            </Table.Cell>
                        </Table.Row>
                        {(CompanyHelper.isLLC(company) || !PrivilegesHelper.isPartner()) && (
                            <Table.Row verticalAlign="top">
                                <Table.Cell>
                                    <Header as="h4" sub content="Active since" />
                                    <span>{CompanyHelper.renderRegisteredSince(company)}</span>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header as="h4" sub content="SNI" />
                                    <span>{<SNIPopup company={company} />}</span>
                                </Table.Cell>
                                <Table.Cell>
                                    <Header as="h4" sub content="Yearly revenue according to UC" />
                                    <span>{CompanyHelper.renderNetSales(company)}</span>
                                </Table.Cell>
                            </Table.Row>
                        )}
                        <Table.Row verticalAlign="top">
                            {loanPurpose && (
                                <Table.Cell>
                                    <Header as="h4" sub content="Loan Purpose" />
                                    <span>{loanPurpose}</span>
                                </Table.Cell>
                            )}
                            <Table.Cell>
                                <Header as="h4" sub content="Existing loan amount" />
                                <span>{DataDisplayHelper.currency(product.existing_loan_amount, 'kr')}</span>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </Segment.Group>
    );
};

CompanyInformation.propTypes = {
    application: PropTypes.shape({
        company: PropTypes.shape({
            credit_data: PropTypes.shape({
                risk_class: PropTypes.string,
            }),
        }).isRequired,
        products: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    boxed: PropTypes.bool,
};

CompanyInformation.defaultProps = {
    boxed: true,
};

export default CompanyInformation;
