import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Header, Icon, Label, Segment, Table, Button, Input, Dropdown, Checkbox, Menu } from 'semantic-ui-react';
import AssumedPopup from './AssumedPopup';
import BusinessActions from 'APP/actions/BusinessActions';
import Toast from 'APP/common/Toast';
import { HandleException } from 'APP/helpers/ErrorHelper';
import { countryCodes } from 'APP/enums/Countries/CountryCodes';

const extentOfControlOptions = [
    {
        value: 'CONTROL_MORE_THAN_0_NO_MORE_THAN_25',
        text: 'Controls more than 0% but less than 25%',
    },
    {
        value: 'CONTROL_MORE_THAN_25_NO_MORE_THAN_50',
        text: 'Controls more than 25% but less than 50%',
    },
    {
        value: 'CONTROL_MORE_THAN_50_NO_MORE_THAN_75',
        text: 'Controls more than 50% but less than 75%',
    },
    {
        value: 'CONTROL_MORE_THAN_75',
        text: 'Controls more than 75%',
    },
    {
        value: 'CONTROL_MORE_THAN_75_LESS_THAN_100',
        text: 'Controls more than 75% but less than 100%',
    },
    {
        value: 'CONTROL_100',
        text: 'Controls 100%',
    },
    {
        value: 'VIEWED_AS_BENEFICIAL_OWNER',
        text: 'View as beneficial owner',
    },
];

function idleReducer(state, event) {
    switch (event.type) {
        case 'EDIT':
            return {
                ...state,
                status: 'editing',
                editData: state.beneficialOwners,
            };
        case 'POPULATE':
            return {
                status: 'idle',
                beneficialOwners: event.beneficialOwners.map(benOwner => ({
                    ...benOwner,
                    entryId: getRandomId(),
                })),
            };
        default:
            return state;
    }
}

function editingReducer(state, event) {
    switch (event.type) {
        case 'SUBMIT':
            return {
                ...state,
                status: 'pending',
                submitData: state.editData,
                editData: [],
            };
        case 'CHANGE':
            return {
                ...state,
                status: 'editing',
                editData: state.editData.map(entry => {
                    if (entry.entryId === event.entryId) {
                        return {
                            ...entry,
                            [event.field]: event.value,
                        };
                    }

                    return {
                        ...entry,
                    };
                }),
            };
        case 'REMOVE_ROW':
            return {
                ...state,
                status: 'editing',
                editData: state.editData.filter(row => row.entryId !== event.entryId),
            };
        case 'ADD_ROW_SWEDISH':
            return {
                ...state,
                status: 'editing',
                editData: [
                    ...state.editData,
                    {
                        id_nr: '',
                        entryId: getRandomId(),
                        countryOfResidence: 'SE',
                        nationality: 'SE',
                        email: '',
                        full_name: '',
                        is_foreign: false,
                        claims_pep: false,
                    },
                ],
            };
        case 'ADD_ROW_FOREIGN':
            return {
                ...state,
                status: 'editing',
                editData: [
                    ...state.editData,
                    {
                        entryId: getRandomId(),
                        email: '',
                        countryOfResidence: '',
                        nationality: '',
                        full_name: '',
                        date_of_birth: '',
                        is_foreign: true,
                        claims_pep: false,
                    },
                ],
            };
        case 'DISCARD':
            return {
                ...state,
                status: 'idle',
                editData: state.beneficialOwners,
            };
        default:
            return state;
    }
}

function pendingReducer(state, event) {
    switch (event.type) {
        case 'RESOLVE':
            return {
                ...state,
                beneficialOwners: event.data.map(benOwner => ({
                    ...benOwner,
                    entryId: getRandomId(),
                })),
                status: 'resolved',
            };
        case 'REJECT':
            return {
                ...state,
                error: event.error,
                status: 'rejected',
            };
        default:
            return state;
    }
}

function resolvedReducer(state, event) {
    switch (event.type) {
        case 'CLOSE':
            return {
                ...state,
                status: 'idle',
            };
        default:
            return state;
    }
}

function rejectedReducer(state, event) {
    switch (event.type) {
        case 'CLOSE':
            return {
                ...state,
                error: undefined,
                status: 'idle',
            };
        default:
            return state;
    }
}

function reducer(state, event) {
    switch (state.status) {
        case 'idle':
            return idleReducer(state, event);
        case 'editing':
            return editingReducer(state, event);
        case 'pending':
            return pendingReducer(state, event);
        case 'rejected':
            return rejectedReducer(state, event);
        case 'resolved':
            return resolvedReducer(state, event);
        default:
            return state;
    }
}

export default function EditableBeneficialOwners(props) {
    const { application, reFetchApplication } = props;
    const { beneficial_owners, applicants } = props.application;
    const [state, dispatch] = React.useReducer(reducer, { status: 'idle', beneficialOwners: [] });

    React.useEffect(() => {
        // This is not a good solution.
        // Since we need to be ready for possible changes to the
        // props we need to listen to all changes and when it changes
        // updated our internal state. The best solution would be too
        // refetch the entire application on the event resolved.
        dispatch({ type: 'POPULATE', beneficialOwners: beneficial_owners });
    }, [beneficial_owners, dispatch]);

    React.useEffect(() => {
        if (state.status === 'pending') {
            BusinessActions.postBeneficialOwners(
                state.submitData.map(entry => ({
                    application_id: application.id,
                    application_revision: application.revision,
                    id_nr: entry.id_nr,
                    claims_pep: entry.claims_pep,
                    extent_of_control: entry.extent_of_control,
                    email: entry.email,
                    countryOfResidence: entry.countryOfResidence,
                    nationality: entry.nationality,
                    full_name: entry.full_name,
                    pep: entry.pep,
                    date_of_birth: entry.date_of_birth
                        ? moment(entry.date_of_birth).format('YYYY-MM-DD') + 'T00:00:00+00:00'
                        : undefined,
                    is_foreign: entry.is_foreign,
                }))
            )
                .then(data => dispatch({ type: 'RESOLVE', data }))
                .catch(error => dispatch({ type: 'REJECT', error }));
        }

        if (state.status === 'rejected') {
            HandleException(state.error);
            dispatch({ type: 'CLOSE' });
        }

        if (state.status === 'resolved') {
            Toast('success', 'Successfully updated beneficial owners.');
            reFetchApplication();
        }
    }, [state, dispatch, application, reFetchApplication]);

    const applicant = applicants[0];

    return (
        <Segment.Group className={props.boxed ? '' : 'basic'}>
            <Segment basic={!props.boxed}>
                <Header as="h4" content="Beneficial owners" />
            </Segment>
            <Segment loading={state.status === 'pending'} basic={!props.boxed}>
                <Table basic="very" compact>
                    <TableHeader />

                    <Table.Body>
                        {state.status === 'editing'
                            ? state.editData.map(beneficialOwner => (
                                  <EditableTableBodyRow
                                      key={`edit-${beneficialOwner.entryId}`}
                                      dispatch={dispatch}
                                      application={application}
                                      beneficialOwner={beneficialOwner}
                                      applicant={applicant}
                                  />
                              ))
                            : state.beneficialOwners.map(beneficialOwner => (
                                  <TableBodyRow
                                      key={`idle-${beneficialOwner.entryId}`}
                                      application={application}
                                      beneficialOwner={beneficialOwner}
                                      applicant={applicant}
                                      uc_personal_data={beneficialOwner.uc_personal_data}
                                  />
                              ))}
                    </Table.Body>

                    <TableFooter state={state} dispatch={dispatch} />
                </Table>
            </Segment>
        </Segment.Group>
    );
}

EditableBeneficialOwners.propTypes = {
    application: PropTypes.any.isRequired,
    boxed: PropTypes.bool,
};

EditableBeneficialOwners.defaultProps = {
    boxed: true,
};

function getRandomId() {
    return (
        Math.random()
            .toString(36)
            .substring(2, 15) +
        Math.random()
            .toString(36)
            .substring(2, 15)
    );
}

function TableHeader() {
    return (
        <Table.Header fullWidth>
            <Table.Row>
                <Table.Cell>
                    <Header as="h4" sub content="Nationality" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="Country of residence" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="SSN/Full Name" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="Email" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="Date of birth" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="Extent of control" />
                </Table.Cell>
                <Table.Cell>
                    <Header as="h4" sub content="Claims PEP" />
                </Table.Cell>
                <Table.Cell />
                <Table.Cell />
            </Table.Row>
        </Table.Header>
    );
}

function TableBodyRow(props) {
    const { beneficialOwner, applicant, application, uc_personal_data } = props;

    const is_foreign = beneficialOwner.is_foreign;
    const id_nr = beneficialOwner.id_nr || '';
    const full_name = beneficialOwner.full_name || '';
    const date_of_birth = beneficialOwner.date_of_birth || '';
    const email = beneficialOwner.email || '';
    const pep = beneficialOwner.claims_pep === true ? true : false;
    const nationality = beneficialOwner.nationality;
    const countryOfResidence = beneficialOwner.countryOfResidence;

    const shouldNotify = application && application.should_notify_beneficialOwner;
    const extentOfControl = extentOfControlOptions.find(option => option.value === beneficialOwner.extent_of_control);

    return (
        <Table.Row key={beneficialOwner.id_nr}>
            <Table.Cell>{nationality}</Table.Cell>
            <Table.Cell>{countryOfResidence}</Table.Cell>
            <Table.Cell>
                {shouldNotify && <AssumedPopup />}
                {is_foreign ? full_name : id_nr}
            </Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>{date_of_birth}</Table.Cell>
            <Table.Cell>{extentOfControl ? extentOfControl.text : 'Data is missing'}</Table.Cell>
            <Table.Cell>{pep ? 'Yes' : 'No'}</Table.Cell>
            <Table.Cell>
                {uc_personal_data && uc_personal_data.restriction && (
                    <Label content={uc_personal_data.restriction.info} color="red" />
                )}
            </Table.Cell>
            <Table.Cell>{id_nr === applicant.ssn && <Label content="Applicant" />}</Table.Cell>
        </Table.Row>
    );
}

function EditableTableBodyRow(props) {
    const { dispatch, beneficialOwner } = props;

    const id_nr = beneficialOwner.id_nr || '';
    const full_name = beneficialOwner.full_name || '';
    const date_of_birth = beneficialOwner.date_of_birth || '';
    const email = beneficialOwner.email || '';
    const pep = beneficialOwner.claims_pep === true ? true : false;
    const extent_of_control = beneficialOwner.extent_of_control || '';
    const is_foreign = beneficialOwner.is_foreign;
    const nationality = beneficialOwner.nationality;
    const countyOfResidence = beneficialOwner.countryOfResidence;

    const countries = countryCodes.map(value => ({ key: value.code, value: value.code, text: value.country }));
    const countriesWithOutSweden = countries.filter(country => country.value !== 'SE');
    const countryOnlySweden = countries.filter(country => country.value === 'SE');

    return (
        <Table.Row>
            <Table.Cell>
                <Dropdown
                    placeholder="Nationality"
                    disabled={!is_foreign}
                    value={nationality}
                    data-testid="bo-nationality"
                    defaultValue={countryOnlySweden[0].value}
                    search
                    options={is_foreign ? countriesWithOutSweden : countryOnlySweden}
                    onChange={(event, data) =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: data.value,
                            field: 'nationality',
                        })
                    }
                />
            </Table.Cell>
            <Table.Cell>
                <Dropdown
                    placeholder="Country of residence"
                    search
                    data-testid="bo-country-of-residence"
                    value={countyOfResidence}
                    options={countries}
                    onChange={(event, data) =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: data.value,
                            field: 'countryOfResidence',
                        })
                    }
                />
            </Table.Cell>
            <Table.Cell width={2}>
                {is_foreign ? (
                    <Input
                        size="small"
                        onChange={event =>
                            dispatch({
                                type: 'CHANGE',
                                entryId: beneficialOwner.entryId,
                                value: event.target.value,
                                field: 'full_name',
                            })
                        }
                        fluid
                        name="full_name"
                        placeholder="Full Name"
                        type="text"
                        value={full_name}
                    />
                ) : (
                    <Input
                        size="small"
                        onChange={event =>
                            dispatch({
                                type: 'CHANGE',
                                entryId: beneficialOwner.entryId,
                                value: event.target.value,
                                field: 'id_nr',
                            })
                        }
                        fluid
                        placeholder="SSN"
                        name="ssn"
                        type="text"
                        value={id_nr}
                    />
                )}
            </Table.Cell>
            <Table.Cell width={2}>
                <Input
                    size="small"
                    onChange={event =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: event.target.value,
                            field: 'email',
                        })
                    }
                    fluid
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={email}
                />
            </Table.Cell>
            <Table.Cell width={2}>
                <Input
                    disabled={!is_foreign}
                    size="small"
                    onChange={event =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: event.target.value,
                            field: 'date_of_birth',
                        })
                    }
                    fluid
                    name="date_of_birth"
                    placeholder="Date of birth"
                    type="date"
                    value={date_of_birth}
                />
            </Table.Cell>

            <Table.Cell width={3}>
                <Dropdown
                    fluid
                    selection
                    data-testid="bo-choose-control"
                    placeholder="Choose extent of control"
                    options={extentOfControlOptions}
                    value={extent_of_control}
                    onChange={(event, data) =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: data.value,
                            field: 'extent_of_control',
                        })
                    }
                />
            </Table.Cell>

            <Table.Cell width={1}>
                <Checkbox
                    onChange={(event, data) =>
                        dispatch({
                            type: 'CHANGE',
                            entryId: beneficialOwner.entryId,
                            value: data.checked,
                            field: 'claims_pep',
                        })
                    }
                    value={pep}
                />
            </Table.Cell>

            <Table.Cell width={1}>
                <Button
                    floated="right"
                    size="small"
                    negative
                    icon
                    onClick={() => dispatch({ type: 'REMOVE_ROW', entryId: beneficialOwner.entryId })}
                >
                    <Icon name="minus" />
                </Button>
            </Table.Cell>
        </Table.Row>
    );
}

function getErrorStatus(state) {
    if (!Array.isArray(state.editData) || state.editData.length < 1) {
        return { canSubmit: false, message: null };
    }

    if (state.editData.some(entry => entry.extent_of_control === 'CONTROL_MORE_THAN_75') && state.editData.length > 1) {
        return {
            canSubmit: false,
            message: 'Only one beneficial can be added when one beneficial owner owns more than 75%.',
        };
    }

    if (
        state.editData.some(entry => entry.nationality === '' || entry.countryOfResidence === '') &&
        state.editData.length > 1
    ) {
        return {
            canSubmit: false,
            message: null,
        };
    }

    return {
        canSubmit: true,
        message: null,
    };
}

function TableFooter(props) {
    const { state, dispatch } = props;

    const errorStatus = getErrorStatus(state);

    return (
        <Table.Footer fullWidth>
            {errorStatus.message && (
                <Table.Row>
                    <Table.HeaderCell colSpan="16" style={{ textAlign: 'right', color: 'red', fontWeight: '600' }}>
                        {errorStatus.message}
                    </Table.HeaderCell>
                </Table.Row>
            )}
            <Table.Row>
                <Table.HeaderCell colSpan="7">
                    <BeneficialOwnerActions canSubmit={errorStatus.canSubmit} state={state} dispatch={dispatch} />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    );
}

function BeneficialOwnerActions(props) {
    const { state, dispatch, canSubmit } = props;

    const buttonOptions = [
        { key: 1, text: 'Swedish Owner', onClick: () => dispatch({ type: 'ADD_ROW_SWEDISH' }) },
        { key: 2, text: 'Foreign Owner', onClick: () => dispatch({ type: 'ADD_ROW_FOREIGN' }) },
    ];

    switch (state.status) {
        case 'idle':
            return (
                <Button floated="right" data-testid="edit-bo-button" onClick={() => dispatch({ type: 'EDIT' })}>
                    <Icon name="edit" />
                    Edit
                </Button>
            );

        case 'editing':
            return (
                <React.Fragment>
                    <Menu compact>
                        <Dropdown
                            text="ADD A BENEFICIAL OWNER"
                            data-testid="bo-menu-button"
                            options={buttonOptions}
                            simple
                            item
                        />
                    </Menu>
                    <Button.Group floated="right">
                        <Button onClick={() => dispatch({ type: 'DISCARD' })}>Discard</Button>
                        <Button.Or />
                        <Button disabled={!canSubmit} onClick={() => dispatch({ type: 'SUBMIT' })} primary>
                            Save
                        </Button>
                    </Button.Group>
                </React.Fragment>
            );

        default:
            return null;
    }
}
