import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import PrivilegesHelper from '../helpers/PrivilegesHelper';
import Privileges from '../enums/Privileges/Privileges';
import SearchPage from '../pages/PartnerApplication/Search';
import PrivateRoute from './PrivateRoute';

// Routes
const PartnerAdminUsers = React.lazy(() => import('../pages/PartnerAdminUsers').catch(() => location.reload()));

const ChangePasswordPage = React.lazy(() => import('../pages/PartnerChangePassword').catch(() => location.reload()));

const AcceptedOfferRoute = React.lazy(() =>
    import('../pages/PartnerApplication/AcceptedOffers').catch(() => location.reload())
);
const TreatApplicationsRoute = React.lazy(() =>
    import('../pages/PartnerApplication/TreatApplications').catch(() => location.reload())
);

const ComplementsRoute = React.lazy(() =>
    import('../pages/PartnerApplication/MortgageComplements').catch(() => location.reload())
);

function PartnerRoutes(props) {
    const { isAuthenticated, authState } = props;

    return (
        <Switch>
            <Redirect exact from="/" to="/treatapplications" />
            {PrivilegesHelper.hasAccess(Privileges.PARTNER_ADMIN) && (
                <PrivateRoute
                    exact
                    path="/partneradmins"
                    component={PartnerAdminUsers}
                    isAuthenticated={isAuthenticated}
                    authState={authState}
                />
            )}
            <PrivateRoute
                exact
                path="/change-password"
                component={ChangePasswordPage}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/treatapplications/:productId?"
                component={TreatApplicationsRoute}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/accepted-offers/:productId?"
                component={AcceptedOfferRoute}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/bill-of-debt/:productId?"
                component={AcceptedOfferRoute}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/paid-out/:productId?"
                component={AcceptedOfferRoute}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/application-search"
                component={SearchPage}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute
                path="/complements/:productId/mortgage/applications/:applicationId/:offerId"
                component={ComplementsRoute}
                isAuthenticated={isAuthenticated}
                authState={authState}
            />
            <PrivateRoute component={() => <div />} isAuthenticated={isAuthenticated} authState={authState} />
        </Switch>
    );
}

PartnerRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authState: PropTypes.string.isRequired,
};

export default PartnerRoutes;
