import React from 'react';
import { Box, Text, Tooltip, InfoButton } from '@lendoab/aphrodite';
import styles from './MortgageInputLabel.css';

export default function MortgageInputLabel(props) {
    const { label, tooltip, htmlFor, ...rest } = props;
    return (
        <Box display="flex" alignItems="center" {...rest}>
            <Text size="xs" weight="bold" component="label" htmlFor={htmlFor} paddingRight="base">
                {label}
            </Text>
            {tooltip && (
                <Tooltip
                    style={{ zIndex: 1000 }}
                    trigger={<InfoButton size="small" color="blue" />}
                    pointerClassName={styles.tooltipPointer}
                >
                    <Box marginRight="large" style={{ maxWidth: 250 }}>
                        {typeof tooltip === 'object' ? tooltip : <Text size="xs">{tooltip}</Text>}
                    </Box>
                </Tooltip>
            )}
        </Box>
    );
}
