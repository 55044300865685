import {
    VALIDATE_REQUEST,
    VALIDATE_SUCCESS,
    VALIDATE_FAILURE,
    LOGIN_REQUEST,
    LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_FAILURE,
    SET_AUTH_STATE,
    IS_UNAUTHED,
    PASSWORD_CHANGE_REQUEST,
    GENERATE_PASSWORD_SUCCESS,
} from 'APP/consts/auth';

function auth(
    state = {
        isFetching: false,
        isAuthenticated: false,
        authData: {},
        authState: 'unauthed',
        generatedPassword: '',
    },
    action
) {
    switch (action.type) {
        case VALIDATE_REQUEST:
            return { ...state, isFetching: true };
        case VALIDATE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
                errorMessage: '',
                authData: action.authData,
                authState: 'authed',
            };
        case VALIDATE_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.errorMessage,
                authState: 'unauthed',
                authData: {},
            };
        case PASSWORD_CHANGE_REQUEST:
            return { ...state, isFetching: true };
        case GENERATE_PASSWORD_SUCCESS:
            return {
                ...state,
                generatedPassword: action.generatedPassword,
            };
        case LOGIN_REQUEST:
            return { ...state, isFetching: true, isAuthenticated: false };
        case LOGIN_FAILURE:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.errorMessage,
                authData: {},
            };
        case LOGOUT_REQUEST:
            return { ...state, isFetching: true, isAuthenticated: true, errorMessage: '' };
        case LOGOUT_FAILURE:
            return { ...state, isFetching: false, errorMessage: action.errorMessage };
        case LOGOUT_SUCCESS:
            return { ...state, isFetching: false, isAuthenticated: false, authData: {}, authState: 'unauthed' };
        case IS_UNAUTHED:
            return { ...state, isFetching: false, isAuthenticated: false, authData: {}, authState: 'unauthed' };
        case SET_AUTH_STATE:
            switch (action.authState) {
                case 'aboutToExpire':
                    return { ...state, authState: action.authState };
                case 'expired':
                    return {
                        ...state,
                        authState: action.authState,
                        isAuthenticated: false,
                        isFetching: false,
                        authData: {},
                        errorMessage: '',
                    };
                default:
                    return {
                        ...state,
                        authState: 'unauthed',
                        isAuthenticated: false,
                        isFetching: false,
                        authData: {},
                        errorMessage: '',
                    };
            }
        default:
            return state;
    }
}

export default auth;
