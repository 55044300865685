import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ApplicationHelper from '../../../../helpers/ApplicationHelper';
import ProductHelper from '../../../../helpers/ProductHelper';
import ApplicationDataGrid from './Grid';
import ApplicationDataMenu from './Menu';

import './style.css';

const columns = [
    {
        key: 'new_loan',
        header: 'New loan',
        value: product => ApplicationHelper.renderAmount(product.total_loan_amount - product.amount_to_collect),
        business: false,
        private: true,
    },
    {
        key: 'loan_amount',
        header: 'Loan amount',
        value: product => ApplicationHelper.renderAmount(product.total_loan_amount),
        business: true,
        private: false,
    },
    {
        key: 'collect',
        header: 'Amount to Collect',
        value: product => ApplicationHelper.renderAmount(product.amount_to_collect),
        business: false,
        private: true,
    },
    {
        key: 'loan_purpose',
        header: 'Loan Purpose',
        value: product => ApplicationHelper.renderLoanPurpose(product.loan_purpose),
        business: false,
        private: true,
    },
    {
        key: 'amortize_length',
        header: 'Amortize length',
        value: product => ApplicationHelper.renderPeriod(product.amortize_length),
        business: true,
        private: true,
    },
    {
        key: 'total',
        header: 'Total Loan Amount',
        value: product => ApplicationHelper.renderAmount(product.total_loan_amount),
        business: false,
        private: true,
    },
];

export default class ApplicationData extends Component {
    render() {
        const product = this.props.application.products[0];
        const isBusiness = ProductHelper.isBusinessLoan(product);
        // Only show relevant columns for product category
        const columnsToShow = columns.filter(c => (isBusiness && c.business) || (!isBusiness && c.private));

        if (this.props.asMenu) {
            return (
                <ApplicationDataMenu
                    columns={columnsToShow}
                    product={product}
                    responses_expired_at={this.props.application.responses_expired_at}
                />
            );
        }
        return (
            <div className="application-data-grid">
                <ApplicationDataGrid columns={columnsToShow} product={product} />
            </div>
        );
    }
}

ApplicationData.propTypes = {
    application: PropTypes.shape({
        products: PropTypes.arrayOf(
            PropTypes.shape({
                total_loan_amount: PropTypes.number.isRequired,
                amortize_length: PropTypes.number.isRequired,
                amount_to_collect: PropTypes.number,
                category: PropTypes.string.isRequired,
                loan_purpose: PropTypes.string.isRequired,
            })
        ),
        responses_expired_at: PropTypes.string,
    }).isRequired,
    asMenu: PropTypes.bool,
};

ApplicationData.defaultProps = {
    asMenu: false,
};
