import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import moment from 'moment';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);

const getYesterday = format => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    return moment(yesterday).format(format);
};

// takes time and date in UTC format and returns a date in Stockholm time
export function convertToStockholmTime(param) {
    if (param) {
        const date = new Date(param);
        return date.toLocaleString('sv-SE', { timeZone: 'Europe/Stockholm' });
    }
    return null;
}

export const setMomentTimeToNow = momemtDate => {
    const dateNow = new Date();
    momemtDate.set({ hour: dateNow.getHours(), minute: dateNow.getMinutes(), second: dateNow.getSeconds() });

    return momemtDate;
};

export const isValidYYYYMMDD = date => date && dayjs(date, 'YYYY-MM-DD').isValid();

export const isDateWithinRange = (date, minDate, maxDate) =>
    dayjs(date).isSameOrAfter(minDate) && date.isSameOrBefore(maxDate);

const funcs = {
    asYmdHis(date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        if (date === null || typeof date !== 'object') {
            return null;
        }
        return date
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ');
    },

    optionsLastNumYears(numYears, includeBefore = 'Before {{year}}') {
        const options = [];
        let i = new Date().getFullYear();
        for (let n = 0; n < numYears; n++) {
            options.push({
                value: i,
                text: i,
            });
            i--;
        }
        options.push({
            value: i,
            text: includeBefore.replace('{{year}}', i + 1),
        });
        return options;
    },
    optionsNextNumYears(numYears, includeAfter = 'After {{year}}') {
        const options = [];
        let i = new Date().getFullYear();
        for (let n = 0; n < numYears; n++) {
            options.push({
                value: i,
                text: i,
            });
            i++;
        }
        options.push({
            value: i,
            text: includeAfter.replace('{{year}}', i - 1),
        });
        return options;
    },

    isToday: (date, format) => {
        const check = funcs.formatDate(date, format);
        const today = funcs.formatDate(new Date(), format);
        return moment(check).isSame(today);
    },

    isAboutToExpire: date => {
        // remove 7 days from the specified date
        const check = moment(date)
            .subtract(7, 'd')
            .format('');
        const today = funcs.formatDate(new Date(), '');
        return moment(today).isAfter(check);
    },

    isYesterday: (date, format) => {
        const check = funcs.formatDate(date, format);
        const yesterday = getYesterday(format);
        return moment(check).isSame(yesterday);
    },

    todayOrYesterday: (date, format) => {
        const checkDate = funcs.formatDate(date, format);
        let today = new Date();
        const yesterday = getYesterday(format);
        today = funcs.formatDate(today, format);

        const todayOrYesterday = moment(checkDate).isSame(today)
            ? 'idag'
            : moment(checkDate).isSame(yesterday)
            ? 'igår'
            : '';
        return todayOrYesterday;
    },

    formatDate: (date, format) => moment(date).format(format),

    getCalendarDate: date => {
        return dayjs(date).calendar(null, {
            sameDay: '[Today at] HH:mm',
            lastDay: '[Yesterday at] HH:mm',
            sameElse: 'YYYY-MM-DD',
        });
    },
    isValidYYYYMMDD,
    isDateWithinRange,
};

export default funcs;
