import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import DenyButton from '../common/DenyButton';

class Actions extends Component {
    render() {
        return (
            <div style={{ ...(this.props.floated && { float: this.props.floated }) }}>
                <Button type="submit" icon="check" color="green" content="Give offer" onClick={this.props.giveOffer} />
                {!this.props.hideDeny && (
                    <DenyButton
                        callback={this.props.denyCallback}
                        applicationType={this.props.applicationType}
                        applicationId={this.props.applicationId}
                        productId={this.props.productId}
                        revision={this.props.revision}
                    />
                )}
            </div>
        );
    }
}

Actions.propTypes = {
    giveOffer: PropTypes.func.isRequired,
    hideDeny: PropTypes.bool,
    denyCallback: PropTypes.func.isRequired,
    applicationId: PropTypes.string,
    applicationType: PropTypes.string,
    productId: PropTypes.string,
    revision: PropTypes.number,
    floated: PropTypes.string,
};

Actions.defaultProps = {
    hideDeny: false,
    applicationId: '',
    applicationType: '',
    productId: '',
    revision: 0,
    floated: 'right',
};

export default Actions;
