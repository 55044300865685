import ApplicationTypes from 'APP/enums/Application/Types';
import config from '../config';
import AjaxHelper from '../helpers/AjaxHelper';

const baseUrl = `${config.api.prefix}/applicants`;
const transport = AjaxHelper;

export default {
    update: (id, applicationType = ApplicationTypes.PRIVATE, data) =>
        transport.patch(`${baseUrl}/${applicationType}/${id}`, { data }),

    prefill: ssn => {
        return transport.post(`${baseUrl}/prefill`, { data: { ssn } }, 'data');
    },
};
