import AjaxHelper from '../../helpers/AjaxHelper';
import config from '../../config';

const baseUrl = `${config.partnerApi.prefix}`;

const transport = AjaxHelper;

export default {
    getBusinessPropertyQuestions: applicationId =>
        transport.get(`${baseUrl}/business/property-questions/${applicationId}`, 'data'),

    // ----------------------------------------------
};
