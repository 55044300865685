import { useReducer, useEffect } from 'react';
import { createEffect } from 'APP/helpers/Effect';
import SubscriptionActions from 'APP/actions/SubscriptionActions';
import Toast from 'APP/common/Toast';

function reducer(state, event) {
    switch (state.status) {
        case 'idle': {
            if (event.type === 'SUBMIT') {
                return {
                    ...state,
                    status: 'pending_submit',
                    effects: [
                        ...state.effects,
                        createEffect('submit', {
                            marketingEmailValue: event.marketingEmailValue,
                            marketingPhoneValue: event.marketingPhoneValue,
                        }),
                    ],
                };
            }

            return state;
        }

        case 'pending': {
            if (event.type === 'RESOLVE') {
                return {
                    ...state,
                    status: 'idle',
                    emailStatus: event.response.email_consent ? event.response.email_consent.state : null,
                    phoneStatus: event.response.phone_consent ? event.response.phone_consent.state : null,
                };
            }

            if (event.type === 'REJECT') {
                Toast('error', `Unable to fetch marketing consents. ${event.error}`);
                return {
                    ...state,
                    status: 'idle',
                };
            }

            return state;
        }

        case 'pending_submit': {
            if (event.type === 'RESOLVE') {
                Toast('success', `Marketing consents updated succesfully`);
                return {
                    ...state,
                    status: 'pending',
                    effects: [...state.effects, createEffect('fetch')],
                };
            }

            if (event.type === 'REJECT') {
                Toast('error', `Unable to update marketing consents. ${event.error}`);
                return {
                    ...state,
                    status: 'idle',
                };
            }
            return state;
        }

        default: {
            return state;
        }
    }
}

export function MarketingConsentFormMachine(applicantData) {
    const [{ effects, ...state }, dispatch] = useReducer(reducer, {
        status: 'pending',
        effects: [createEffect('fetch')],
        emailStatus: null,
        phoneStatus: null,
        userEmail: null,
        userPhone: null,
        userId: null,
        ...applicantData,
    });

    useEffect(() => {
        for (const effect of effects) {
            if (effect.status !== 'idle') {
                continue;
            }

            effect.markAsStarted();

            if (effect.type === 'fetch') {
                SubscriptionActions.getMarketingEmailAndPhoneConsents(state.userEmail, state.userPhone, state.userId)
                    .then(response => dispatch({ type: 'RESOLVE', response: response }))
                    .catch(err => dispatch({ type: 'REJECT', error: err }));
            }

            if (effect.type === 'submit') {
                const payload = {
                    marketing: {
                        email_consent: {
                            email: state.userEmail,
                            user_id: state.userId,
                            state: effect.marketingEmailValue,
                        },
                        phone_consent: {
                            phone_number: state.userPhone,
                            user_id: state.userId,
                            state: effect.marketingPhoneValue,
                        },
                    },
                };

                SubscriptionActions.setApplicantEmailAndPhoneConsents(payload)
                    .then(() => dispatch({ type: 'RESOLVE' }))
                    .catch(err => dispatch({ type: 'REJECT', error: err }));
            }
        }
    }, [state, effects]);

    return [state, dispatch];
}
