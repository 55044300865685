/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef } from 'react';
import {
    Typography,
    Box,
    Stack,
    Modal,
    ModalContent,
    HeadsetIcon,
    ButtonRow,
    Button,
    ButtonRowButton,
    StatusCircle,
    PrivateIcon,
    RedoIcon,
    CrossIcon,
    BankIdColorIcon,
    CheckIcon,
} from '@lendoab/aphrodite';
import ApplicantHelper from 'APP/helpers/ApplicantHelper';
import { useBankIdVerificationMachine } from './BankIdVerificationMachine';
import { Divider } from '@lendoab/aphrodite-admin';
import { getErrorMessage } from './helpers';

export default function BankIdVerificationModal(props) {
    const {
        applicant,
        open,
        onClose,
        onSuccess,
        onVerified,
        successButtonContent,
        title = 'Verify customer with BankID',
        message,
        ...rest
    } = props;
    const [state, dispatch] = useBankIdVerificationMachine({ applicant });
    const prevOpenRef = useRef(open);

    function triggerBankIdVerification(whoInitiated) {
        dispatch({ type: 'TRIGGER_BANK_ID_VERIFICATION', whoInitiated });
    }

    function onCloseHandler() {
        dispatch({ type: 'CANCEL_AND_CLOSE' });
        onClose();
    }

    const applicantName = ApplicantHelper.getApplicantFullName(applicant);

    useEffect(() => {
        if (state.status === 'resolved') {
            onVerified?.(state.bankIdReference);
        }
    }, [state.status]);

    useEffect(() => {
        // If we previously was open and now is closed, we should cancel the BankID challenge.
        if (prevOpenRef.current && !open) {
            dispatch({ type: 'CANCEL_AND_CLOSE' });
        }
        prevOpenRef.current = open;
    }, [open]);

    useEffect(() => {
        return () => dispatch({ type: 'CANCEL_AND_CLOSE' });
    }, []);

    const description =
        message ||
        `You’re about to request a BankID signing for ${applicantName}. Choose who initiated the phone call to continue.`;

    return (
        <Modal open={open} onClose={onCloseHandler} style={{ zIndex: 9 }} {...rest}>
            <ModalContent padding="none" style={{ width: 736 }} borderRadius="small">
                {state.status === 'idle' && (
                    <BankIdVerificationContent
                        onClose={onCloseHandler}
                        onConfirm={triggerBankIdVerification}
                        title={title}
                        description={description}
                    />
                )}

                {['pending_polling', 'awaiting_polling', 'pending_ssn', 'update_bankid_reference'].includes(
                    state.status
                ) && <PendingPollingModalContent onClose={onCloseHandler} />}

                {state.status === 'resolved' && (
                    <SuccessMessage
                        onSuccess={onSuccess}
                        buttonContent={successButtonContent}
                        onClose={onCloseHandler}
                    />
                )}
                {state.status === 'rejected' && (
                    <ErrorMessage
                        bankIdStatus={state.bankIdStatus}
                        onClose={onCloseHandler}
                        onRestart={triggerBankIdVerification}
                    />
                )}
            </ModalContent>
        </Modal>
    );
}

function BankIdVerificationContent(props) {
    const { onClose, onConfirm, title, description } = props;

    return (
        <Fragment>
            <Stack padding="2xl" space="medium">
                <Box display="flex" justifyContent="space-between" alignItems="start">
                    <Typography.Title level={3}>{title}</Typography.Title>
                    <CrossIcon
                        size="medium"
                        color="blue-40"
                        // TODO: Optional: Make [enter] key press work
                        onClick={onClose}
                        cursor="pointer"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        tabIndex={0}
                    />
                </Box>
                <Typography.Body>{description}</Typography.Body>
            </Stack>
            <ButtonRow>
                <ButtonRowButton
                    primary
                    onClick={() => {
                        onConfirm('user');
                    }}
                >
                    The customer called Lendo
                    <PrivateIcon size="small" />
                </ButtonRowButton>
                <ButtonRowButton
                    primary
                    onClick={() => {
                        onConfirm('RP');
                    }}
                >
                    Lendo called the customer
                    <HeadsetIcon size="small" />
                </ButtonRowButton>
            </ButtonRow>
        </Fragment>
    );
}

function PendingPollingModalContent({ onClose }) {
    return (
        <Box>
            <Box paddingTop="xl" paddingBottom="base" paddingRight="xl" display="flex" justifyContent="flex-end">
                <CrossIcon size="medium" color="blue-40" onClick={onClose} cursor="pointer" />
            </Box>
            <Stack space="xl">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <StatusCircle status="loading" color="blue-30">
                        <BankIdColorIcon size="4xl" />
                    </StatusCircle>
                </Box>
                <Typography.Title level={3} paddingBottom="5xl" textAlign="center">
                    Waiting for customer to sign...
                </Typography.Title>
            </Stack>
            <Divider marginTop="none" marginBottom="none" />
            <Box padding="medium">
                <Button size="medium" fluid onClick={onClose}>
                    Cancel
                </Button>
            </Box>
        </Box>
    );
}

function SuccessMessage({ onClose, onSuccess, buttonContent }) {
    return (
        <Box>
            <Box paddingTop="xl" paddingBottom="base" paddingRight="xl" display="flex" justifyContent="flex-end">
                <CrossIcon size="medium" color="blue-40" onClick={onClose} cursor="pointer" />
            </Box>
            <Stack space="xl">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <StatusCircle color="green-40" status="complete">
                        <CheckIcon color="gray-10" size="4xl" />
                    </StatusCircle>
                </Box>
                <Typography.Title level={3} paddingBottom="5xl" textAlign="center">
                    The customer was successfully verified!
                </Typography.Title>
            </Stack>
            <ButtonRow>
                <ButtonRowButton primary onClick={onSuccess}>
                    {buttonContent || (
                        <>
                            Close <CrossIcon size="small" />
                        </>
                    )}
                </ButtonRowButton>
            </ButtonRow>
        </Box>
    );
}

function ErrorMessage(props) {
    const { onClose, bankIdStatus, onRestart } = props;

    return (
        <Fragment>
            <Box paddingTop="xl" paddingBottom="base" paddingRight="xl" display="flex" justifyContent="flex-end">
                <CrossIcon size="medium" color="blue-40" onClick={onClose} cursor="pointer" />
            </Box>
            <Box textAlign="center">
                <Box display="flex" justifyContent="center" alignItems="center" marginBottom="xl">
                    <StatusCircle color="red-50" status="stop">
                        <CrossIcon size="4xl" />
                    </StatusCircle>
                </Box>
                <Typography.Title level={3} paddingBottom="medium" textAlign="center">
                    There was an error when verifying an applicant
                </Typography.Title>
                <Typography.Body paddingBottom="5xl">{getErrorMessage(bankIdStatus)}</Typography.Body>
            </Box>
            <ButtonRow>
                <ButtonRowButton onClick={onClose}>Cancel</ButtonRowButton>
                <ButtonRowButton primary onClick={onRestart}>
                    Try again <RedoIcon size="small" />
                </ButtonRowButton>
            </ButtonRow>
        </Fragment>
    );
}
