/**
 * Return true if `obj` is considered to be empty.
 * `null` and `undefined` are empty.
 *
 * @param {any} obj
 * @return {boolean} - isEmpty
 */
export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) {
        return true;
    }

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) {
        return false;
    }
    if (obj.length === 0) {
        return true;
    }

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') {
        return true;
    }

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    // Array.some returns true if the callback function
    // returns a truthy value for any array element;
    // otherwise, false.
    return !Object.keys(obj).some(key => {
        if (hasOwnProperty.call(obj, key)) {
            return true;
        }
        return false;
    });
}

// Converts an object to a object that can be used by a semantic-ui dropdown
export function getDropdownOptions(obj) {
    return Object.keys(obj).map(key => ({
        text: obj[key],
        value: key,
        key,
    }));
}

// Converts an object to a object that can be used by a Aphrodite dropdown
export function getDropdownOptionsAphrodite(obj) {
    return Object.keys(obj).map(key => ({
        label: obj[key],
        value: key,
    }));
}

// Set a max length of a string
export function limitStringLength(str, maxLength, replacement = '...') {
    // Double check that the string is actually string
    if (typeof str !== 'string') {
        return '';
    }

    if (str.length <= maxLength) {
        return str;
    }

    return str.substr(0, maxLength - replacement.length) + replacement;
}

export function upperCase(str) {
    return str.replace(/^\w/, c => c.toUpperCase());
}
