import React from 'react';
import { Sidebar, Segment } from 'semantic-ui-react';
import './style.css';

function ToplistSidebar(props) {
    const { sidebarVisible } = props;
    return (
        <Sidebar
            as={Segment}
            animation="overlay"
            width="wide"
            direction="right"
            visible={sidebarVisible}
            icon="labeled"
            className="topListContainer"
        >
            {/* Add sidebar content here */}
        </Sidebar>
    );
}

export default ToplistSidebar;
