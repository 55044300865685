import React, { Component } from 'react';
import { Modal, Header, Segment, List, Button, Icon } from 'semantic-ui-react';
import RequestStatusHandler from '../../common/RequestStatusHandler/RequestStatusHandler';
import style from './RequestStatusModal.css';

class RequestStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requests: [],
            all_done: true,
            requests_overall_status: 'done',
            show_status_modal: false,
        };
    }

    UNSAFE_componentWillMount() {
        RequestStatusHandler.addChangeListener(this.getRequests);
        RequestStatusHandler.on('modal_show', this.showModal);
    }

    componentWillUnmount() {
        RequestStatusHandler.addChangeListener(this.getRequests);
        RequestStatusHandler.removeListener('modal_show', this.showModal);
    }

    getRequests = () => {
        this.setState({
            requests: RequestStatusHandler.getRequestQueue(),
            requests_overall_status: RequestStatusHandler.getStatus(),
        });
    };

    forceClose = () => {
        RequestStatusHandler.clearRequestsQueue();
        this.setState({ show_status_modal: false });
    };

    showModal = () => {
        this.setState({ show_status_modal: true });
    };

    render() {
        if (!this.state.show_status_modal || this.state.requests_overall_status === 'done') {
            return null;
        }

        const loading_nodes = this.state.requests.map((request, index) => (
            <List.Item key={index} style={{ minHeight: '38px' }}>
                <List.Content floated="left">
                    <List.Header>{request.name}</List.Header>
                    <List.Description style={{ color: 'red' }}>
                        {' '}
                        {request.error && request.error.message}
                    </List.Description>
                </List.Content>
                <List.Content floated="right">
                    {request.status === 'active' && <Icon loading name="spinner" circular />}
                    {request.status === 'done' && <Icon name="check" color="green" circular />}
                    {request.status === 'failed' && <Icon name="remove" color="red" circular />}
                </List.Content>
            </List.Item>
        ));

        return (
            <Modal open size="small" className={style.statusModal}>
                <Modal.Content>
                    <Modal.Description>
                        <Header as="h2" textAlign="center">
                            Skickar
                        </Header>
                        <Segment basic padded>
                            <List relaxed size="large">
                                {loading_nodes}
                            </List>
                        </Segment>
                        {this.state.requests_overall_status === 'failed' && (
                            <Segment basic textAlign="center">
                                <Button content="Stäng" onClick={this.forceClose} />
                                <Button content="Försök igen" onClick={RequestStatusHandler.restartAllRequests} />
                            </Segment>
                        )}
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default RequestStatusModal;
